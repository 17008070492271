<div class="porter-container register-container">
  <div class="logo-container">
    <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
  </div>
  <div class="modal content-container color-bg-white p-6">
    <div class="modal-header w-100 row">
      <h2 class="modal-title">Nutzungsbedingungen</h2>
    </div>
    <div class="modal-text">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sit amet neque felis.
        Sed ac gravida est, eu rutrum ligula. In hac habitasse platea dictumst. Pellentesque
        tristique faucibus gravida. Donec lobortis congue nulla, vel aliquam ex rutrum eu. Nulla
        varius eros eget scelerisque elementum. Aenean placerat ut neque ut molestie. Nulla pulvinar
        rutrum dui, at placerat purus. Nulla consequat eleifend augue, vel eleifend nisl aliquet in.
        Suspendisse ac tellus odio.
      </p>
      <p>
        Duis finibus accumsan posuere. Donec fringilla aliquet odio, sed interdum ex blandit sit
        amet. Aliquam erat volutpat. Fusce venenatis lorem id leo tincidunt, sit amet posuere erat
        aliquet. Maecenas commodo justo ut vestibulum volutpat. Nullam a massa non lectus feugiat
        tempus. Donec porttitor, ante a porttitor pulvinar, arcu elit fermentum orci, sed bibendum
        ipsum massa at risus. Aenean in ex faucibus, sagittis tellus id, dictum dolor. Duis lectus
        nulla, varius vulputate felis sed, volutpat scelerisque nunc. Nullam vehicula nibh nec
        libero eleifend accumsan. In porttitor finibus tortor, a ultrices turpis fermentum at.
        Aenean arcu neque, bibendum sed finibus id, malesuada nec metus. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aliquam sit amet eros a
        nulla consectetur molestie. Curabitur a libero a sapien varius aliquet et convallis orci.
      </p>
      <p>
        Fusce vulputate magna tortor, ac condimentum ipsum pellentesque et. Aliquam gravida nec
        felis eu facilisis. Donec ornare viverra arcu, ac mattis nisi faucibus gravida. Quisque in
        ante placerat, congue tortor bibendum, aliquet augue. Nam sed molestie neque, non
        ullamcorper nisi. Nunc massa elit, vulputate eu lacus et, dignissim vulputate nunc. Aliquam
        efficitur ullamcorper justo condimentum tempus. Vivamus id pulvinar augue. Nullam eget
        maximus turpis. Ut nec velit a dui placerat semper. Nullam egestas mollis efficitur. Morbi
        suscipit lorem ut sem posuere, at consequat sem tincidunt. Sed fringilla enim nec nunc
        consectetur, vel pharetra odio pulvinar. Pellentesque nulla quam, luctus non cursus vel,
        rutrum quis nisi.
      </p>
      <p>
        Aenean lobortis nibh quis odio rutrum, ac bibendum lectus sodales. Interdum et malesuada
        fames ac ante ipsum primis in faucibus. Nullam auctor condimentum purus vitae convallis.
        Donec vitae augue vel sapien lacinia fringilla facilisis nec est. Donec ac mollis nunc.
        Curabitur laoreet vestibulum ante, sit amet dapibus erat. Proin felis est, pulvinar non
        lobortis quis, commodo non arcu. Quisque vehicula sapien et est tempor vulputate. Donec in
        fringilla leo.
      </p>
      <p>
        Sed id lobortis nibh, at lacinia tortor. Quisque eget placerat libero. Morbi sollicitudin
        felis sed nisi viverra rutrum. Aliquam justo ipsum, consectetur a consectetur sit amet,
        efficitur sed quam. Sed dictum justo porta aliquam dictum. Ut viverra mattis dolor, vel
        gravida nisl. Nunc viverra diam elit, vel malesuada lorem vehicula ac.
      </p>
      <p>
        Vivamus venenatis eget sem eget fringilla. Proin ac orci purus. Mauris dictum commodo
        libero, vel ullamcorper eros hendrerit vitae. Vivamus porttitor ex quis augue laoreet, eu
        gravida arcu commodo. Pellentesque et volutpat ex. Nullam eu euismod orci, vitae rhoncus
        enim. Cras finibus leo id nulla semper iaculis. Suspendisse lectus augue, malesuada eu
        ornare a, sollicitudin lobortis enim. Mauris sit amet congue dolor. Morbi quis tristique
        sapien.
      </p>
      <p>
        Sed aliquet felis neque, et tristique nisi placerat varius. Aenean at mattis metus, ut
        interdum sem. In arcu lectus, finibus id tortor quis, tempor viverra purus. Donec dictum
        posuere elit a vestibulum. Class aptent taciti sociosqu ad litora torquent per conubia
        nostra, per inceptos himenaeos. Cras a convallis tortor. Cras sollicitudin nisl non quam
        tristique tincidunt. Integer non semper felis. Ut bibendum pharetra quam, quis pretium augue
        mattis nec. Praesent est odio, tempus quis lobortis at, ultricies a ante. Duis interdum
        mauris at lacus dignissim, nec efficitur turpis mollis. Vivamus hendrerit lacus sit amet
        odio pretium venenatis. Donec ultrices rutrum magna vitae placerat.
      </p>
      <p>
        Nam malesuada, tellus ut gravida consectetur, dui risus faucibus elit, sit amet imperdiet
        tellus mi eu neque. Vivamus sed consequat justo. Donec convallis varius maximus. Praesent
        malesuada auctor augue non euismod. Fusce sed est vehicula, facilisis nulla id, lacinia
        velit. Quisque vitae ipsum vitae tellus pellentesque laoreet. Integer sagittis dictum
        tincidunt. Phasellus tincidunt iaculis lorem nec feugiat. Suspendisse vel luctus ante. Sed
        imperdiet rutrum lorem, vel venenatis nulla pretium ac. Etiam a mi ut nisl euismod lacinia.
        Nam eu felis eu ante tristique faucibus pretium in tellus. Quisque feugiat velit et nulla
        pretium sagittis vitae sodales massa. Ut et porta ante. Nullam suscipit dui et pellentesque
        porttitor. Nulla blandit non libero tincidunt consequat.
      </p>
      <p>
        Donec tempor orci et consectetur ultrices. Curabitur rhoncus magna condimentum mauris
        ultrices, eget vehicula ligula laoreet. Nunc consequat vestibulum tincidunt. Nunc nec sem
        quis odio convallis commodo. In hac habitasse platea dictumst. Curabitur vehicula, metus sed
        molestie venenatis, ante ipsum vestibulum enim, nec rutrum eros ex eget dolor. Nullam
        suscipit, diam sed accumsan tristique, erat ante mattis ligula, vitae tempus est tellus at
        lorem. Vivamus et lectus maximus, pellentesque sem sed, commodo metus. Maecenas et ipsum ut
        augue gravida consequat sed ut diam. Sed eu ante a lectus pellentesque rhoncus sed a lectus.
        Praesent ut felis auctor, faucibus magna sit amet, lacinia massa. Aenean eget ultrices
        justo. Proin mattis dui id ante luctus, nec semper nisl lacinia. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Nunc tincidunt non magna in mattis. Morbi neque purus,
        malesuada et nulla vel, interdum interdum erat.
      </p>
      <p>
        Phasellus lobortis rutrum magna ac ullamcorper. Aliquam non porta sapien, at finibus ipsum.
        Nunc ut orci malesuada, tincidunt felis vel, vulputate metus. Quisque venenatis est ac
        eleifend venenatis. Duis quis augue egestas purus bibendum euismod. Duis interdum quam eget
        mauris accumsan blandit. Curabitur et elit orci.
      </p>
    </div>

    <div class="btn-container">
      <button
        *ngIf="backButton"
        class="back-button"
        mat-flat-button
        color="accent"
        class="button-size-xl"
        (click)="backButtonClick.next()"
      >
        Zurück
      </button>
    </div>
  </div>
</div>
