<app-signup-header></app-signup-header>
<div class="porter-container signup-container">
  <div class="logo-container">
    <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
  </div>
  <div *ngIf="!confirm" class="modal-s content-container color-bg-white p-6">
    <div class="modal-header w-100 row">
      <h2 class="modal-title">Passwort zurücksetzen</h2>
    </div>
    <div class="modal-text">
      <p>
        Geben Sie ein neues Passwort für ihr XCYDE-Konto mit der E-Mail-Adresse
        <strong>{{ email }}</strong> ein.
      </p>
      <p>
        <strong>Achtung:</strong> Minimum je ein Kleinbuchstaben, Großbuchstaben, Zahl,
        Sonderzeichen und 8 Zeichen verwenden!
      </p>
    </div>

    <form (ngSubmit)="changeMode(true)" [formGroup]="form">
      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Neues Passwort</mat-label>

        <input
          matInput
          placeholder="Neues Passwort"
          [type]="hidePw ? 'password' : 'text'"
          minlength="8"
          name="password"
          formControlName="password"
          required
        />
        <mat-icon matSuffix class="hide-password" (click)="hidePw = !hidePw">{{
          hidePw ? 'visibility' : 'visibility_off'
        }}</mat-icon>

        <mat-error *ngIf="form.controls.password.errors?.minlength" class="form-field-error"
          >Mindestens 8 Zeichen verwenden</mat-error
        >
        <mat-error
          *ngIf="
            form.controls.password.errors?.tooWeak && !form.controls.password.errors?.minlength
          "
          class="form-field-error"
        >
          Kein Klein- & Großbuchstaben, Zahl & Sonderzeichen
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Passwort erneut eingeben</mat-label>

        <input
          matInput
          placeholder="Passwort erneut eingeben"
          [type]="hidePw ? 'password' : 'text'"
          minlength="6"
          name="passwordCheck"
          formControlName="passwordCheck"
          required
        />
        <mat-icon matSuffix class="hide-password" (click)="hidePw = !hidePw">{{
          hidePw ? 'visibility' : 'visibility_off'
        }}</mat-icon>

        <mat-error
          *ngIf="!!form.controls.passwordCheck.value && form.errors?.passwordsNotMatching"
          class="form-field-error"
        >
          Passwörter stimmen nicht überein
        </mat-error>
      </mat-form-field>

      <div class="btn-container btn-container-w100">
        <button
          mat-flat-button
          color="accent"
          class="button-size-xl"
          [disabled]="!form.touched || form.invalid || waitingForResponse"
        >
          Passwort zurücksetzen
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="confirm" class="modal-s content-container color-bg-white confirm-container">
    <div class="modal-header w-100 row">
      <h2 class="modal-title">Passwort zurücksetzen</h2>
    </div>
    <div class="modal-text">
      <p>
        Sind Sie sicher, dass Sie Ihr Passwort für die E-Mail-Adresse
        <strong>{{ email }}</strong> zurücksetzen möchten?
      </p>
    </div>
    <div class="btn-container btn-container-w100">
      <button
        mat-stroked-button
        class="cancel-button button-size-xl"
        color="accent"
        (click)="changeMode(false)"
      >
        Nein
      </button>
      <button
        mat-flat-button
        class="submit-button button-size-xl"
        color="accent"
        (click)="resetPassword()"
      >
        Ja, zurücksetzen
      </button>
    </div>
  </div>
</div>
<app-signup-footer></app-signup-footer>
