<div class="porter-container">
  <div>
    <div class="logo-container">
      <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
    </div>

    <div class="modal content-container color-bg-white p-6">
      <div class="modal-header w-100 row">
        <h2 class="modal-title">PORTER Download</h2>
      </div>
      <div class="modal-main">
        <p class="modal-subtitle">Ihr Download wird automatisch gestartet...</p>
        <p class="modal-text">
          Sollte der Download der neuesten PORTER Version nicht starten, klicken Sie bitte den
          Button oder laden Sie die Seite neu.
        </p>
        <div class="dialog-actions row mt-7">
          <button mat-flat-button (click)="getLatestVersion()" color="accent">
            Desktop-App herunterladen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
