import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import '@angular/common/locales/global/de';

// Material Design Components
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// 3D Party
import * as Sentry from '@sentry/angular';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/signup/login/login.component';

import { ErrorDialogComponent } from './components/dialogs/errordialog/errordialog.component';
import { StreamDialogComponent } from './components/dialogs/stream-dialog/stream-dialog.component';
import { DownloadComponent } from './components/download/download.component';
import { RequestPasswordResetComponent } from './components/signup/login/request-password-reset/request-password-reset.component';
import { ResetPasswordComponent } from './components/signup/login/reset-password/reset-password.component';
import { ConfirmationComponent } from './components/signup/register/confirmation/confirmation.component';
import { RegisterComponent } from './components/signup/register/register.component';
import { TermsOfServiceComponent } from './components/signup/register/terms-of-service/terms-of-service.component';
import {
  ExampleModal,
  VisualUnitsComponent,
} from './components/visual-units/visual-units.component';

// Services
import { AssetService } from './services/asset.service';
import { AuthService } from './services/auth.service';
import { BuildingService } from './services/building.service';
import { UniversalDeviceDetectorService } from './services/device-detector.service';
import { EnvironmentService } from './services/environment.service';
import { ErrorDialogService } from './services/errordialog.service';
import { ExporterPluginService } from './services/exporter-plugin.service';
import { FilteredArticlesService } from './services/filtered-articles.service';
import { FloorplanAIService } from './services/floorplan-ai.service';
import { FloorplanService } from './services/floorplan.service';
import { FloorplannerService } from './services/floorplanner.service';
import { InfoboxDialogService } from './services/infobox-dialog.service';
import { InputFileService } from './services/input-file.service';
import { LevelService } from './services/level.service';
import { NavigationHistoryService } from './services/navigation-history.service';
import { NavigationService } from './services/navigation.service';
import { ProjectService } from './services/project.service';
import { ReleaseNotesService } from './services/releasenotes.service';
import { SchemaPropertiesService } from './services/schema-properties.service';
import { SchemasService } from './services/schemas.service';
import { ScreenshotService } from './services/screenshot.service';
import { StreamingService } from './services/streaming.service';
import { TokenService } from './services/token.service';
import { TwoActionsDialogService } from './services/two-actions-dialog.service';
import { UnitService } from './services/unit.service';
import { UnrealService } from './services/unreal.service';
import { UrlService } from './services/url.service';
import { UtilityService } from './services/utility.service';
import { VersionService } from './services/version.service';
import { DateService } from './services/date.service';

// Resolvers
import { ConfigurationResolverService } from './resolvers/configuration-resolver.service';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Shared Module
import { SharedModule } from './shared/shared.module';

// TODO: Core Modules?
import { AuthGuard } from './auth.guard';
import { ExportPluginGuard } from './export-plugin.guard';
import { SalesGuard } from './sales.guard';
import { UserGroupGuard } from './user-group.guard';

// Interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { AppInitializeService } from './services/app-initialize.service';

// Pipes
import { FloorplanFetchPipe } from './pipes/floorplan-fetch.pipe';
import { ScreenshotFetchPipe } from './pipes/screenshot-fetch.pipe';

import { InfoboxDialogComponent } from './components/dialogs/infobox-dialog/infobox-dialog.component';
import { SignupHeaderComponent } from './components/signup/signup-header/signup-header.component';

import { ClosingDialogComponent } from './components/dialogs/closing-dialog/closing-dialog.component';
import { TwoActionsDialogComponent } from './components/dialogs/two-actions-dialog/two-actions-dialog.component';
import { WarningDialogComponent } from './components/dialogs/warning-dialog/warning-dialog.component';
import { ManufacturerComponent } from './components/manufacturer/manufacturer.component';
import { ExampleInputWithUnitSelector } from './components/visual-units/helpers/example-input-with-selector/example-input-with-selector';
import { FeatureFlagGuard } from './feature-flag.guard';
import { OnboardingScreenGuard } from './onboarding-screen.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ScreenshotFetchPipe,
    FloorplanFetchPipe,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ConfirmationComponent,
    TermsOfServiceComponent,
    ErrorDialogComponent,
    StreamDialogComponent,
    DownloadComponent,
    SignupHeaderComponent,
    InfoboxDialogComponent,
    ManufacturerComponent,
    VisualUnitsComponent,
    ExampleModal,
    ExampleInputWithUnitSelector,
    ClosingDialogComponent,
    WarningDialogComponent,
    TwoActionsDialogComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,

    // Material Design Components
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    // CDK
    DragDropModule,
    LayoutModule,
  ],
  exports: [MatIconModule],
  providers: [
    AuthGuard,
    SalesGuard,
    UserGroupGuard,
    ExportPluginGuard,
    OnboardingScreenGuard,
    FeatureFlagGuard,
    AuthService,
    EnvironmentService,
    TokenService,
    ProjectService,
    ScreenshotService,
    FloorplanService,
    FloorplannerService,
    UnitService,
    BuildingService,
    LevelService,
    InputFileService,
    UtilityService,
    StreamingService,
    ExporterPluginService,
    ErrorDialogService,
    VersionService,
    FloorplanAIService,
    InfoboxDialogService,
    UniversalDeviceDetectorService,
    ReleaseNotesService,
    AssetService,
    FilteredArticlesService,
    SchemasService,
    SchemaPropertiesService,
    TwoActionsDialogService,
    NavigationService,
    NavigationHistoryService,
    UrlService,
    UnrealService,
    ConfigurationResolverService,
    AppInitializeService,
    DatePipe,
    DateService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        //TODO: set localhost in environment.envSystem and use the variable
        //showDialog: !environment.apiUrl.includes('localhost'),
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializeService.initializeApp,
      deps: [AppInitializeService],
      multi: true,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [ErrorDialogComponent, InfoboxDialogComponent],
})
export class AppModule {}
