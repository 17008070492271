import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { InputFile } from '../business-domain/InputFile';
import { environment } from '../../environments/environment';

@Injectable()
export class InputFileService {
  private inputFilesUrl = environment.apiUrl + '/inputFiles';

  constructor(private http: HttpClient) {}

  getInputFilesByUnit(unitId: string): Observable<InputFile[]> {
    return this.http.get<InputFile[]>(`${environment.apiUrl}/units/${unitId}/inputFiles`);
  }

  uploadInputFile(file: File, unitId: string, type: string): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('unitId', String(unitId));
    formData.append('type', type);
    formData.append('input', file, file.name);

    const req = new HttpRequest('POST', this.inputFilesUrl, formData, { reportProgress: true });

    return this.http.request(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) return Math.round((100 * event.loaded) / event.total);
        if (event.type === HttpEventType.ResponseHeader && !event.ok) throw event;
      }),
    );
  }

  updateInputFile(file: File, unitId: string, type: string): Observable<number> {
    return this.getInputFilesByUnit(unitId).pipe(
      flatMap((floorplans) => {
        const formData: FormData = new FormData();
        formData.append('type', type);
        formData.append('input', file, file.name);

        const req = new HttpRequest('PUT', this.inputFilesUrl + '/' + floorplans[0].id, formData, { reportProgress: true });
        return this.http.request(req);
      }),
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) return Math.round((100 * event.loaded) / event.total);
        if (event.type === HttpEventType.ResponseHeader && !event.ok) throw event;
      }),
    );
  }

  releaseForFinalEdit(inputFileId: string): Observable<any> {
    return this.http.post(`${this.inputFilesUrl}/${inputFileId}/finalEdit`, {});
  }
}
