import { Component, isDevMode, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { UniversalDeviceDetectorService } from '../services/device-detector.service';
import { IconRegistryService } from '../services/icon-registry.service';
import { UrlService } from '../services/url.service';

interface EnvironmentSetup {
  name: string;
  title: string;
  theme: string;
  favoriteIconPath: string;
  logoName: string;
  logoPath: string;
}

const FINGERHAUS: EnvironmentSetup = {
  name: 'fingerhaus',
  title: 'FingerHauszeit | Anwendung',
  theme: 'fingerhaus-theme',
  favoriteIconPath: '../../assets/icons/favicon-fingerhaus.ico',
  logoName: 'fingerhaus-logo',
  logoPath: 'assets/icons/fingerhaus-logo.svg',
};

const PORTER: EnvironmentSetup = {
  name: 'porter',
  title: 'PORTER | Anwendung',
  theme: 'porter-theme',
  favoriteIconPath: '../../assets/icons/favicon.ico',
  logoName: 'porter-logo',
  logoPath: 'assets/icons/porter-logo.svg',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isMobile = false;
  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  customerSystem: String;
  isServiceRoute = false;

  constructor(
    private iconRegistry: IconRegistryService,
    private deviceService: UniversalDeviceDetectorService,
    private renderer: Renderer2,
    private urlService: UrlService,
    private titleService: Title
  ) {
    this.registerLogos();
  }

  async ngOnInit() {
    this.recognizeDomain();
    this.handleMobile();
    this.logInformation();
    this.handleServiceRoute();
  }

  private recognizeDomain() {
    this.urlContainsFingerhaus()
      ? this.setupFingerhausEnvironment()
      : this.setupPorterEnvironment();
    this.updateCustomerSystem();
  }

  private urlContainsFingerhaus(): boolean {
    return this.urlService.urlContains(FINGERHAUS.name);
  }

  private setupFingerhausEnvironment() {
    environment.customerSystem = FINGERHAUS.name;
    document.documentElement.classList.remove(PORTER.theme);
    document.documentElement.classList.add(FINGERHAUS.theme);
    this.favIcon.href = FINGERHAUS.favoriteIconPath;
    this.titleService.setTitle(FINGERHAUS.title);
  }

  private setupPorterEnvironment() {
    environment.customerSystem = PORTER.name;
    document.documentElement.classList.remove(FINGERHAUS.theme);
    document.documentElement.classList.add(PORTER.theme);
    this.favIcon.href = PORTER.favoriteIconPath;
    this.titleService.setTitle(PORTER.title);
  }

  private updateCustomerSystem() {
    this.customerSystem = environment.customerSystem;
  }

  private handleMobile() {
    this.updateIsMobile();
    this.initializeWindowSizeListener();
  }

  private handleServiceRoute() {
    this.isServiceRoute =
      this.urlService.urlContains('service') && this.urlService.urlContains('authentication');
  }

  // TODO: Remove when there is a mobile version
  private updateIsMobile() {
    this.isMobile = window.innerWidth < 767 || this.deviceService.isMobile; // || window.innerHeight < 800;
  }

  private initializeWindowSizeListener() {
    this.renderer.listen(window, 'resize', () => this.updateIsMobile());
  }

  private logInformation() {
    this.logUrl();
    this.logDevelopmentMode();
    this.logEnvironmentSystem();
    this.logCustomerSystem();
    this.logDocument();
  }

  private logUrl() {
    this.urlService.logUrl();
  }

  private logDevelopmentMode() {
    if (isDevMode()) {
      console.log('Development Mode: Development');
    } else {
      console.log('Development Mode: Production');
    }
  }

  private logEnvironmentSystem() {
    switch (environment.envSystem) {
      case 'Development':
        console.log('Environment System: Development');
        console.log('API Url:', environment.apiUrl);
        break;
      case 'Staging':
        console.log('Environment System: Staging');
        console.log('API Url:', environment.apiUrl);
        break;
      case 'Production':
        console.log('Environment System: Production');
        console.log('API Url:', environment.apiUrl);
        break;
      default:
        console.log('Environment System: Undefined');
        console.log('API Url:', environment.apiUrl);
        break;
    }
  }

  private logCustomerSystem() {
    console.log('Environment CustomerSystem: ', environment.customerSystem);
  }

  private logDocument() {
    console.log('Document: ', document.documentElement.classList);
  }

  private registerLogos() {
    this.registerPorterLogo();
    this.registerFingerhausLogo();
  }

  private registerFingerhausLogo() {
    this.iconRegistry.register(FINGERHAUS.logoName, FINGERHAUS.logoPath);
  }

  private registerPorterLogo() {
    this.iconRegistry.register(PORTER.logoName, PORTER.logoPath);
  }
}
