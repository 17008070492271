export const DUPLICATE_PRODUCT = {
  errorCode: '0xASSET1',
  location: 'Produkt anlegen',
  devinfo: '',
  publicinfo:
    'Ein Produkt des gewählten Herstellers mit der angegebenen Hersteller-Artikelnummer wurde bereits erstellt.',
  debug: false,
  autoFocus: false,
};

export const DIAF_EXPORT_MANUFACTURER_NOT_FOUND = {
  errorCode: '0xDIAFE1',
  location: 'DIAF request',
  devinfo: 'Manufacturer not found',
  publicinfo:
    'Dieses Produkt konnte auf Grund eines Fehlers nicht exportiert werden. <br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const DIAF_EXPORT_MANUFACTURER_HAS_NO_DIAF_ID = {
  errorCode: '0xDIAFE2',
  location: 'DIAF request',
  devinfo: 'Manufacturer has no DIAF-Id',
  publicinfo:
    'Dieses Produkt konnte auf Grund eines Fehlers nicht exportiert werden. <br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const DIAF_EXPORT_MISSING_PARAMETERS_IN_DIAF_REQUEST = {
  errorCode: '0xDIAFE3',
  location: 'DIAF request',
  devinfo: 'Missing request parameters',
  publicinfo:
    'Dieses Produkt konnte auf Grund eines Fehlers nicht exportiert werden. <br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const FAILED_DIAF_REQUEST = {
  errorCode: '0xDIAFE4',
  location: 'DIAF export',
  devinfo: 'Error returned from DIAF',
  publicinfo:
    'Die DIAF-Anfrage ist fehlgeschlagen. <br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const DUPLICATE_REGISTRATION = {
  errorCode: '0xFFFFFF',
  location: 'register',
  devinfo: '',
  publicinfo:
    'Für diese E-Mail-Adresse wurde bereits ein Nutzer registriert. Bitte nutzen Sie den Login um sich anzumelden oder wenden Sie sich an unseren <a href="mailto:support@porter.de">Support</a>. Vielen Dank.',
  debug: false,
  autoFocus: false,
};

export const INVALID_FLOOR_PLAN_CONVERSION = {
  errorCode: '0xA12730',
  location: '/grundrisse/grundriss-editor',
  devinfo: '',
  publicinfo:
    'Diese Umwandlungs ID ist ungültig. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const NO_FLOOR_PLAN_CONVERSION = {
  errorCode: '0xFFFFFF',
  location: 'dashboard - getFirstPageOfLatestUserConversions',
  devinfo: '',
  publicinfo:
    'Sie haben noch keine Grundriss-Umwandlungen. Bitte laden Sie einen ersten Grundriss hoch.',
  debug: false,
  autoFocus: false,
};

export const NO_BUILDING_PROJECT = {
  errorCode: '0xFFFFFF',
  location: 'configuration service - getConfigurationByCode',
  devinfo: '',
  publicinfo:
    'Es liegt kein Bauvorhaben für diese BV-Nummer vor.<br><br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

export const FAILED_DIAF_EXPORT_WITH_DUPLICATE_PRODUCT_NUMBER: any = {
  icon: 'cancel',
  iconColor: 'warn',
  title: 'Produkt existiert bereits in DIAF',
  buttonText: 'Okay',
  text: 'Dieses Produkt wurde nicht exportiert, weil in DIAF bereits ein Produkt mit gleicher Produktdatennummer vorhanden ist.',
};

export const FAILED_DIAF_EXPORT_WITH_DUPLICATE_MANUFACTURER_PRODUCT: any = {
  icon: 'cancel',
  iconColor: 'warn',
  title: 'Produkt existiert bereits in DIAF',
  buttonText: 'Okay',
  text: 'Dieses Produkt wurde nicht exportiert. In DIAF ist bereits ein Produkt des gewählten Herstellers mit der angegebenen Hersteller-Artikelnummer vorhanden.',
};

export const FAILED_DIAF_EXPORT_WITH_MANUFACTURER_NOT_PRESENT: any = {
  icon: 'cancel',
  iconColor: 'warn',
  title: 'Hersteller nicht vorhanden',
  buttonText: 'Okay',
  text: 'Dieses Produkt wurde nicht exportiert. In DIAF ist der gewählte Hersteller nicht vorhanden.',
};