import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

export const requiredErrorValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  for (const controlName in control.controls) {
    if (control.controls[controlName].touched && control.controls[controlName].errors && control.controls[controlName].errors.required) {
      return { required: true };
    }
  }

  return null;
};
