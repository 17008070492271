import { Component, Input, OnInit } from '@angular/core';

//TODO: There might be a simpler alternative to these onClick functions
// Could we use a two-way-binding like the following?
/*
  <mat-select [(value)]="selectedOption">
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{option.label}}
    </mat-option>
  </mat-select>
  */
export interface DropdownOption {
  label: string;
  onClick: () => void;
}

export interface DropdownConfiguration {
  isDisabled: boolean;
  title: string;
  placeholder: string;
  options: DropdownOption[];
  selectedItemIndex: number;
  executeClickAfterInitialized: boolean;
  autoWidth: boolean; 
}

export const DEFAULT_DROPDOWN_CONFIGURATION: DropdownConfiguration = {
  isDisabled: false,
  selectedItemIndex: null,
  title: 'Title',
  placeholder: 'Option auswählen',
  options: [],
  executeClickAfterInitialized: false,
  autoWidth: false,
};

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() configuration: DropdownConfiguration;

  selectedItem: DropdownOption;

  ngOnInit() {
    this.initializeSelectedItem();
    if (this.canExecuteClick()) this.executeClick();
  }

  onSelectionChange(selectedItem: DropdownOption) {
    this.selectedItem = selectedItem;
    this.selectedItem.onClick();
  }

  private canExecuteClick() {
    return this.configuration.executeClickAfterInitialized;
  }

  private executeClick() {
    this.selectedItem.onClick();
  }

  private initializeSelectedItem() {
    this.selectedItem = this.configuration.options[this.configuration.selectedItemIndex];
  }
}
