import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Building } from '../business-domain/Building';
import { environment } from '../../environments/environment';

interface Response<T> {
  data: T;
}

@Injectable()
export class BuildingService {
  constructor(private http: HttpClient) {}

  public getBuildingsByUnit(buildingId: string): Observable<Building[]> {
    return this.http
      .get<Response<Building[]>>(environment.apiUrl + `/units/${buildingId}/buildings`)
      .pipe(map((response) => response.data)) as Observable<Building[]>;
  }
}
