import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class SalesGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
      if (!this.authService.getCurrentUser().sales) {
          return true;
      }

      await this.router.navigateByUrl(window.location.pathname);
      return false;
  }
}
