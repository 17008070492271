<app-signup-header></app-signup-header>
<div class="porter-container signup-container">
  <div class="logo-container">
    <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
  </div>

  <div class="modal-s content-container color-bg-white p-6">
    <div class="modal-header w-100 row">
      <h2 *ngIf="!sent" class="modal-title">Passwort zurücksetzen</h2>
      <h2 *ngIf="sent" class="modal-title">Bitte prüfen Sie Ihr E-Mail Postfach!</h2>
    </div>
    <div class="modal-text">
      <p *ngIf="!sent">Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.</p>
      <p *ngIf="sent">
        Wir haben Ihnen einen Link an die E-Mail-Adresse
        <strong>{{ emailCtrl.value }}</strong> gesendet, über welchen Sie ein neues Passwort
        vergeben können.
      </p>
      <p *ngIf="sent">
        Wollen Sie aber dennoch das Passwort nicht neu vergeben, können Sie sich wie gewohnt mit
        Ihrem bisherigen Passwort anmelden.
      </p>
    </div>

    <form *ngIf="!sent" (ngSubmit)="requestPasswordReset()">
      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>E-Mail-Adresse eingeben</mat-label>
        <input
          matInput
          placeholder="E-Mail-Adresse eingeben"
          [formControl]="emailCtrl"
          name="email"
          required
        />

        <mat-error *ngIf="emailCtrl.errors?.unknown" class="form-field-error"
          >E-Mail-Adresse ist nicht registriert
        </mat-error>
        <mat-error *ngIf="!!emailCtrl.value && emailCtrl.errors?.email" class="form-field-error"
          >Ungültige E-Mail-Adresse</mat-error
        >
      </mat-form-field>

      <div class="btn-container btn-container-w100">
        <button
          mat-flat-button
          color="accent"
          class="button-size-xl"
          [disabled]="emailCtrl.invalid || waitingForResponse"
        >
          Passwort zurücksetzen
        </button>
        <button mat-stroked-button color="accent" class="button-size-xl" routerLink="/login">
          Zurück zum Login
        </button>
      </div>
    </form>

    <div *ngIf="sent" class="btn-container btn-container-w100">
      <button mat-stroked-button color="accent" class="button-size-xl" routerLink="/login">
        Zurück zum Login
      </button>
    </div>

    <!-- <p *ngIf="sent" class="email">{{ email }}</p> -->
  </div>
</div>
<app-signup-footer></app-signup-footer>
