import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

const removeNotMatching = (errors: ValidationErrors): ValidationErrors | null => {
  if (errors) {
    const { notMatching, ...others } = errors;
    if (Object.keys(others).length > 0) return others;
  }
  return null;
};

const removeTooWeak = (errors: ValidationErrors): ValidationErrors | null => {
  if (errors) {
    const { tooWeak, ...others } = errors;
    if (Object.keys(others).length > 0) return others;
  }
  return null;
};

export const passwordCheckValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password');
  const passwordCheck = control.get('passwordCheck');

  // at least 1 lower case, 1 upper case, 1 number, 1 special charachter, length >= 8
  const pwRegexLower = new RegExp('[a-z]');
  const pwRegexUpper = new RegExp('[A-Z]');
  const pwRegexNumber = new RegExp('[0-9]');
  const pwRegexSpecial = new RegExp('[?!@#$%^&*]');

  if (
    password &&
    password.value &&
    !(
      pwRegexLower.test(password.value) &&
      pwRegexUpper.test(password.value) &&
      pwRegexNumber.test(password.value) &&
      pwRegexSpecial.test(password.value)
    )
  ) {
    password.setErrors({ ...password.errors, tooWeak: true });
    passwordCheck.setErrors(null);
    return { passwordsTooWeak: true };
  } else {
    const existingPasswordErrors = removeTooWeak(password.errors);
    password.setErrors(existingPasswordErrors);
  }

  // password matches passwordCheck field
  if (password && passwordCheck && password.value === passwordCheck.value) {
    const existingPasswordCheckErrors = removeNotMatching(passwordCheck.errors);
    passwordCheck.setErrors(existingPasswordCheckErrors);

    return null;
  }

  passwordCheck.setErrors({ ...passwordCheck.errors, notMatching: true });
  return { passwordsNotMatching: true };
};
