import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve } from '@angular/router';

import { UrlService } from '../services/url.service';
import { ConfigurationService } from '../services/configuration.service';


@Injectable({
  providedIn: 'root',
})
export class ConfigurationResolverService implements Resolve<any> {
  readonly ROUTE_PARAMETER_CONFIGURATION_ID_KEY = 'configurationId';
  readonly QUERY_PARAMETER_ACCESS_CODE_KEY = 'bvn';

  constructor(
    private urlService: UrlService, 
    private configurationService: ConfigurationService, 
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const queryParameters = route.queryParams;

    /*
    TODO: Consider the following:
    For now, configurationId as Route Parameter is handled in app.component.ts and removed from the URL afterwards.
    Thus, it cannot be read in this resolver anymore. The logic in app.component should be reconsidered, so this resolver can handle it.
    */
    if (this.doesUrlContainIdAsRouteParameter()) {
      const id = this.getConfigurationIdFromRouteParameter();
      await this.setConfigurationByCodeFromRouteParameter(id);
    } else if (this.doesUrlContainIdAsQueryParameter(queryParameters)) {
      const accessCode = this.getAccessCodeFromQueryParams(queryParameters);
      await this.setConfigurationByAccessCode(accessCode);
    } else {
      // make http call to /users/configuration to get the configuration
      try {
        await this.configurationService.initBaseConfiguration();
      } catch (error) {
        console.warn('Error while fetching base configuration', error)
      }
    }
  }

  private doesUrlContainIdAsRouteParameter(): boolean {
    return this.urlService.urlContains(this.ROUTE_PARAMETER_CONFIGURATION_ID_KEY);
  }

  private doesUrlContainIdAsQueryParameter(queryParameters: Params): boolean {
    return !!queryParameters[this.QUERY_PARAMETER_ACCESS_CODE_KEY];
  }

  private getAccessCodeFromQueryParams(queryParameters: Params): string {
    return queryParameters[this.QUERY_PARAMETER_ACCESS_CODE_KEY];
  }

  private getConfigurationIdFromRouteParameter(): string {
    return this.urlService.getRouteParameterValue('configurationId');
  }

  private async setConfigurationByCodeFromRouteParameter(id: string) {
    await this.configurationService.updateConfigurationById(id);
  }

  private async setConfigurationByAccessCode(accessCode: string) {
    await this.configurationService.setConfigurationByAccessCode(accessCode);
  }
}
