import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Version } from '../business-domain/Version';
import { AuthService } from './auth.service';

@Injectable()
export class VersionService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getLatestVersion() {
    let promise = new Promise<Version>((resolve, reject) => {
      let link = environment.apiUrl + '/versions/latest';
      this.http
        .get<Version>(link)
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          error => {
            reject(error.message);
          },
        );
    });
    return promise;
  }

  async downloadApplicationWithCode(code: string) {
    const hasValidCode = this.authService.isValidCode(code).subscribe(async res => {
      console.log(res);
      if (res) {
        const path = (await this.getLatestVersion()).path;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.click();
        // we have to wait otherwise the page will close before the download starts
        await new Promise(resolve => setTimeout(resolve, 500));
        window.close();
      }
    });
  }
}
