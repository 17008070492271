import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

const FINGERHAUS_PROJECTS_PATH = '/fhProjects';

export interface BaseProject {
  clientAppointmentDate: string;
  clientUserId: string;
  consultantEmail: string;
  consultantFirstName: string;
  consultantLastName: string;
  consultantUserId: string;
  contractSignDate: string;
  lockEmailSent: number;
}

export interface BuildingProject extends BaseProject {
  customers: CustomerInfo[];
  number: string;
  location: string;
  status: ProjectStatus;
}

export enum ProjectStatus {
  NOT_STARTED = 'Unbearbeitet',
  IN_PROGRESS = 'In Bearbeitung',
  FROZEN = 'Merkliste gesperrt',
  FINISHED = 'Abgeschlossen',
}

export interface FingerhausProject extends BaseProject {
  bvhData: BuildingProjectData;
  bvhNumber: string;
  status: string;
}

export interface BuildingProjectData {
  location: string;
  customers: CustomerInfo[];
}

export interface CustomerInfo {
  title: string;
  firstName: string;
  lastName: string;
  street: string;
  isocode: string;
  zipcode: number;
  city: string;
  phone: string;
  email: string;
}

export interface BuildingProjectResponse {
  fhProjects: FingerhausProject[];
  pageCount: number;
}

@Injectable()
export class BuildingProjectService {
  constructor(private readonly http: HttpClient) {}

  async getAllFingerhausBuildingProjects(): Promise<BuildingProject[]> {
    const response: BuildingProjectResponse = await this.http
      .get<BuildingProjectResponse>(environment.apiUrl + FINGERHAUS_PROJECTS_PATH)
      .toPromise();

    return response.fhProjects.map((project) => ({
      ...project,
      location: project.bvhData?.location,
      customers: project.bvhData?.customers,
      number: project.bvhNumber,
      status: ProjectStatus[project.status],
    }));
  }
}
