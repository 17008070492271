import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDialogService } from '../services/errordialog.service';
import { InfoboxDialogService } from '../services/infobox-dialog.service';
import {
  DUPLICATE_PRODUCT,
  DUPLICATE_REGISTRATION,
  FAILED_DIAF_EXPORT_WITH_DUPLICATE_MANUFACTURER_PRODUCT,
  FAILED_DIAF_EXPORT_WITH_DUPLICATE_PRODUCT_NUMBER,
  FAILED_DIAF_REQUEST,
  INVALID_FLOOR_PLAN_CONVERSION,
  DIAF_EXPORT_MANUFACTURER_HAS_NO_DIAF_ID,
  DIAF_EXPORT_MANUFACTURER_NOT_FOUND,
  DIAF_EXPORT_MISSING_PARAMETERS_IN_DIAF_REQUEST,
  NO_BUILDING_PROJECT,
  NO_FLOOR_PLAN_CONVERSION,
  FAILED_DIAF_EXPORT_WITH_MANUFACTURER_NOT_PRESENT,
} from './error.interceptor.data';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private interceptedError: any;
  private expectedErrorOccured: boolean;

  constructor(
    private errorDialogService: ErrorDialogService,
    private infoboxDialogService: InfoboxDialogService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        this.interceptedError = error;
        this.expectedErrorOccured = false;

        if (this.isHttpError() && this.shouldShowErrors()) {
          this.showGeneralErrors();
          this.showFloorPlanErrors();
          this.showBuildingProjectErrors();
          this.showDiafErrors();
          this.showUnexpectedErrors();
        }

        return throwError(this.interceptedError);
      })
    );
  }

  private isHttpError(): boolean {
    return this.interceptedError instanceof HttpErrorResponse;
  }

  private shouldShowErrors() {
    return !(this.isMissingToken() || this.isOnLoginPage() || this.isDiafRequest());
  }

  private showGeneralErrors() {
    if (this.isDuplicateRegistrationError()) {
      this.showError(DUPLICATE_REGISTRATION);
    }

    if (this.isDuplicateProduct()) {
      this.showError(DUPLICATE_PRODUCT);
    }

    if (this.hasNoManufacturer()) {
      this.showError(DIAF_EXPORT_MANUFACTURER_NOT_FOUND);
    }
  }

  private showFloorPlanErrors() {
    if (this.isInvalidFloorPlanConversion()) {
      this.showError(INVALID_FLOOR_PLAN_CONVERSION);
    }

    if (this.isFloorPlanConversionNotPresent()) {
      this.showError(NO_FLOOR_PLAN_CONVERSION);
    }
  }

  private showBuildingProjectErrors() {
    if (this.hasNoBuildingProject()) {
      this.showError(NO_BUILDING_PROJECT);
    }
  }

  private showDiafErrors() {
    if (this.manufacturerHasNoDiafId()) {
      this.showError(DIAF_EXPORT_MANUFACTURER_HAS_NO_DIAF_ID);
    }

    if (this.hasMissingParametersInDiafRequest()) {
      this.showError(DIAF_EXPORT_MISSING_PARAMETERS_IN_DIAF_REQUEST);
    }

    if (this.isDiafSystemError()) {
      if (this.isDiafProductNumberAlreadyPresent()) {
        this.showInfo(FAILED_DIAF_EXPORT_WITH_DUPLICATE_PRODUCT_NUMBER);
      } else if (this.isManufacturerAndManufacturerArticleNumberAlreadyPresent()) {
        this.showInfo(FAILED_DIAF_EXPORT_WITH_DUPLICATE_MANUFACTURER_PRODUCT);
      } else if (this.isManufacturerNotPresent()){
         this.showInfo(FAILED_DIAF_EXPORT_WITH_MANUFACTURER_NOT_PRESENT) 
      } else {
        this.showError(FAILED_DIAF_REQUEST);
      }
    }
  }

  private showUnexpectedErrors() {
    if (!this.expectedErrorOccured) {
      if (this.isValidError()) {
        this.showError(this.interceptedError.error);
      } else {
        this.showError(this.buildUnknownErrorMessage());
      }
    }
  }

  private isMissingToken() {
    return this.interceptedError.error.errorCode === '0xPAVRR5';
  }
  private isOnLoginPage() {
    return window.location.pathname === '/login';
  }

  private isDiafRequest() {
    // don't show error dialog on DIAF request
    // TODO: Remove this if in backend the return object includes the error message instead of a thrown 404 error
    // TODO: error texts from DIAF change over time, previous ones are kept inside the condition just in case
    return (
      this.interceptedError.status == 404 &&
      (this.interceptedError.error.error == 'Artikel_Nr Hersteller nicht vorhanden' ||
        this.interceptedError.error.error == 'Artikelnr . nicht vorhanden' ||
        this.interceptedError.error.error == 'Kein Artikel für Hersteller vorhanden' ||
        this.interceptedError.error.error == 'Artikel_Nr zum Hersteller nicht vorhanden')
    );
  }

  private isDuplicateRegistrationError() {
    return window.location.pathname === '/register' && this.interceptedError.status == 409;
  }

  private isDuplicateProduct() {
    return this.interceptedError.error.errorCode == '0xASSET1';
  }

  private buildUnknownErrorMessage() {
    const errorMessage = this.interceptedError.message || 'Unknown Error';
    const supportMessage =
      '<br><br>Bei Fragen wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.';
    const message = errorMessage + supportMessage;
    return {
      errorCode: '0xFFFFFF',
      location: '',
      devinfo: '',
      publicinfo: message,
      debug: false,
      autoFocus: false,
    };
  }

  private isValidError() {
    return (
      this.interceptedError.error != undefined && this.interceptedError.error.errorCode != undefined
    );
  }

  private hasNoBuildingProject() {
    // TODO: Refactor condition
    return (
      /^\/bauvorhaben\/\d+\/merkliste$/.test(window.location.pathname) &&
      this.interceptedError.status == 403
    );
  }

  private isFloorPlanConversionNotPresent() {
    return (
      window.location.pathname === '/grundrisse/grundriss-editor' &&
      this.interceptedError.status == 500
    );
  }

  private isInvalidFloorPlanConversion() {
    return (
      window.location.pathname === '/grundrisse/grundriss-editor' &&
      this.interceptedError.status == 404
    );
  }

  private isManufacturerAndManufacturerArticleNumberAlreadyPresent() {
    // The following error is also returned if in addition to manufacturer and manufacurerUuId also productDataId already exists
    return (
      this.interceptedError.error.devinfo == 'Hersteller und Hersteller-Artikelnr bereits vorhanden'
    );
  }

  private isDiafProductNumberAlreadyPresent(): boolean {
    return this.interceptedError.error.devinfo == 'Produktdatennummer bereits vorhanden';
  }

  private isManufacturerNotPresent(): boolean {
    return this.interceptedError.error.devinfo == 'Hersteller nicht vorhanden';
  }

  private hasNoManufacturer(): boolean {
    return this.interceptedError.error.errorCode == '0xDIAFE1';
  }

  private manufacturerHasNoDiafId(): boolean {
    return this.interceptedError.error.errorCode == '0xDIAFE2';
  }

  private hasMissingParametersInDiafRequest(): boolean {
    return this.interceptedError.error.errorCode == '0xDIAFE3';
  }

  private isDiafSystemError(): boolean {
    return this.interceptedError.error.errorCode == '0xDIAFE4';
  }

  private showInfo(data: any) {
    this.expectedErrorOccured = true;
    this.infoboxDialogService.open(data);
  }

  private showError(data: any) {
    this.expectedErrorOccured = true;
    this.errorDialogService.openDialog(data);
  }
}
