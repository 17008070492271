import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[hasFeatureFlag]',
})
export class FeatureFlagDirective {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly auth: AuthService
  ) {}

  @Input()
  set hasFeatureFlag(featureFlag: string) {
    if (this.auth.hasFeatureFlag(featureFlag)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
