import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TwoActionsDialogComponent } from '../components/dialogs/two-actions-dialog/two-actions-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TwoActionsDialogService {
  constructor(private dialog: MatDialog) {}

  private dialogRef: MatDialogRef<TwoActionsDialogComponent>;
  private confirmed;

  public openDialog(options) {
    this.dialogRef = this.dialog.open(TwoActionsDialogComponent, {
      autoFocus: false,
      backdropClass: 'backdropBackground',
      data: options,
    });
  }

  public setConfirmed(confirmed: boolean) {
    this.confirmed = confirmed;
  }

  public result(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(() => this.confirmed)
    );
  }
}
