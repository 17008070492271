import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, zip } from 'rxjs';
import { saveAs } from 'file-saver';
import * as path from 'path';
import { map } from 'rxjs/operators';

const JSZip = require('jszip');

import { environment } from '../../environments/environment';
import { Screenshot } from '../business-domain/Screenshot';

@Injectable()
export class ScreenshotService {
  constructor(private http: HttpClient) {}

  getScreenshotsByLevel(levelId: string): Observable<Screenshot[]> {
    return this.http.get<Screenshot[]>(`${environment.apiUrl}/levels/${levelId}/screenshots`);
  }

  getScreenshot(screenshotId: string, thumbnail = false): Observable<string> {
    return this.http
      .get(`${environment.apiUrl}/screenshots/${screenshotId}`, { params: { thumbnail: String(thumbnail) } })
      .pipe(map((screenshot: { data: string }) => screenshot.data));
  }

  deleteScreenshot(screenshotId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/screenshots/${screenshotId}`);
  }

  downloadScreenshots(screenshots: Screenshot[]): void {
    zip(
      ...screenshots.map((screenshot) => this.getScreenshot(screenshot.id).pipe(map((data) => ({ ...screenshot, data })))),
    ).subscribe((downloadedScreenshots) => this.saveScreenshots(downloadedScreenshots));
  }

  shareScreenshots(unitId: string, screenshotIds: string[]): Observable<string> {
    return this.http
      .post<{ code: string }>(`${environment.apiUrl}/screenshots/share`, { unitId, screenshotIds })
      .pipe(map((response) => response.code));
  }

  // CONSUMER STUFF

  getConsumerScreenshotsByCode(code: string): Observable<Screenshot[]> {
    return this.http.get<Screenshot[]>(`${environment.apiUrl}/screenshots/consumer`, { headers: { Authorization: `Code ${code}` } });
  }

  getConsumerScreenshot(code: string, screenshotId: string, thumbnail = false): Observable<string> {
    return this.http
      .get(`${environment.apiUrl}/screenshots/consumer/${screenshotId}`, {
        headers: { Authorization: `Code ${code}` },
        params: { thumbnail: String(thumbnail) },
      })
      .pipe(map((screenshot: { data: string }) => screenshot.data));
  }

  downloadConsumerScreenshots(code: string, screenshots: Screenshot[]): void {
    zip(
      ...screenshots.map((screenshot) => this.getConsumerScreenshot(code, screenshot.id).pipe(map((data) => ({ ...screenshot, data })))),
    ).subscribe((downloadedScreenshots) => this.saveScreenshots(downloadedScreenshots));
  }

  getConsumerInformation(code: string): Observable<{ projectName: string; company: string }> {
    return this.http.get<{ projectName: string; company: string }>(`${environment.apiUrl}/screenshots/consumer/information`, {
      headers: { Authorization: `Code ${code}` },
    });
  }

  private convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512): Blob {
    const base64String = base64Data.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private saveScreenshots(screenshots: { data: string; filename: string }[]) {
    if (screenshots.length > 1) {
      const zip = new JSZip();

      screenshots.reduce((uniqueFilenames, screenshot) => {
        const filename = this.increaseFilenameNumber(uniqueFilenames, screenshot.filename);

        zip.file(filename, screenshot.data.split(',')[1], { base64: true });

        return [...uniqueFilenames, filename];
      }, []);

      zip.generateAsync({ type: 'blob' }).then((zipFile) => saveAs(zipFile, 'screenshots.zip'));
    } else {
      saveAs(this.convertBase64ToBlobData(screenshots[0].data), screenshots[0].filename);
    }
  }

  private increaseFilenameNumber(uniqueFilenames: string[], initialFilename: string): string {
    let filename: string = initialFilename;
    let i = 1;

    while (uniqueFilenames.includes(filename)) {
      const extension = path.extname(initialFilename);
      const baseFilename = path.basename(initialFilename, extension);

      filename = baseFilename + '-' + i + extension;
      i++;
    }

    return filename;
  }
}
