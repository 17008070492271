import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  //TODO: set localhost in environment.envSystem and use the variable
  environment: environment.apiUrl.includes('localhost') ? 'local' : environment.envSystem,
  dsn: 'https://de4c07f82bb1acb41ad643242a5ebedb@o4507106472951808.ingest.de.sentry.io/4507108247928912',
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  //TODO: Adjust this!
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
