<div class="wall-height-input-group m-4">
  <div class="input-with-unit-selection wall-height-form-wrapper">
    <!-- <mat-error *ngIf="referenceValue.errors?.belowMinHeight" class="wall-height-error form-field-error">Minimale
    Deckenhöhe 2m
  </mat-error> -->

    <mat-form-field *ngIf="formGroup" [formGroup]="formGroup" appearance="outline" floatLabel="never" class="">
      <!--TODO: Check if inner-form-field-wrapper needed outside of visual untits. Try using same styles in mat-form-field-infix -->
      <div class="inner-form-field-wrapper">
        <input matInput type="number" [formControl]="formGroup.controls.inputValue" placeholder="123" (input)="onInputChange($event)" />
        <mat-select [formControl]="formGroup.controls.selectorValue" (selectionChange)="onChange($event)">
          <mat-option [value]="option.value" *ngFor="let option of exampleUnits"> {{ option.viewValue }} </mat-option>
        </mat-select>
      </div>
    </mat-form-field>
  </div>
</div>
