import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../business-domain/User';
import { Token } from './token.interface';
import { TokenService } from './token.service';

export interface LoginData {
  email: string;
  password: string;
}

// DAI: LoginResponse should be adapted from BackEnd to match Token in FrontEnd
export interface LoginResponse {
  token: string;
  tokenExpiry: number;
}

interface RegistrationData {
  company: string;
  username: string;
  email: string;
  password: string;
  industry: string;
  city: string;
  zipcode: string;
  street: string;
  houseNumber: string;
  firstName: string;
  lastName: string;
}

export class ErrorType {
  static NO_CONNECTION = new ErrorType('No Connection to server');
  static DUPLICATE_USER = new ErrorType('User exists already');
  constructor(readonly message: string) {}
}

@Injectable()
export class AuthService {
  private user: User;

  constructor(private http: HttpClient, private tokenService: TokenService) {
    this.user = null;
  }

  isAuthenticated(): boolean {
    return this.tokenService.hasValidToken();
  }

  async initialize() {
    await this.initializeUser();
  }

  logout() {
    this.tokenService.deleteToken();
  }

  async login(data: LoginData) {
    this.logout(); // just to be sure

    const token = await this.doLoginRequest(data);
    this.cacheToken(token);
    await this.initialize();
  }

  register(data: RegistrationData): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint, data).pipe(
      catchError((error) => {
        if (error.error.message === 'DUPLICATE_USER') throw ErrorType.DUPLICATE_USER;
        throw ErrorType.NO_CONNECTION;
      })
    );
  }

  getCurrentUser(): User {
    return this.user;
  }

  isValidCode(code: string): Observable<boolean> {
    return this.http
      .post<LoginResponse>(environment.apiUrl + '/sessions/validate', { code })
      .pipe(map((result) => !!result.token));
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/requestReset', {
      email,
    });
  }

  getEmailForResetToken(token: string): Observable<{ email: string }> {
    return this.http.get<{ email: string }>(
      environment.apiUrl + environment.usersEndpoint + `/reset/${token}`
    );
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/reset', {
      token,
      password,
    });
  }

  confirm(token: string): Observable<any> {
    return this.http.post(environment.apiUrl + environment.usersEndpoint + '/confirmation', {
      token,
    });
  }

  async initializeUser() {
    this.user = await this.fetchUser();
  }

  hasFeatureFlag(feature: string): boolean {
    return this.user.featureFlags.includes(feature);
  }

  hasUserGroup(userGroups: string[]): boolean {
    return userGroups.filter((v) => this.user.groups.includes(v)).length > 0;
  }

  private async doLoginRequest(data: LoginData): Promise<LoginResponse> {
    return await this.http
      .post<LoginResponse>(environment.apiUrl + environment.sessionsEndpoint, data)
      .toPromise();
  }

  private createToken(result: LoginResponse): Token {
    return {
      value: result.token,
      expiry: String(result.tokenExpiry),
    };
  }

  private cacheToken(token: LoginResponse) {
    this.tokenService.setToken(this.createToken(token));
  }

  private async fetchUser(): Promise<User> {
    return this.http.get<User>(environment.apiUrl + environment.usersEndpoint).toPromise();
  }
}
