import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'error-dialog',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private domSanitizer: DomSanitizer) {}

  getSanitizedPublicInfo() {
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.data.publicinfo);
  }
}
