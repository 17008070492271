import { Injectable } from '@angular/core';
import { TokenService } from './token.service';

declare var sketchup: any;
declare var revit: any;

@Injectable()
export class ExporterPluginService {
  constructor() {}
  usesExportPlugin = typeof sketchup == 'object' || typeof revit == 'object';

  closePlugin() {
    if (this.usesExportPlugin) {
      try {
        if (typeof sketchup == 'object') sketchup.close();
        if (typeof revit == 'object') revit.sendTextToRevit('close');
      } catch (e) {
        console.log(e);
      }
    }
  }

  startUploadPlugin(tokenService: TokenService, unitId: string) {
    if (this.usesExportPlugin) {
      try {
        const tokenValue = tokenService.getToken().value;

        if (typeof sketchup == 'object')
          sketchup.upload_zip_action({ token: tokenValue, unitID: unitId });
        if (typeof revit == 'object')
          //use sendMessage to Revit witch one combined String, use "," to split strings.
          revit.sendTextToRevit('startUpload,' + tokenValue + ',' + unitId);
        return { uploadStarted: true, uploadError: false };
      } catch (e) {
        console.log(e);
        return { uploadStarted: false, uploadError: true };
      }
    }
  }

  usesExport() {
    return this.usesExportPlugin;
  }
}
