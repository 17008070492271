<div class="modal-s real-modal">
  <div class="modal-header w-100 row">
    <h2 class="modal-title">
      {{ data.title }}
    </h2>
    <div class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="modal-main">
    <p *ngIf="data.subtitle" class="modal-subtitle">{{ data.subtitle }}</p>
    <p class="modal-text" [innerHTML]="data.text"></p>
  </div>
  <div class="dialog-actions row">
    <button mat-button color="primary" mat-dialog-close (click)="cancel()">
      {{ data.leftButtonText }}
    </button>

    <button mat-flat-button color="accent" mat-dialog-close (click)="confirm()">
      {{ data.rightButtonText }}
    </button>
  </div>
</div>
