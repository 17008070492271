import { Component, Injectable,  } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class UniversalDeviceDetectorService  {
    isMobile = false;
    isTablet = false;
    isDesktopDevice = false;
    deviceInfo = null;
    os = null;

    constructor(private deviceService: DeviceDetectorService) {
        this.detectDevice();
    }

    detectDevice() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();    
        console.log(this.deviceInfo);
        // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
        // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
        // console.log("os: "+this.deviceService.os);
        // console.log("browser: "+this.deviceService.browser);
        // console.log("device: "+this.deviceService.device);
        // console.log("os_version: "+this.deviceService.os_version);
      }

      getOS(){
        this.os = this.deviceService.os;
        return this.os;
      }
}