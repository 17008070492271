import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  success: boolean;
  failure: boolean;

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');

    this.authService.confirm(token).subscribe(
      () => {
        this.success = true;
      },
      (error) => {
        this.failure = true;
        console.error(error);
      },
    );
  }
}
