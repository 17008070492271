import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent {
  @Input()
  backButton: boolean;

  @Output()
  backButtonClick = new EventEmitter();
}
