import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

import { passwordCheckValidator } from '../../../../directives/password-check.directive';
import { IconRegistryService } from '../../../../services/icon-registry.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  token: string;
  form: FormGroup;
  hidePw = true;
  confirm = false;
  waitingForResponse = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private iconRegistry: IconRegistryService
  ) {
    this.registerIcons();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    } else {
      this.token = this.activatedRoute.snapshot.paramMap.get('token');

      this.authService.getEmailForResetToken(this.token).subscribe(
        (response) => (this.email = response.email),
        (error) => {
          // TODO: Display error that token is not valid
          this.router.navigate(['/reset']);
        }
      );

      this.form = new FormGroup(
        {
          password: new FormControl(),
          passwordCheck: new FormControl(),
        },
        { validators: passwordCheckValidator }
      );
    }
  }

  changeMode(confirm: boolean): void {
    this.confirm = confirm;
  }

  resetPassword(): void {
    if (!this.form.value.password || !this.form.value.passwordCheck) {
      console.error('Missing parameters');
      return; // should never actually happen
    }

    // TODO: password requirements?
    if (this.form.value.password !== this.form.value.passwordCheck) {
      console.log('passwords are different');
      this.form.controls.passwordCheck.setErrors({ incorrect: true });
      return;
    }

    this.authService.resetPassword(this.token, this.form.value.password).subscribe(
      () => {
        console.log('Reset was successful');
        this.router.navigate(['/login']);
      },
      (error) => {
        console.error(error.message);

        this.waitingForResponse = false;
      }
    );
  }

  private registerIcons() {
    this.iconRegistry.register('porter-logo', 'assets/icons/porter-logo.svg');
  }
}
