import {Product} from "./product.interface";
import {DEFAULT_PRODUCT} from "./default-product";
import { Conversion } from "../temporary-interfaces";
import { DEFAULT_CONVERSION } from "./default-conversion";
import { FingerhausProject} from "../../src/app/services/building-project.service";
import {DEFAULT_BUILDING_PROJECT} from "./default-building-project";

export class MockFactory {
    static createProduct(overrides: Partial<Product> = {}): Product {
        return { ...DEFAULT_PRODUCT, ...overrides };
    }

    static createConversion(overrides: Partial<Conversion> = {}): Conversion {
        return { ...DEFAULT_CONVERSION, ...overrides };
      }

    static createBuildingProject(overrides: Partial<FingerhausProject> = {}): FingerhausProject {
        return { ...DEFAULT_BUILDING_PROJECT, ...overrides };
    }
}