import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SchemaPropertiesService } from './schema-properties.service';

export interface SchemaPath {
  firstSchemaName: string;
  secondSchemaName: string;
  thirdSchemaName: string;
}

export interface ProductSchema {
  children: ProductSchema[];
  displayName: string;
  id: string;
  isVisible: number;
  langCode: string;
  name: string;
  order: number;
  parentId: string;
  plural: string;
  properties?: SchemaPropertiesService;
  schemaId: string;
  selected: boolean;
  singular: string;
  thumbnailURL: string;
}

interface ProductSchemaTreeResponse {
  data: RootSchema[]; // Backend should not send this as an array
}

interface RootSchema {
  children: ProductSchema[];
  displayName: string;
  id: string;
  isVisible: number;
  langCode: string;
  name: string;
  order: number;
  parentId: string;
  plural: string;
  schemaId: string;
  singular: string;
  thumbnailURL: string;
}

export interface SchemaTree {
  data: ProductSchema[];
}

@Injectable({
  providedIn: 'root',
})
export class SchemasService implements OnDestroy {
  private schemaTreeSubscription: Subscription;
  private schemaTreeSubject: BehaviorSubject<any> = new BehaviorSubject<ProductSchemaTreeResponse>({
    data: [],
  });
  private schemaTreeSubscriptionFiltered: Subscription;
  private schemaTreeSubjectFiltered: BehaviorSubject<any> =
    new BehaviorSubject<ProductSchemaTreeResponse>({ data: [] });
  private schemaTreeWithPropsSubject: BehaviorSubject<any> =
    new BehaviorSubject<ProductSchemaTreeResponse>({ data: [] });
  private schemasSubject: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {}

  private initSchemas(): any {
    const availableSchemaTree = this.getAvailableSchemaTree();

    availableSchemaTree.subscribe((schemaTree: { data: any }) => {
      let schemas = this.getFlattenedSchemaTree(schemaTree.data);
      this.schemasSubject.next(schemas);
    });
  }

  getFlattenedSchemaTree(schemaTreeData: ProductSchema[]): Array<any> {
    let schemas: any[] = [];

    aggregateChildren(schemas, schemaTreeData);

    return schemas;

    // kept scoped for now due to high specificity
    function aggregateChildren(array: any, schemas: any) {
      for (let i = 0; i < schemas.length; i++) {
        let schema = schemas[i];
        array.push(schema);

        if (schema.children) aggregateChildren(array, schema.children);
      }
    }
  }

  getSchemaTree(filterByUser: boolean = false): Observable<SchemaTree> {
    const subject = filterByUser ? this.schemaTreeSubjectFiltered : this.schemaTreeSubject;
    if (!subject.getValue().data.length) {
      filterByUser ? this.initSchemaTreeFiltered() : this.initSchemaTree();
    }

    return subject.asObservable().pipe(
      filter((res) => res.data.length),
      map((res) => {
        let schemaTree = { data: [] };
        schemaTree.data = res['data']['0']['children']; //exclude the root level schema
        return schemaTree;
      }),
      first()
    );
  }

  getSchemaTreeWithProperties(): Promise<SchemaTree> {
    let params = new HttpParams();
    params = params.append('includeProperties', 'true');

    let options = { params: params };
    const response: Observable<ProductSchemaTreeResponse> =
      this.http.get<ProductSchemaTreeResponse>(environment.apiUrl + '/schemas/tree', options);

    return response
      .pipe(
        map((res) => {
          this.schemaTreeWithPropsSubject.next(res);

          //get schemas nested in response to adapt to previously expected structure
          let schemaTree = { data: {} };
          schemaTree.data = res['data']['0']['children']; //exclude the root level schema

          return <SchemaTree>schemaTree;
        })
      )
      .toPromise();
  }

  async getChildrenIds(categoryId: string): Promise<string[]> {
    const schemaTree = await this.getSchemaTree().toPromise();
    const category = schemaTree.data.find((schema: any) => schema.id == categoryId);
    if (!category) return [];
    const childrenIds: string[] = [];
    category.children.forEach((child: any) => childrenIds.push(child.id));
    return childrenIds;
  }

  async findSchema(id: string): Promise<ProductSchema> {
    const schemas = await this.getSchemas().toPromise();
    return schemas.find((schema) => schema.id === id) ?? null;
  }

  async findSchemaId(path: SchemaPath): Promise<string> {
    if (!path) return null;

    let schemaTree = await this.getSchemaTree().toPromise();
    const pathSegments = [path.firstSchemaName, path.secondSchemaName, path.thirdSchemaName];
    const activePathSegments = pathSegments.filter((segment) => segment);
    const schemaTreeRoot: any = { children: schemaTree.data };

    let mostSpecificSchema = schemaTreeRoot;

    for (let i = 0; i < activePathSegments.length; i++) {
      const foundChild = mostSpecificSchema.children.find((schema: any) => {
        return schema.displayName === activePathSegments[i];
      });
      if (foundChild) {
        mostSpecificSchema = foundChild;
      } else {
        return null;
      }
    }

    return mostSpecificSchema.id;
  }

  // check whether argument equals a schemaId of the "main schemas"(the schemas of the level directly below the root schema)
  async isAFirstLevelSchema(schemaId: string): Promise<boolean> {
    return this.getSchemaTree()
      .pipe(
        map((res) =>
          res['data'].some((firstLevelSchema: any) => firstLevelSchema['id'] == schemaId)
        )
      )
      .toPromise();
  }

  ngOnDestroy() {
    this.schemaTreeSubscription.unsubscribe();
    this.schemaTreeSubscriptionFiltered.unsubscribe();
  }

  private getAvailableSchemaTree(): Observable<ProductSchemaTreeResponse> {
    if (this.schemaTreeHasData()) {
      return this.schemaTreeSubject;
    } else if (this.schemaTreeWithPropertiesHasData()) {
      return this.schemaTreeWithPropsSubject;
    } else {
      return this.getSchemaTree();
    }
  }

  /*
  An unnecessary combination with getSchemaTree or getSchemaTreeWithProperties in a combineLatest might result in an additional request,
  because initSchemas() may not find any values in getSchemaTreeSubject or getSchemaTreeWithPropsSubject while their initialization didn't receive data yet.
  */
  private getSchemas(): Observable<ProductSchema[]> {
    if (!this.schemasSubject.getValue().length) this.initSchemas();
    return this.schemasSubject.pipe(
      filter((res) => res.length),
      first()
    );
  }

  private subjectHasData(subject: BehaviorSubject<any>): boolean {
    return subject.getValue().data.length;
  }

  private schemaTreeHasData(): boolean {
    return this.subjectHasData(this.schemaTreeSubject);
  }

  private schemaTreeWithPropertiesHasData(): boolean {
    return this.subjectHasData(this.schemaTreeWithPropsSubject);
  }

  private initSchemaTree(): any {
    this.schemaTreeSubscription = this.http
      .get(environment.apiUrl + '/schemas/tree')
      .subscribe((schemaTree) => {
        this.schemaTreeSubject.next(schemaTree);
      });
  }

  private initSchemaTreeFiltered(): any {
    this.schemaTreeSubscriptionFiltered = this.http
      .get(environment.apiUrl + '/schemas/tree?filter=true')
      .subscribe((schemaTree) => {
        this.schemaTreeSubjectFiltered.next(schemaTree);
      });
  }
}
