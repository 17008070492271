import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { APP_PATHS } from '../route-paths';
import { AuthService } from './auth.service';

export enum Page {
  CATALOG,
  ONBOARDING_SCREEN,
  LIVING_STYLE_CONSULTANT,
  LIVING_STYLE_INFO,
  FLOORPLANS,
  APP_LINK,
  MANAGEMENT,
  PROJECTS,
  BUILDING_PROJECTS,
}

export interface OnboardingScreenQueryParameters {
  livingStyleConsultant: 'open';
  livingStyleInfo: 'open';
}

const LIVING_STYLE_CONSULTANT_QUERY_PARAMETER = {
  livingStyleConsultant: 'open',
};

const LIVING_STYLE_INFO_QUERY_PARAMETER = {
  livingStyleInfo: 'open',
};

const CATALOG_PAGE_QUERY_PARAMETER = {
  seite: 1,
};

// CHAT GPT
// Use navigate()
// when you want to navigate to a route within your Angular application and construct the route path
// based on route configuration and parameters.
//
// Use navigateByUrl()
// when you want to navigate to an external URL or to a route using an absolute URL string.
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  async navigateTo(page: Page) {
    console.log('navigateTo', Page[page]);
    switch (page) {
      case Page.CATALOG:
        await this.navigate(APP_PATHS.assetCatalog, CATALOG_PAGE_QUERY_PARAMETER);
        break;
      case Page.MANAGEMENT:
        await this.navigate(APP_PATHS.assetManagement, CATALOG_PAGE_QUERY_PARAMETER);
        break;
      case Page.ONBOARDING_SCREEN:
        await this.navigate(APP_PATHS.onboardingScreen);
        break;
      case Page.LIVING_STYLE_CONSULTANT:
        await this.navigate(APP_PATHS.onboardingScreen, LIVING_STYLE_CONSULTANT_QUERY_PARAMETER);
        break;
      case Page.LIVING_STYLE_INFO:
        await this.navigate(APP_PATHS.onboardingScreen, LIVING_STYLE_INFO_QUERY_PARAMETER);
        break;
      case Page.FLOORPLANS:
        await this.navigate(APP_PATHS.floorPlans);
        break;
      case Page.APP_LINK:
        await this.navigate(APP_PATHS.appLink);
        break;
      case Page.PROJECTS:
        await this.navigate(APP_PATHS.projects);
        break;
      case Page.BUILDING_PROJECTS:
        await this.navigate(APP_PATHS.buildingProjects);
        break;
    }
  }

  async getBaseRouteSegment(activatedRoute: ActivatedRoute): Promise<string> {
    let pathFromRoot = await activatedRoute.pathFromRoot[1].url.pipe(first()).toPromise();
    return pathFromRoot[0].path;
  }

  getPathForPrecedingPage(path: string): string {
    const substring = 'seite=';
    const parameterIndex = path.indexOf(substring);
    const charIndex = parameterIndex + substring.length;
    const page = path.charAt(charIndex);
    const newPage = +page - 1;
    const newPath = path.substring(0, charIndex) + newPage + path.substring(charIndex + 1);
    return newPath;
  }

  isAssetCatalog(): boolean {
    return (
      this.router.url.includes(APP_PATHS.assetCatalog) ||
      this.router.url.includes(APP_PATHS.assetCatalogOnly)
    );
  }

  isAssetManagement(): boolean {
    return this.router.url.includes(APP_PATHS.assetManagement);
  }

  isBuildingProjects(): boolean {
    return this.router.url.includes(APP_PATHS.buildingProjects);
  }

  isFloorPlans(): boolean {
    return this.router.url.includes(APP_PATHS.floorPlans);
  }

  async guardNavigation(next: ActivatedRouteSnapshot) {
    if (this.isOnProjects(next) || this.isAdmin()) {
      await this.navigateTo(Page.FLOORPLANS);
    } else if (this.isBuilder()) {
      await this.navigateTo(Page.CATALOG);
    } else if (this.canNavigateToProductManagement()) {
      await this.navigateTo(Page.MANAGEMENT);
    } else {
      await this.navigateTo(Page.APP_LINK);
    }
  }

  private async navigate(url: string, queryParameters?: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: queryParameters,
    };

    await this.router.navigate([url], navigationExtras);
  }

  private isAdmin() {
    return this.authService.hasUserGroup([environment.adminGroup]);
  }

  private isBuilder() {
    return this.authService.hasUserGroup(environment.FINGERHAUS_BUILDER);
  }

  private canNavigateToProductManagement() {
    return (
      this.authService.hasUserGroup(environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP) ||
      this.authService.hasUserGroup(environment.FINGERHAUS_CONSULTANT_GROUP) ||
      this.authService.hasUserGroup(environment.FINGERHAUS_ADMIN_GROUP)
    );
  }

  private isOnProjects(next: ActivatedRouteSnapshot) {
    return next.routeConfig.path === 'projects';
  }
}
