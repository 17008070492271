import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Project } from '../../../business-domain/Project';
import { BaseComponent } from '../../../modules/asset-catalog/components/base-component/base-component.component';
import { AuthService } from '../../../services/auth.service';
import { EnvironmentService } from '../../../services/environment.service';
import { ExporterPluginService } from '../../../services/exporter-plugin.service';
import { IconRegistryService } from '../../../services/icon-registry.service';
import { UrlService } from '../../../services/url.service';
import { NavigationService, Page } from '../../../services/navigation.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

const DOWNLOAD_ICON_NAME = 'download';
const PORTER_ICON_NAME = 'porter-logo';
const PORTER_WORDMARK_ICON_NAME = 'porter-wortmarke';
const FINGERHAUS_LOGO_ICON_NAME = 'fingerhaus-logo';

const DOWNLOAD_ICON_URL = 'assets/icons/download-icon.svg';
const PORTER_ICON_URL = 'assets/icons/porter-logo.svg';
const PORTER_WORDMARK_URL = 'assets/icons/porter-wortmarke.svg';
const FINGERHAUS_LOGO_URL = 'assets/icons/fingerhaus-logo.svg';

const FLOOR_PLAN_EDITOR_PATH = 'grundriss-editor';
const CONFIGURATION_PATH = 'bemusterung';

const FLOOR_PLAN_URL = '/grundrisse';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  projectName: string;
  @Input()
  newProjects: Project[];

  hideProfileMenu = true;
  isLinkActive = { productManagement: false, productCatalog: false, buildingProjects: false };
  routerFloorPlanLink = FLOOR_PLAN_URL;
  usesExportPlugin = this.exportPluginService.usesExport();

  subscriptionsUntilDestroy: Subscription[];

  constructor(
    protected environment: EnvironmentService,
    protected auth: AuthService,
    private iconRegistry: IconRegistryService,
    private exportPluginService: ExporterPluginService,
    private urlService: UrlService,
    private navigationService: NavigationService,
    private router: Router
  ) {
    super(environment, auth);
    this.subscriptionsUntilDestroy = [];
    this.registerIcons();
  }

  ngOnInit() {
    this.hideProfileMenu = this.isEmbeddedAsIframe();
    this.subscribeToNavigationEnd();
    this.updateActiveLinks();
  }

  ngOnDestroy() {
    this.subscriptionsUntilDestroy.forEach((subscription) => subscription.unsubscribe());
  }

  get authenticated(): boolean {
    return this.auth.isAuthenticated();
  }

  logout() {
    this.auth.logout();
  }

  shouldShowHelpAndSupportButton(): Boolean {
    return (
      this.userGroups.licences.isAi ||
      this.userGroups.licences.isPorter ||
      this.userGroups.licences.isManufacturer ||
      this.user.admin
    );
  }

  isProductManagementAccessible(): boolean {
    return (
      this.userGroups.fingerhaus.isAdmin ||
      this.userGroups.fingerhaus.isProductManager ||
      this.userGroups.fingerhaus.isConsultant
    );
  }

  isProductCatalogAccessible(): boolean {
    return !this.userGroups.fingerhaus.isProductManager && !this.userGroups.fingerhaus.isConsultant;
  }

  isInFloorplanEditor(): boolean {
    return this.urlService.urlContains(FLOOR_PLAN_EDITOR_PATH);
  }

  isInConfiguration(): boolean {
    return this.urlService.urlContains(CONFIGURATION_PATH);
  }

  navigateToProductManagement() {
    this.navigationService.navigateTo(Page.MANAGEMENT);
  }

  navigateToProductCatalog() {
    this.navigationService.navigateTo(Page.CATALOG);
  }

  navigateToBuildingProjects() {
    this.navigationService.navigateTo(Page.BUILDING_PROJECTS);
  }

  private registerIcons() {
    this.iconRegistry.register(DOWNLOAD_ICON_NAME, DOWNLOAD_ICON_URL);
    this.iconRegistry.register(PORTER_ICON_NAME, PORTER_ICON_URL);
    this.iconRegistry.register(PORTER_WORDMARK_ICON_NAME, PORTER_WORDMARK_URL);
    this.iconRegistry.register(FINGERHAUS_LOGO_ICON_NAME, FINGERHAUS_LOGO_URL);
  }

  isFingerhaus(): boolean {
    return this.environment.isFingerhaus();
  }

  private subscribeToNavigationEnd() {
    const subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async () => {
        this.updateActiveLinks();
      });

    this.subscriptionsUntilDestroy.push(subscription);
  }

  private updateActiveLinks() {
    this.isLinkActive = {
      productManagement: this.navigationService.isAssetManagement(),
      productCatalog: this.navigationService.isAssetCatalog(),
      buildingProjects: this.navigationService.isBuildingProjects(),
    };
  }
}
