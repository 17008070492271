import { FingerhausProject } from '../../src/app/services/building-project.service';

export const DEFAULT_CUSTOMER_INFO = {
    title: 'Herr',
    firstName: 'Max',
    lastName: 'Mustermann',
    street: 'Musterstraße 1',
    isocode: 'DE',
    zipcode: 12345,
    city: 'Musterstadt',
    phone: '0123456789',
    email: 'max@mustermann.de'
};

export const DEFAULT_BUILDING_PROJECT: FingerhausProject = {
    bvhNumber: "123456",
    clientAppointmentDate: "2024-04-02T02:00:00.000Z",
    clientUserId: "54a67ecd-b379-4aaa-805f-bff61f4d21e9",
    consultantEmail: null,
    consultantFirstName: 'Max',
    consultantLastName: 'Mustermann',
    consultantUserId: null,
    contractSignDate: "2024-03-05+01:00",
    lockEmailSent: 1,
    status: "FINISHED",
    bvhData: {
        location: '',
        customers: [DEFAULT_CUSTOMER_INFO]
    }
};