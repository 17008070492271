import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Stream } from '../business-domain/Stream';

// import 'rxjs/add/operator/retryWhen';
import { map, retryWhen, delay, tap, take, first } from 'rxjs/operators';
import { TokenService } from './token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';

export class StreamingObject {
  sessionId: string;
  publicIP: string;
  status: string;
}

@Injectable()
export class StreamingService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  public usePixelStream = false;

  public startStreaming(): Observable<Stream> {
    return this.http
      .post<StreamingObject>(`${environment.apiUrl}/streaming`, {})
      .pipe(map((stream) => new Stream(stream.sessionId, stream.publicIP)));
  }

  public leaveStreamingSession(sessionId): Observable<Stream> {
    return this.http.post<Stream>(`${environment.apiUrl}/streaming/${sessionId}/leave`, {});
  }

  public testStreamServer(maxRetryAttempts: number): Observable<Stream> {
    let i = 0;

    // At the moment we want only one of the streams
    return this.http.get<Stream[]>(`${environment.apiUrl}/streaming`).pipe(
      map((stream) => {
        i++;
        if (stream[0] && stream[0].status !== 'running') {
          if (i === maxRetryAttempts) return stream[0];
          throw stream[0]; // Catched by the retrywhen
        } else {
          return stream[0];
        }
      }),
      retryWhen((errors) => errors.pipe(delay(5000), take(maxRetryAttempts)))
    );
  }

  public async awaitTestingStreamServer(delayTime: number): Promise<boolean> {
    if (!delayTime) delayTime = 0;
    return await this.testStreamServer(1)
      .pipe(
        map((oldstream) => !!oldstream),
        delay(delayTime),
        first()
      )
      .toPromise();
  }

  public sendMessageToIFrame(contentWindow: Window, action: String) {
    const message = this.createMessageByAction(action);
    contentWindow.postMessage(message, '*');
  }

  public createMessageByAction(action: String): String {
    const message = {
      action: action,
      token: this.tokenService.getToken().value,
    };

    /*
    switch (action) {
      case 'ORBIT_CONTROL': {
        message.action = action;
        break;
      }
      case 'FIRST_PERSON': {
        message.action = action;
        break;
      }
      case 'LAUNCH': {
          message.action = action;
          break;
      } default: {
        throw new Error('Action is not defined');
      }

    }*/

    return JSON.stringify(message);
  }

  //TODO: Move this to its own service if we want to support user access checks one more routes
  hasStreamingAccess(): boolean {
    return (this.authService.hasUserGroup([environment.streamGroup]) || this.authService.getCurrentUser().admin);
  }

  setShowStartStreamDialog(show: boolean): void {
    window.localStorage.setItem('showStartStream', show ? '1' : '0');
  }

  getShowStreamingDialog(start: boolean): boolean {
    const showDialog = start ? 'showStartStream' : 'showEndStream';
    const checkValueExists = function (value: string): boolean {
      return window.localStorage.getItem(value) !== null;
    };

    return (
      !checkValueExists(showDialog) || !!+parseInt(window.localStorage.getItem(showDialog), 10)
    );
  }
}
