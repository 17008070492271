import { Injectable } from '@angular/core';
import {
  TokenService,
  ROUTE_PARAMETER_TOKEN_KEY,
  ROUTE_PARAMETER_TOKEN_EXPIRY_KEY,
} from './token.service';
import {
  ConfigurationService,
  ROUTE_PARAMETER_CONFIGURATION_ID_KEY,
} from './configuration.service';
import { UrlService } from './url.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { FavoriteArticlesService } from './favorite-articles.service';

const ROUTE_PARAMETER_KEYS = {
  download: '/download',
  downloadPorter: 'download.porter.de',
  tokenValue: ROUTE_PARAMETER_TOKEN_KEY,
  tokenExpiry: ROUTE_PARAMETER_TOKEN_EXPIRY_KEY,
  configurationId: ROUTE_PARAMETER_CONFIGURATION_ID_KEY,
};

@Injectable({
  providedIn: 'root',
})
export class AppInitializeService {
  constructor(
    private tokenService: TokenService,
    private configurationService: ConfigurationService,
    private urlService: UrlService,
    private router: Router,
    private authService: AuthService,
    private favoriteArticlesService: FavoriteArticlesService
  ) {}

  private async initialize() {
    await this.initializeServices();
    await this.redirectNavigation();
  }

  private async initializeServices() {
    this.tokenService.initialize();
    
    if(this.authService.isAuthenticated()) await this.authService.initialize();
    if (this.configurationService.urlContainsIdAsRouteParameter()) await this.configurationService.updateConfigurationByIdFromRouteParam();
    
    await this.favoriteArticlesService.initFavorites();
  }

  private async redirectNavigation() {
    this.urlContainsDownload()
      ? await this.navigateToDownload()
      : await this.navigateWithoutRouteParameters([
          ROUTE_PARAMETER_KEYS.configurationId,
          ROUTE_PARAMETER_KEYS.tokenValue,
          ROUTE_PARAMETER_KEYS.tokenExpiry,
        ]);
  }

  private urlContainsDownload(): boolean {
    const containsDownload = this.urlService.urlContains(ROUTE_PARAMETER_KEYS.download);
    const containsDownloadPorter = this.urlService.urlContains(ROUTE_PARAMETER_KEYS.downloadPorter);

    return containsDownload || containsDownloadPorter;
  }

  private async navigateToDownload() {
    await this.router.navigate([ROUTE_PARAMETER_KEYS.download]);
  }

  private async navigateWithoutRouteParameters(keys: string[]) {
    const baseUrl = this.urlService.getBaseUrl();
    const urlWithoutParameters = this.urlService.removeRouteParameters(keys);
    let path = urlWithoutParameters.replace(baseUrl, '');
    const dashIsLastCharacter = path.slice(-1) === '/';

    if (dashIsLastCharacter) {
      path = path.slice(0, -1); // Remove the Dash
    }
    if (window.location.pathname !== path) {
      /*
      Using await results in configuration-resolver being activated too early and
      ultimately in asset-catalog-user-group-guard not being called when opening catalog.

      POR-10876:
      We need to prevent such unexpected behavior. Maybe another resolver should be
      used for handling initial route parameters for confogiurationId and token, etc..
      */
      this.router.navigateByUrl(path);
    }
  }

  static initializeApp(appInitService: AppInitializeService) {
    return (): Promise<any> => appInitService.initialize();
  }
}
