import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TwoActionsDialogService } from '../../../services/two-actions-dialog.service';

@Component({
  selector: 'two-actions-dialog',
  templateUrl: './two-actions-dialog.component.html',
  styleUrls: ['./two-actions-dialog.component.scss'],
})
export class TwoActionsDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<TwoActionsDialogComponent>,
    private twoActionsDialogService: TwoActionsDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  public close(value) {
    this.cancel();
    this.dialogRef.close(value);
  }

  @HostListener('keydown.esc')
  onEsc() {
    this.close(false);
  }

  confirm(): void {
    this.twoActionsDialogService.setConfirmed(true);
  }

  cancel(): void {
    this.twoActionsDialogService.setConfirmed(false);
  }
}
