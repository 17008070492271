import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Output,
  SecurityContext,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'infobox-dialog',
  templateUrl: './infobox-dialog.component.html',
  styleUrls: ['./infobox-dialog.component.scss'],
})
export class InfoboxDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<InfoboxDialogComponent>
  ) {}

  @HostListener('keydown.esc')
  public onEsc() {
    this.close();
  }

  close() {
    this.dialogRef.close(false);
  }

  getSanitizedContent() {
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.data.content);
  }
}
