import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/dialogs/errordialog/errordialog.component';

@Injectable()
export class ErrorDialogService {
  isDialogOpen: boolean;
  isErrorDialogOpenSubject: BehaviorSubject<boolean>;
  constructor(private dialog: MatDialog) {
    this.initialize();
  }
  openDialog(data) {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true;
      this.isErrorDialogOpenSubject.next(true);
      const dialogRef = this.dialog.open(ErrorDialogComponent, {
        width: 'auto',
        data: data,
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
        this.isErrorDialogOpenSubject.next(false);
      });
    }
  }

  private initialize() {
    this.isDialogOpen = false;
    this.isErrorDialogOpenSubject = new BehaviorSubject<boolean>(false);
  }
}
