import { Component } from '@angular/core';
import { EnvironmentService } from '../../../../services/environment.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from "../../../../business-domain/User";
import { environment } from "../../../../../environments/environment";
import { FEATURE_FLAGS } from '../../../../shared/constants/feature-flags.constants';

export interface Fingerhaus {
  isAdmin: boolean;
  isConsultant: boolean;
  isProductManager: boolean;
  isBuilder: boolean;
  isReadOnly: boolean;
}

export interface Licences {
  isAi: boolean;
  isPorter: boolean;
  isStreaming: boolean;
  isManufacturer: boolean;
}

export interface UserGroups {
  licences: Licences;
  fingerhaus: Fingerhaus;
  isAiAlpha: boolean;
}

export class BaseComponent {
  readonly user: User;
  userGroups: UserGroups;
  FEATURE_FLAGS = FEATURE_FLAGS;
  
  constructor(
    protected environment: EnvironmentService,
    protected auth: AuthService
  ) {
    this.user = this.auth.getCurrentUser();
    this.userGroups = this.createUserGroups();
  }

  isFingerhaus(): boolean {
    return this.environment.isFingerhaus();
  }
  
  isPorter(): boolean {
    return this.environment.isPorter();
  }

  isEmbeddedAsIframe(): boolean {
    return window.self !== window.top;
  }

  private createUserGroups(): UserGroups {
    return this.userGroups = {
      isAiAlpha: this.auth.hasUserGroup(environment.AI_ALPHA_GROUP), 
      fingerhaus: {
        isAdmin: this.auth.hasUserGroup(environment.FINGERHAUS_ADMIN_GROUP),
        isBuilder: this.auth.hasUserGroup(environment.FINGERHAUS_BUILDER),
        isConsultant: this.auth.hasUserGroup(environment.FINGERHAUS_CONSULTANT_GROUP),
        isProductManager: this.auth.hasUserGroup(environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP),
        isReadOnly: this.auth.hasUserGroup(environment.READ_ONLY_GROUP)
      }, 
      licences: {
        isAi: this.auth.hasUserGroup(environment.AI_LICENCE_GROUPS),
        isManufacturer: this.auth.hasUserGroup(environment.PORTER_MANUFACTURER_GROUPS),
        isPorter: this.auth.hasUserGroup(environment.PORTER_LICENCE_GROUPS),
        isStreaming: this.auth.hasUserGroup(environment.STREAMING_LICENCE_GROUP),
      },
    }
  }
}
