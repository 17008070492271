import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private tokenService: TokenService,
    private matDialog: MatDialog
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();

    if (token.value && !req.headers.get('Authorization')) {
      const headers = req.headers.set('Authorization', `Bearer ${token.value}`);
      req = req.clone({ headers: headers });
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.authService.logout();

          if (window.location.pathname == '/login' || window.location.pathname == '/grundrisse') {
          } else {
            this.matDialog.closeAll();
            this.router
              .navigate(['/login'], {
                queryParams: {
                  returnUrl: window.location.pathname + window.location.search,
                  sessionLost: true,
                },
              })
              .catch((navErr) => console.error(navErr));
          }
        }

        return throwError(err);
      })
    );
  }
}
