import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '../business-domain/Project';
import { environment } from './../../environments/environment';

interface Response<T> {
  data: T;
}

@Injectable()
export class ProjectService {
  constructor(private http: HttpClient) {}

  public getProject(projectId: string): Observable<Project> {
    const endpoint = environment.projectEndpoint.replace(':projectId', String(projectId));
    return this.http.get<Project>(environment.apiUrl + endpoint);
  }

  public getProjects(): Observable<Project[]> {
    return this.http
      .get<Response<Project[]>>(environment.apiUrl + environment.projectsEndpoint, {
        params: { includeImage: 'true' },
      })
      .pipe(map((response) => response.data)) as Observable<Project[]>;
  }

  public getProjectForLevelId(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/project');
  }

  public insertProject(project: Project): Observable<any> {
    const endpoint = environment.projectsEndpoint;
    return this.http.post(environment.apiUrl + endpoint, { ...project });
  }

  public updateProject(projectId: string, project: Project): Observable<any> {
    const endpoint = environment.projectEndpoint.replace(':projectId', String(projectId));
    return this.http.put(environment.apiUrl + endpoint, { ...project });
  }

  public deleteProject(projectId: string) {
    const endpoint = environment.projectEndpoint.replace(':projectId', String(projectId));
    return this.http.delete(environment.apiUrl + endpoint);
  }
}
