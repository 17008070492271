import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { STRING } from '../../../e2e/constants';
import { environment } from '../../environments/environment';
import {
  FloorplanUploadFile,
  UploaderGrundrisseComponent,
} from '../modules/porter-floor-plans/components/uploader-grundrisse/uploader-grundrisse.component';
import { ErrorDialogService } from './errordialog.service';
import { FloorplannerService } from './floorplanner.service';

type ConversionStatus = 'COMPLETED' | 'FAILED' | 'REJECTED' | 'FORBIDDEN' | 'IN_PROGRESS';

export interface ConversionDataResponse {
  conversions: ConversionResponse[];
  totalCount: number;
}

export interface ConversionData {
  list: Conversion[];
  totalCount: number;
}

export interface Conversion extends ConversionResponse {
  conversionDate: string;
  conversionTime: string;
  iconTooltip: string;
  conversionLink: string;
}

interface ConversionResponse {
  endTime?: string;
  startTime?: string;
  error: any;
  conversionId: string;
  evaluation_score: string; // TODO: Why is this a string and not a number from be Backend. It is used a number in the frontend.
  floorplannerProjectId: number;
  hasProject: boolean;
  name: string;
  status: ConversionStatus;
  unitId: string;
  downloadUrlIfc: string;
  downloadUrlCollada: string;
  downloadUrGltf: string;
  downloadUrlBlender: string;
  thumbnail?: string;
  numberOfFiles?: number;
}

const API_URL = environment.apiUrl;
const VIEWER_LOCATION = window.location.origin + '/grundrisse/grundriss-editor?conversionId=';
const MAX_TIME_TO_WAIT = 60 * 60 * 1000; // 60 minutes
const RETRY_TIME = 6 * 1000; // 6 seconds

const CONVERSION_FORBIDDEN_ERROR = {
  errorCode: '0xFFFFFF',
  location: 'floorplan-ai-service - getStatusOfConversion',
  devinfo: '403 unauthorized',
  publicinfo:
    'Sie haben keine Berechtigung zur Anzeige dieses Grundrisses. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

const CONVERSION_FAILED_ERROR = {
  errorCode: '0xFFFFFF',
  location: 'floorplan-ai-service - getStatusOfConversion',
  devinfo: 'result.status === FAILED',
  publicinfo:
    'Der gewünschte Grundriss konnte nicht verarbeitet werden. Bitte laden Sie die Seite neu und wählen Sie eine andere Datei.<br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

const CONVERSION_REJECTED_ERROR = {
  errorCode: '0xFFFFFF',
  location: 'floorplan-ai-service - getStatusOfConversion',
  devinfo: 'result.status === REJECTED',
  publicinfo:
    'Der angeforderte Grundriss ist ungültig. Wählen Sie einen bereits umgewandelten Grundriss aus der Historie oder laden Sie einen neuen Grundriss hoch.',
  debug: false,
  autoFocus: false,
};

const CONVERSION_TIMED_OUT_ERROR = {
  errorCode: '0xFFFFFF',
  location: 'floorplan-ai-service - getStatusOfConversion',
  devinfo: 'Max upload tries reached.',
  publicinfo:
    'Der Upload ist fehlgeschlagen. Bitte laden Sie die Seite neu und versuchen es erneut. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
  debug: false,
  autoFocus: false,
};

const USER_CONVERSION_ENDPOINT = '/floorplans-ai/userConversions';
const NO_PAGINATION_ENDPOINT = USER_CONVERSION_ENDPOINT + '?usePagination=false';
const PAGINATION_ENDPOINT = USER_CONVERSION_ENDPOINT + '?usePagination=true&perPage=';
const PAGE_PARAM = '&page=';
const INCLUDE_THUMBNAILS_PARAM = '&includeThumbnails=';

@Injectable()
export class FloorplanAIService {
  private readonly uploadSubscriptions: any;
  private conversionId: string;
  private modal: MatDialogRef<UploaderGrundrisseComponent>;

  constructor(
    private http: HttpClient,
    private errorDialogService: ErrorDialogService,
    private floorplannerService: FloorplannerService
  ) {
    this.uploadSubscriptions = {};
  }

  cancelUploads() {
    for (const filename in this.uploadSubscriptions) {
      this.unsubscribe(filename);
    }
  }

  async initializeFloorPlanner(
    conversionId: string,
    modal?: MatDialogRef<UploaderGrundrisseComponent>
  ): Promise<Conversion> {
    this.modal = modal;

    const status = await this.waitForFinalConversionStatus(conversionId);

    if (status === 'COMPLETED') {
      const conversion = await this.getConversion(conversionId).toPromise();
      this.floorplannerService.initializeFloorplanner(conversion.floorplannerProjectId);
      return conversion;
    }

    this.showConversionErrors(status);
  }

  private async waitForFinalConversionStatus(conversionId: string): Promise<ConversionStatus> {
    let status: ConversionStatus = 'IN_PROGRESS';
    let retryCount = 0;

    while (status === 'IN_PROGRESS' && !this.hasReachedRetryLimit(retryCount)) {
      status = await this.getCurrentConversionStatus(conversionId);

      if (status !== 'IN_PROGRESS') {
        return status;
      }

      retryCount++;
      await this.waitUntilNextRetry();
    }
  }

  private async getCurrentConversionStatus(conversionId: string): Promise<ConversionStatus> {
    return (await this.getConversion(conversionId).toPromise()).status;
  }

  private hasReachedRetryLimit(retryCount: number) {
    return retryCount * RETRY_TIME >= MAX_TIME_TO_WAIT;
  }

  private waitUntilNextRetry() {
    return new Promise((resolve) => setTimeout(resolve, RETRY_TIME));
  }

  private showConversionErrors(status: ConversionStatus) {
    switch (status) {
      case 'IN_PROGRESS':
        this.showError(CONVERSION_TIMED_OUT_ERROR);
        throw new Error('Conversion timed out.');
      case 'FORBIDDEN':
        this.showError(CONVERSION_FORBIDDEN_ERROR);
        throw new Error('Conversion is forbidden.');
      case 'FAILED':
        this.showError(CONVERSION_FAILED_ERROR);
        throw new Error('Conversion failed.');
      case 'REJECTED':
        this.showError(CONVERSION_REJECTED_ERROR);
        throw new Error('Conversion rejected.');
    }
  }

  uploadFloorplans(
    files: FloorplanUploadFile[],
    conversionName: string,
    modal?: MatDialogRef<UploaderGrundrisseComponent>
  ): Observable<number> {
    return Observable.create(async (observer) => {
      let totalProgress = 0;
      let numComplete = 0;
      let currentProgress = 0;

      let floorPlans = Array(files.length);

      // get base64 strings of files
      for (let i = 0; i < files.length; i++) {
        // floorplans and files are in reversed order, so we need to keep track of the inverse of i
        let inv_i = files.length - 1 - i;

        const val = await this.toBase64(files[i]).toPromise();
        floorPlans[inv_i] = {
          fileName: files[i].name,
          file: val,
          scalingFactor: files[i].scalingFactor,
          heightWall: files[i].heightWall,
        };
        if (i == files.length - 1) {
          // last file
          this.startConversion(floorPlans, conversionName, modal).subscribe(
            (progress) => {
              const progressDiff = progress - currentProgress;
              totalProgress += Math.round(progressDiff / files.length);
              currentProgress = progress;
              observer.next(totalProgress);
            },
            null,
            () => {
              numComplete++;
              if (numComplete === files.length) observer.complete();
            }
          );
        }
      }
    });
  }

  /**
   * convert PDF or DXF files to images
   */
  convertFile(file: File, caller) {
    // convert file to base64 string
    this.toBase64(file).subscribe((base64String) => {
      // combine apiUrl and conversion route
      const url = API_URL + '/floorplans-ai/convertFile';

      // create request with base64 data
      const request = new HttpRequest('POST', url, { data: base64String });

      // subscribe to upload observer
      const uploadSub = this.http.request(request).subscribe((event: HttpEvent<any>) => {
        // switch for response events
        switch (event.type) {
          // log response headers that are not 200
          case HttpEventType.ResponseHeader:
            if (!event.ok) console.log(event);
            break;

          // if we get a response - base64 data in the event.body
          case HttpEventType.Response:
            // redirect the response back to the caller
            caller.insertB64(event.body, file);
            break;

          // default case - do nothing
          default:
            break;
        }
      });
    });
  }

  transferAiConversionToProject(conversionId: string, unitId: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/conversions/' + conversionId + '/conversionExports',
      { unitId: unitId }
    );
  }

  getConversion(conversionId: string): Observable<Conversion> {
    return this.http.get<Conversion>(
      environment.apiUrl + `/floorplans-ai/conversions/` + conversionId,
      {}
    );
  }

  private unsubscribe(filename) {
    const subscription = this.uploadSubscriptions[filename];
    if (!subscription) return;

    subscription.unsubscribe();
    delete this.uploadSubscriptions[filename];
  }

  /**
   * convert File to base64 string
   */
  private toBase64(file: File): Observable<any> {
    // create observable reader
    return Observable.create((observer) => {
      // create new FileReader
      const reader = new FileReader();

      // onLoad for reader
      reader.addEventListener('load', () => {
        // get base64 conversion from reader
        const fullBase64String = reader.result as string;

        // split the descriptive non-data part
        const base64String = fullBase64String.split(',')[1];

        // emit the result and complete the observer
        observer.next(base64String);
        observer.complete();
      });

      // read the provided file - starts the process
      reader.readAsDataURL(file);
    });
  }

  private showError(error: any) {
    this.allowClosingModal();
    this.errorDialogService.openDialog(error);
  }

  private allowClosingModal() {
    // TODO: Is this necessary? All our modals are closeable by default, right?
    if (this.modal) this.modal.disableClose = false;
  }

  private startConversion(
    floorplans: any[],
    conversionName: string,
    modal?: MatDialogRef<UploaderGrundrisseComponent>
  ): Observable<number> {
    return Observable.create((observer) => {
      const request = new HttpRequest(
        'POST',
        API_URL + '/floorplans-ai',
        { name: conversionName, files: floorplans },
        { reportProgress: true }
      );

      this.http.request(request).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const percentDone = Math.round((100 * event.loaded) / event.total);
              observer.next(percentDone);
              break;
            case HttpEventType.ResponseHeader:
              if (!event.ok) observer.error(event);
              break;
            case HttpEventType.Response:
              console.log(event);
              this.conversionId = event.body.conversionId;
              conversionName = event.body.name;
              break;
            default:
              break;
          }
        },
        (error) => {
          console.error(error);
          observer.error(error);
        },
        async () => {
          observer.complete();
          if (this.conversionId) {
            const nextUrl = VIEWER_LOCATION + this.conversionId + '&environment=' + API_URL;
            await this.initializeFloorPlanner(this.conversionId, modal);
            window.location.href = nextUrl;
          }
        }
      );
    });
  }

  getAllUserConversionDataWithoutThumbnail(): Observable<ConversionData> {
    return this.http.get(environment.apiUrl + NO_PAGINATION_ENDPOINT).pipe(
      map((response: ConversionDataResponse) => {
        return {
          list: response.conversions.map(this.mapConversion),
          totalCount: response.totalCount,
        };
      })
    );
  }

  async getConversionDataByPageWithoutThumbnail(
    perPage: number,
    pageNumber: number
  ): Promise<ConversionData> {
    const response = await this.http
      .get<ConversionDataResponse>(
        environment.apiUrl +
        PAGINATION_ENDPOINT +
        perPage +
        PAGE_PARAM +
        pageNumber +
        INCLUDE_THUMBNAILS_PARAM +
        STRING.false
      )
      .toPromise();
    return {
      list: response.conversions.map(this.mapConversion),
      totalCount: response.totalCount,
    };
  }

  async getConversionDataByPageWithThumbnail(
    perPage: number,
    pageNumber: number
  ): Promise<ConversionData> {
    const response = await this.http
      .get<ConversionDataResponse>(
        environment.apiUrl +
        PAGINATION_ENDPOINT +
        perPage +
        PAGE_PARAM +
        pageNumber +
        INCLUDE_THUMBNAILS_PARAM +
        STRING.true
      )
      .toPromise();
    return {
      list: response.conversions.map(this.mapConversion),
      totalCount: response.totalCount,
    };
  }

  private mapConversion(conversion: ConversionResponse): Conversion {
    return {
        ...conversion,
        conversionDate: '',
        conversionTime: '',
        iconTooltip: '',
        conversionLink: ''
    };
  }
}
