<app-top-menu class="header-menu"></app-top-menu>
<div
  class="sidenav-toggle-button"
  (click)="toggleSidenav()"
  [ngClass]="{ 'toggle-closed': !isShowing }"
>
  <mat-icon>keyboard_arrow_left</mat-icon>
</div>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    class="mat-sidenav"
    [mode]="checkForMobile() ? 'side' : 'push'"
    [opened]="isShowing"
    [fixedInViewport]="true"
    [fixedTopGap]="55"
  >
    <app-sidebar>Loading</app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="content-body">
    <h1 class="site-title">PORTER Hersteller | Startseite</h1>
    <div class="preview-text">
      <alert [type]="getAlert().type" [text]="getAlert().text"></alert>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
