import { Conversion } from '../temporary-interfaces';

export const DEFAULT_CONVERSION: Conversion = {
  name: '',
  numberOfFiles: 0,
  endTime: '',
  status: '',
  thumbnail: 'https://fml-thumbs-prod.s3.eu-west-1.amazonaws.com/257210586.png',
  userId: '1',
  id: 'cb015d08-753a-43bd-8096-eea13ae8bc97',
  conversionId: '343b4c212eec11ef8aff8b56e48995cf',
  startTime: '2024-06-20T10:02:30.000Z',
  type: 'AI',
};
