import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UrlService } from './url.service';
import { Token } from './token.interface';
import { LoginResponse } from './auth.service';

// TODO QA - Could the parameter value and the storage value not be the same?
export const ROUTE_PARAMETER_TOKEN_KEY = 'token';
export const ROUTE_PARAMETER_TOKEN_EXPIRY_KEY = 'token-expiry';

const STORAGE_VALUE_KEY = 'tokenValue';
const STORAGE_EXPIRY_KEY = 'tokenExpiry';

const DELAY_FACTOR = 0.8;
const INVALID_TOKEN = 'Invalid Token';

@Injectable()
export class TokenService {
  constructor(private http: HttpClient, private urlService: UrlService) {}
  initialize() {
    // We need to prioritize the token from the URL over the token from the local storage
    // if the expiry of the url token is later than the expiry of the local storage token.
    // In this case the token in the local storage might be from a previous session
    // with a different user.
    if (
      this.urlContainsValidToken() &&
      (!this.hasValidToken() || this.getToken().expiry < this.getTokenByUrl().expiry)
    ) {
      this.setTokenByUrl();
    } else if (this.hasValidToken()) {
      this.startRefreshTimer();
    }
  }

  setToken(token: Token) {
    this.isValidToken(token) ? this.setTokenToLocalStorage(token) : this.throwInvalidTokenError();
  }

  getToken(): Token {
    return {
      value: this.getValueFromLocalStorage(),
      expiry: this.getExpiryFromLocalStorage(),
    };
  }

  getSyncToken(): Observable<Token> {
    return this.http.post(environment.apiUrl + '/sync/auth', {}).pipe(
      map((response: any) => {
        return { value: response.token, expiry: response.tokenExpiry };
      })
    );
  }

  deleteToken() {
    window.localStorage.removeItem(STORAGE_VALUE_KEY);
    window.localStorage.removeItem(STORAGE_EXPIRY_KEY);
  }

  hasValidToken(): boolean {
    return this.isValidToken(this.getToken());
  }

  private isValidToken(token: Token): boolean {
    return token && token.value && token.expiry && this.hasRemainingTime(token);
  }

  private hasRemainingTime(token: Token): boolean {
    return this.calculateRemainingTime(token) >= 0;
  }

  private urlContainsValidToken(): boolean {
    return (
      this.urlContainsValue() && this.urlContainsExpiry() && this.isValidToken(this.getTokenByUrl())
    );
  }

  private urlContainsValue(): boolean {
    return this.urlService.urlContains(ROUTE_PARAMETER_TOKEN_KEY);
  }

  private urlContainsExpiry(): boolean {
    return this.urlService.urlContains(ROUTE_PARAMETER_TOKEN_EXPIRY_KEY);
  }

  private setTokenByUrl() {
    this.setToken(this.getTokenByUrl());
  }

  private getTokenByUrl(): Token {
    return {
      value: this.urlService.getRouteParameterValue(ROUTE_PARAMETER_TOKEN_KEY),
      expiry: this.urlService.getRouteParameterValue(ROUTE_PARAMETER_TOKEN_EXPIRY_KEY),
    };
  }

  private setTokenToLocalStorage(token: Token) {
    this.setValueToLocalStorage(token.value);
    this.setExpiryToLocalStorage(token.expiry);

    this.startRefreshTimer();
  }

  private setValueToLocalStorage(value: string) {
    window.localStorage.setItem(STORAGE_VALUE_KEY, value);
  }

  private setExpiryToLocalStorage(expiry: string) {
    window.localStorage.setItem(STORAGE_EXPIRY_KEY, expiry);
  }

  private getValueFromLocalStorage(): string {
    return window.localStorage.getItem(STORAGE_VALUE_KEY);
  }

  private getExpiryFromLocalStorage(): string {
    return window.localStorage.getItem(STORAGE_EXPIRY_KEY);
  }

  private startRefreshTimer() {
    setTimeout(() => {
      this.setTokenByDatabase();
    }, this.calculateRefreshTimer());
  }

  private calculateRefreshTimer(): number {
    return this.calculateRemainingTime(this.getToken()) * DELAY_FACTOR;
  }

  private calculateRemainingTime(token: Token): number {
    return Number(token.expiry) - new Date().getTime();
  }

  private setTokenByDatabase() {
    const sub: Subscription = this.http
      .post<LoginResponse>(environment.apiUrl + environment.sessionRefreshEndpoint, {})
      .subscribe(
        (result) => {
          const token: Token = {
            value: result.token,
            expiry: String(result.tokenExpiry),
          };

          this.setToken(token);
        },
        (error) => {
          this.deleteToken();
          console.error(error);
        },
        () => sub.unsubscribe()
      );
  }

  private throwInvalidTokenError() {
    throw new Error(INVALID_TOKEN);
  }
}
