import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AssetService} from '../../../services/asset.service';
import {AuthService} from '../../../services/auth.service';
import {AssetSearchResult} from '../models/asset-search-result';

const SHORTCUT_LENGTH = 1;
const DASH_LENGTH = 1;
const READY_NUMBER_LENGTH = 5;
const READY_NUMBER_PARAMETER_NAME = 'readyNumber';
const PARTNER_TAG_SHORTCUT = '%';

const NO_RESULT_FOUND: AssetSearchResult = {
  info: {
    icon: 'error',
    iconColor: 'warn',
    title: 'Die eingegebene PORTER-Ready-Nummer konnte nicht gefunden werden',
    text: 'Bitte überprüfen Sie Ihre Eingabe oder wenden Sie sich an unseren Support',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ReadyNumberService {
  private partnerTag: string;
  private canSearch: boolean;

  constructor(private authService: AuthService, private assetService: AssetService) {
    this.initialize();
  }

  private initialize() {
    this.partnerTag = this.authService.getCurrentUser().partnerTag;
    this.canSearch = !!this.partnerTag;
  }

  isValid(term: string): boolean {
    if(!this.canSearch) return false;
    
    const startsWithShortcut = this.startsWithShortcut(term);
    const startsWithPartnerTag = this.startsWithPartnerTag(term);
    const hasValidSearchLength = this.hasValidSearchLength(term);
    const hasValidPorterNumber = this.containsValidPorterReadyNumber(term);

    return (startsWithPartnerTag || startsWithShortcut) && hasValidSearchLength && hasValidPorterNumber;
  }

  search(term: string): Observable<AssetSearchResult> {
    if(!this.isValid(term)) return of(NO_RESULT_FOUND);
    
    term = this.startsWithPartnerTag(term) ? term : this.replaceShortcutWithTag(term);
    return this.fetchAssets(term);
  }

  private startsWithPartnerTag(term: string): boolean {
    return term.startsWith(this.partnerTag);
  }

  private startsWithShortcut(term: string): boolean {
    return term.startsWith(PARTNER_TAG_SHORTCUT);
  }
  
  private hasValidSearchLength(term: string): boolean {
    const prefixLength = this.startsWithPartnerTag(term) 
        ? this.partnerTag.length + DASH_LENGTH 
        : SHORTCUT_LENGTH;

    return term.length === prefixLength + READY_NUMBER_LENGTH;
  }

  private containsValidPorterReadyNumber(term: string): boolean {
    const porterReadyNumber = this.getExpectedReadyNumber(term);
    return this.isNumericString(porterReadyNumber) && this.hasValidLength(porterReadyNumber);
  }

  private getExpectedReadyNumber(term: string): string {
    return this.startsWithPartnerTag(term) ? term.substring(this.partnerTag.length + 1) : term.substring(1);
  }
  
  private isNumericString(value: string): boolean {
    return !isNaN(Number(value));
  }

  private hasValidLength(porterReadyNumber: string): boolean {
    return porterReadyNumber.length === READY_NUMBER_LENGTH;
  }
  
  private replaceShortcutWithTag(term: string): string {
    return this.partnerTag + '-' + this.getExpectedReadyNumber(term);
  }

  private fetchAssets(term: string): Observable<AssetSearchResult> {
    const parameters = this.createSearchParameters(term);
    
    return of(parameters).pipe(
        switchMap((params: HttpParams) => this.fetchAssetsByParameters(params)),
        map((result: AssetSearchResult) => this.processSearchResult(result))
    );
  }

  private createSearchParameters(term: string): HttpParams {
    let filteredParams = new HttpParams();
    filteredParams = filteredParams.append(READY_NUMBER_PARAMETER_NAME, term);
    return filteredParams;
  }

  private fetchAssetsByParameters(searchParams: HttpParams): Observable<AssetSearchResult> {
    return this.assetService.fetchAssetsByParameters(searchParams);
  }
  
  private processSearchResult(result: AssetSearchResult): AssetSearchResult {
    if (this.isEmpty(result)) {
      return NO_RESULT_FOUND;
    }

    this.markAsFound(result);
    return result;
  }

  private isEmpty(search: AssetSearchResult): boolean {
    return !(search.data?.length > 0);
  }
  
  private markAsFound(search: AssetSearchResult) {
    search.foundByReadyNumber = true;
  }
}
