export class Stream {
  id: string;
  instanceId: string;
  createdAt: string;
  createdBy: string;
  publicIP: string;
  status: string;

  constructor(id: string, publicIP: string, status?: string, instanceId?: string, createdAt?: string, createdBy?: string) {
    this.id = id;
    this.instanceId = instanceId;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.publicIP = publicIP;
    this.status = status;
  }
}
