import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

enum CustomerSystem {
  FINGERHAUS = 'fingerhaus',
  PORTER = 'porter',
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  isFingerhaus(): boolean {
    return environment.customerSystem === CustomerSystem.FINGERHAUS;
  }

  isPorter(): boolean {
    return environment.customerSystem === CustomerSystem.PORTER;
  }
}
