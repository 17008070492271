<div class="error-modal modal-s real-modal">
  <div class="modal-header w-100 row">
    <h2 class="modal-title">
      <mat-icon color="accent">warning</mat-icon>Es ist ein Fehler aufgetreten
    </h2>
  </div>
  <div class="modal-main">
    <p class="modal-text" [innerHTML]="getSanitizedPublicInfo()"></p>
    <p class="modal-subtitle">
      <b>Fehlercode: {{ data.errorCode }}</b>
    </p>
    <p class="modal-text" *ngIf="data.debug">
      Debuginfo:
      <i>{{ data.location }}</i
      >:<br />
      {{ data.devinfo }}
    </p>
  </div>
  <div class="dialog-actions row">
    <button mat-stroked-button mat button color="primary" mat-dialog-close>Okay</button>
  </div>
</div>
