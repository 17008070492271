<div class="infobox-dialog modal-s real-modal">
  <div class="modal-header w-100 row">
    <mat-icon *ngIf="data.icon" [color]="data.iconColor">{{ data.icon }}</mat-icon>
    <h2 class="modal-title">
      {{ data.title }}
    </h2>
    <div class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="modal-main">
    <p class="text">{{ data.text }}</p>
    <div *ngIf="data.content" class="html-content" [innerHTML]="getSanitizedContent()"></div>
  </div>
  <div *ngIf="data.buttonText && data.buttonText.length" class="dialog-actions row">
    <button mat-button mat-stroked-button color="primary" mat-dialog-close>
      {{ data.buttonText }}
    </button>
  </div>
</div>
