import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { IconRegistryService } from '../../services/icon-registry.service';
import { VersionService } from '../../services/version.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  constructor(
    private versionService: VersionService,
    private iconRegistry: IconRegistryService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.registerIcon();
  }

  async ngOnInit() {
    console.log('DownloadComponent ngOnInit()');
    await this.getLatestVersion();
  }

  getLatestVersion() {
    return this.versionService.getLatestVersion().then(
      (res) => {
        this.document.location.href = res.path;
      },
      (error) => {
        console.log('Failed to navigate to link, with error: ' + error);
      }
    );
  }

  private registerIcon() {
    this.iconRegistry.register('porter-logo', 'assets/icons/porter-logo.svg');
  }
}
