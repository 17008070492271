import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
  public calculateColsFromWidth(width: number): number {
    if (width < 800) {
      return 1;
    } else if (width < 1200) {
      return 2;
    } else if (width < 1700) {
      return 3;
    } else if (width < 2400) {
      return 4;
    } else if (width < 3200) {
      return 5;
    } else {
      return 6;
    }
  }
}
