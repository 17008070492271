import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { ErrorDialogService } from './errordialog.service';
import { LevelService } from './level.service';
import { SchemasService } from './schemas.service';
import { UnrealService } from './unreal.service';

declare const ue: {
  interface: {
    broadcast(name: string, data?: any): void;
  } & {
    [key: string]: (data?: any) => void;
  };
};

declare const ue4: typeof ue.interface.broadcast;
@Injectable({
  providedIn: 'root',
})
export class FavoriteArticlesService implements OnDestroy {
  schemaTree;
  unsortedFavorites: any[];
  sortedFavorites;
  favoritesInitialized = false;

  private unsortedFavoritesSubj: BehaviorSubject<any> = new BehaviorSubject([]);
  private sortedFavoritesSubj: BehaviorSubject<any> = new BehaviorSubject([]);

  private readonly subscriptionsUntilDestroy: Subscription[] = [];

  constructor(
    private levelService: LevelService,
    private configurationService: ConfigurationService,
    private schemasService: SchemasService,
    private errorDialogService: ErrorDialogService,
    private unrealService: UnrealService
  ) {}

  async initFavorites(): Promise<any> {
    if (this.favoritesInitialized) return;
    const configurationSubscription = this.configurationService.configuration$
      .pipe(
        switchMap((configuration) => {
          return this.configurationService.getFavorites(configuration.id);
        })
      )
      .subscribe(async (preselections) => {
        this.unsortedFavorites = preselections;
        this.unsortedFavoritesSubj.next(this.unsortedFavorites);

        this.schemaTree = await this.schemasService.getSchemaTree().toPromise();
        this.sortedFavorites = this.generateSortedFavorites(
          this.schemaTree,
          this.unsortedFavorites
        );
        this.sortedFavoritesSubj.next(this.sortedFavorites);
        this.favoritesInitialized = true;
      });

    this.subscriptionsUntilDestroy.push(configurationSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptionsUntilDestroy.length) {
      this.subscriptionsUntilDestroy.forEach((subscription) => subscription.unsubscribe());
    }
  }

  getUnsortedFavorites(): Observable<any> {
    return this.unsortedFavoritesSubj.asObservable();
  }

  async getProductNote(preselectionId: string): Promise<string> {
    const configuration = await this.configurationService.configuration$.pipe(first()).toPromise();
    const favorites = await this.configurationService
      .getFavorites(configuration.id)
      .pipe(first())
      .toPromise();
    const favorite = favorites.find((f) => f.id === preselectionId);
    return favorite?.note;
  }

  async setProductNote(preselectionId: string, note: string) {
    await this.configurationService.updatePreselectionNote(preselectionId, note);
    this.unsortedFavorites.find((f) => f.id === preselectionId).note = note;
  }

  getSortedFavorites(): Observable<any> {
    return this.sortedFavoritesSubj.asObservable();
  }

  /*
    TODO: Refactor!

    Purpose: Sorts all favorites into articles array of the first level schema they
    are part of or of which their schema is a child/grandchild
    (e.g. a favorite in schema "Parkett" will be sorted into the "Bodenbeläge" schema)
  */
  generateSortedFavorites(schemaTree: any, unsortedFavorites: any): any {
    console.log(
      'generateSortedFavorites(), schemaTree, :',
      schemaTree,
      ' - unsortedFavorites: ',
      unsortedFavorites
    );
    let schemaTreeData = schemaTree.data;
    //Prepare sortedFavorites based on schemaTree in order to use it as structure that can be read by template directives
    let sortedFavorites = { schemas: [], others: [], sum: 0 };

    for (let index = 0; index < schemaTreeData.length; index++) {
      let schema = {};

      //is nextLevel now the children property!?
      const { nextLevel, ...reducedSchemaTree } = schemaTreeData[index]; // define all properties of schemaTree[index] exept nextLevel as reducedSchemaTree
      schema = { ...reducedSchemaTree };
      schema['others'] = [];
      schema['articles'] = [];
      schema['sum'] = 0;

      sortedFavorites.schemas.push(schema);
    }

    //Fill empty sortedFavorites with favorites data
    unsortedFavorites.forEach((favorite) => {
      const parentSchema = sortedFavorites.schemas.find((schema) => {
        if (schema.id === favorite.productSchemaId) return true;

        return schema.children.some((child) => {
          if (child.id === favorite.productSchemaId) return true;

          return child.children.some((grandChild) => grandChild.id === favorite.productSchemaId);
        });
      });

      if (!parentSchema) {
        sortedFavorites.others.push(favorite);
      } else {
        parentSchema.articles.push(favorite);
      }
    });

    //filter out types and categories that are empty while keepng any articles in inner and outer 'other' properties
    sortedFavorites.schemas = sortedFavorites.schemas.filter((schema) => {
      let res;
      res = schema.articles.length > 0 || schema.others.length > 0;
      return res;
    });

    this.setTypeSums(sortedFavorites.schemas);

    //TODO: Consider separate function
    //calc sum of all favorites
    sortedFavorites.sum += sortedFavorites.others.length;
    for (let i = 0; i < sortedFavorites.schemas.length; i++) {
      sortedFavorites.sum += sortedFavorites.schemas[i].sum;
    }

    return sortedFavorites;
  }

  setTypeSums(array): void {
    for (let i = 0; i < array.length; i++) {
      array[i].sum = array[i].others.length + array[i].articles.length;
    }
  }

  async addAssetToFavorites(asset): Promise<any> {
    if (!this.unsortedFavorites) this.throwInvalidUnsortedFavoritesError();

    if (this.hasAlreadyAdded(asset)) {
      this.showAssetAlreadyAddedError();
      this.throwAssetAlreadyAddedError();
    }

    try {
      asset.preselectionId = await this.configurationService.createPreselectionForConfiguration(
        asset.assetId
      );
      this.unrealService.emitFavoriteAdded(asset.id, asset.preselectionId);
      this.unsortedFavorites.push({ ...asset, id: asset.preselectionId });
      this.unsortedFavoritesSubj.next(this.unsortedFavorites);
      this.sortedFavorites = this.generateSortedFavorites(this.schemaTree, this.unsortedFavorites);
      this.sortedFavoritesSubj.next(this.sortedFavorites);
    } catch (error) {
      this.showCreatePreselectionError();
      this.throwCreatePreselectionError();
    }
  }

  removeAssetFromFavorites(asset) {
    let preselectionIdToDelete = undefined;
    if (asset.preselectionId) {
      preselectionIdToDelete = asset.preselectionId;
    } else {
      preselectionIdToDelete = this.unsortedFavorites.find(
        (fav) => fav.assetId == asset.assetId
      ).id;
    }
    if (!preselectionIdToDelete) {
      console.error('No deletionId found in favoriteArticlesService');
      return;
    }

    this.configurationService
      .deletePreselectionById(preselectionIdToDelete)
      .pipe(first())
      .subscribe(
        (res) => {
          this.unrealService.emitFavoriteDeleted(preselectionIdToDelete);
          this.removeFavoriteLocally(asset);
          this.sortedFavorites = this.generateSortedFavorites(
            this.schemaTree,
            this.unsortedFavorites
          );
          this.sortedFavoritesSubj.next(this.sortedFavorites);
        },
        (error) => {
          console.error(error.message);
        }
      );
  }

  removeFavoriteFromProtocol(favorite) {
    console.log('removeFavoriteFromProtocol: ', favorite);

    let deletionId = favorite.id;
    /* If asset was chosen as favorite in session, its id as a favorite gets
     set as preselectionId, while loaded favorites already come with an id  */
    if (favorite.preselectionId) {
      deletionId = favorite.preselectionId;
    }
    this.configurationService.deletePreselectionById(deletionId).subscribe(
      (res) => {
        this.unrealService.emitFavoriteDeleted(favorite.id);
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  //remove all "other" favorite articles without categories/schemas
  removeFavoritesByIds(favoriteIDs) {
    console.log('removeFavoritesByIds(): ', favoriteIDs);

    this.levelService.deletePreselections(favoriteIDs).subscribe(
      (res) => {
        this.removeFavoritesLocally(favoriteIDs);
        this.sortedFavorites = this.generateSortedFavorites(
          this.schemaTree,
          this.unsortedFavorites
        );
        this.sortedFavoritesSubj.next(this.sortedFavorites);
        console.log('removed favorites by Ids: ', favoriteIDs);
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  //remove favorite articles within one categorie/schemas
  async removeFavoritesBySchema(schemaId): Promise<void> {
    console.log('removeFavoritesBySchema(): ', schemaId);

    this.configurationService
      .deletePreselectionsForSchemaAndConfiguration(schemaId)
      .pipe(
        map(
          async (res) => {
            // remove all favorites of chosen schema or its childschema, by updating subject without them
            // using helper funcitons getNewUnsortedFavorites and isFavoriteInSchema as .filter() cannot work with async callbacks
            const newUnsortedFavorites: any[] = await this.filterOutFavoritesByCategory(
              this.unsortedFavorites,
              schemaId
            );
            //console.log('new unsorted favorites = ', newUnsortedFavorites);
            this.unsortedFavorites = newUnsortedFavorites;
            this.unsortedFavoritesSubj.next(newUnsortedFavorites);

            this.sortedFavorites = this.generateSortedFavorites(
              this.schemaTree,
              this.unsortedFavorites
            );
            this.sortedFavoritesSubj.next(this.sortedFavorites);
            this.unrealService.emitFavoritesDeletedBySchema(schemaId);
          },
          (error) => {
            console.error(error.message);
          }
        )
      )
      .toPromise();
  }

  async filterOutFavoritesByCategory(unsortedFavorites, schemaId): Promise<any[]> {
    let favoritesArray: any[] = [];
    for (const favorite of unsortedFavorites) {
      const isFavoriteInSchema = await this.isFavoriteInSchema(favorite, schemaId);
      if (!isFavoriteInSchema) favoritesArray.push(favorite);
    }
    return favoritesArray;
  }

  async isFavoriteInSchema(favorite, schemaId): Promise<boolean> {
    if (schemaId == favorite.productSchemaId) {
      return true;
    }
    let schemaChildren = await this.schemasService.getChildrenIds(schemaId);

    if (schemaChildren.length) {
      // check if schema of favorite is of childSchema
      let foundChildSchema = schemaChildren.find((childId) => {
        return childId == favorite.productSchemaId;
      });

      if (foundChildSchema) return true;
    } else return false;
  }
  
  removeAllFavorites() {
    const favoriteIds = [];
    this.unsortedFavorites.forEach((favorite) => {
      //if asset was added to favorites in frontend
      if (favorite.preselectionId) favoriteIds.push(favorite.preselectionId);
      //if favorite already came from get favorites
      else favoriteIds.push(favorite.id);
    });
    console.log('removeAllFavorites(): ', favoriteIds);
    this.levelService.deletePreselections(favoriteIds).subscribe(
      () => {
        this.unsortedFavorites = [];
        this.unsortedFavoritesSubj.next(this.unsortedFavorites);
        this.sortedFavorites = this.generateSortedFavorites(
          this.schemaTree,
          this.unsortedFavorites
        );
        this.sortedFavoritesSubj.next(this.sortedFavorites);
        console.log('removed all favorites');
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  private removeFavoriteLocally(favorite) {
    let targetArticleIndex;

    console.log('removeFavoriteLocally: ', favorite, this.unsortedFavorites);
    try {
      targetArticleIndex = this.unsortedFavorites.findIndex(
        // using article id, because via article-overview.component favorite object is an article and via favorites.component, favorite is a preselection object
        (article) => article.assetId == favorite.assetId
      ); //in try in case no fitting id can be found
      this.unsortedFavorites.splice(targetArticleIndex, 1);
      this.unsortedFavoritesSubj.next(this.unsortedFavorites);
    } catch {
      console.error('Error when removing favorite');
    }
  }

  // ToDo refactor with own logic instead using removeFavoriteLocally -> call next only in the End
  removeFavoritesLocally(favorites) {
    favorites.forEach((favorite) => this.removeFavoriteLocally(favorite));
  }
  
  private hasAlreadyAdded(asset: any) {
    return this.unsortedFavorites.find((previousFav) => previousFav.assetId == asset.assetId);
  }

  private showAssetAlreadyAddedError() {
    this.errorDialogService.openDialog({
      errorCode: '4Exxxx',
      location: 'favoriteArticlesService - addFavorite',
      devinfo: 'Tried to add asset that already exists in favorites',
      publicinfo:
        'Das Hinzufügen des Produktes in die Merkliste ist fehlgeschlagen. Es existiert bereits in der Merkliste. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
      debug: false,
      autoFocus: false,
    });
  }

  private showCreatePreselectionError() {
    this.errorDialogService.openDialog({
      errorCode: '4Exxxx',
      location: 'favoriteArticlesService - createPreselectionForConfiguration',
      devinfo: 'Error in createPreselectionForConfiguration()',
      publicinfo:
        'Das Hinzufügen des Produktes in die Merkliste ist fehlgeschlagen. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
      debug: false,
      autoFocus: false,
    });
  }

  private throwInvalidUnsortedFavoritesError() {
    throw new Error(
      'Can not add Asset. Unsorted Favorites are not defined. Configuration/BVN is maybe not initialized.'
    );
  }

  private throwCreatePreselectionError() {
    throw new Error('Error when tyring to add asset to preselections');
  }

  private throwAssetAlreadyAddedError() {
    throw new Error('Tried to add asset that already exists in favorites');
  }
}
