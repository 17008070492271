import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {AssetSearchResult} from "../modules/asset-catalog/models/asset-search-result";

// TODO: Refactor, reconsider hard coded handling in frontend -> Endpoint needed?
export enum ProductStatus {
  VISUALIZE = 'Visualisierung erfassen',
  NEED_RESOURCE = 'Zusätzliches Produkt nötig',
  CATALOGUE_READY = 'Für Katalog ohne Visualisierung',
  COMPLETED = 'Visualisierung abgeschlossen',
}

export type ProductStatusKey = keyof typeof ProductStatus;

export interface ProductStatusObject {
  key: ProductStatusKey;
  value: string;
}

const ASSETS_PATH = '/assets/';
const ASSETS_SEARCH_PATH = ASSETS_PATH + 'search';

@Injectable()
export class AssetService {
  constructor(private http: HttpClient) {}

  private getProductStatusKeyList(): ProductStatusKey[] {
    return Object.keys(ProductStatus) as ProductStatusKey[];
  }

  getProductStatusListObject(): ProductStatusObject[] {
    const productStatusKeyList = this.getProductStatusKeyList();
    return productStatusKeyList.map((key) => {
      const productStatusKey = key as ProductStatusKey;
      return { key: productStatusKey, value: ProductStatus[productStatusKey] };
    });
  }

  insertAsset(body: any): Observable<any> {
    return this.http.post(environment.apiUrl + ASSETS_PATH, body);
  }

  updateAsset(assetId: string, body: any): Observable<any> {
    return this.http.put(environment.apiUrl + ASSETS_PATH + assetId, body);
  }

  setIsPublished(assetId: string, isPublished: any): Observable<any> {
    return this.http.post(
      environment.apiUrl + ASSETS_PATH + assetId + '/published/' + isPublished,
      {}
    );
  }

  deleteAssetById(assetId: string, forceDelete = true): Observable<any> {
    if (forceDelete) {
      return this.http.delete(environment.apiUrl + ASSETS_PATH + assetId + '?option=force');
    }
    return this.http.delete(environment.apiUrl + ASSETS_PATH + assetId);
  }

  fetchAssetsById(assetId: string): Observable<any> {
    return this.http.get(environment.apiUrl + ASSETS_PATH + assetId + '?thumbnail=true');
  }

  fetchAssetsByParameters(searchParams: HttpParams): Observable<AssetSearchResult> {
    return this.http.get<AssetSearchResult>(environment.apiUrl + ASSETS_SEARCH_PATH, { params: searchParams });
  }
}
