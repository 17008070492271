<app-signup-header></app-signup-header>
<div class="porter-container confirmation-container">
  <div class="logo-container">
    <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
  </div>

  <div class="modal content-container color-bg-white p-6">
    <div class="modal-header w-100 row">
      <h2 *ngIf="success" class="modal-title">
        <mat-icon color="accent">check_circle_outline</mat-icon>Aktivierung erfolgreich
      </h2>
      <h2 *ngIf="failure" class="modal-title">
        <mat-icon color="accent">error_outline</mat-icon> Aktivierung fehlgeschlagen
      </h2>
    </div>

    <div class="modal-text">
      <p *ngIf="success">Ihr Account wurde erfolgreich aktiviert.</p>
      <p *ngIf="failure">
        Leider ist ein Fehler bei der Aktivierung Ihres Accounts aufgetreten. Es kann sein, dass Ihr
        Account bereits aktiviert ist.<br /><br />
        Für weitere Hilfe kontaktieren Sie bitte unseren Support unter
        <a href="mailto:support@porter.de">support@porter.de</a>.
      </p>
    </div>

    <div *ngIf="success" class="btn-container">
      <button mat-flat-button color="accent" class="button-size-xl" routerLink="/login">
        Zum Login
      </button>
    </div>
  </div>
</div>
<app-signup-footer></app-signup-footer>
