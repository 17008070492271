import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

interface Element {
  innerText: string;
}

type AlertType = 'base' | 'info' | 'success' | 'warning' | 'error';

const INVALID_TYPE_ERROR_MESSAGE =
  'You are using an invalid alert type. Please use base, info, success, warning or error as type';

export interface Alert {
  type?: AlertType;
  showIcon?: boolean;
  collapseIcon?: boolean;
  title?: string;
  text?: string;
}

@Directive({
  selector: 'alert',
})
export class AlertDirective implements OnInit, Alert {
  @Input() type: AlertType = 'base';
  @Input() showIcon = true;
  @Input() collapseIcon = false;
  @Input() title: string;
  @Input() text: string;

  rootElement: Element = this.el.nativeElement;
  iconElement: Element;
  contentElement: Element;
  titleElement: Element;
  textElement: Element;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.addAlertClassToRootElement();
    this.createElements();
    this.setupHierarchy();
    this.handleType();
    this.handleIcon();
  }

  private addAlertClassToRootElement() {
    this.renderer.addClass(this.rootElement, 'alert');
  }

  private createElements() {
    this.createIconElement();
    this.createContentElement();
    this.createTitleElement();
    this.createTextElement();
  }

  private createIconElement() {
    this.iconElement = this.renderer.createElement('mat-icon');
    this.renderer.setAttribute(this.iconElement, 'role', 'img');
    this.renderer.addClass(this.iconElement, 'mat-icon');
    this.renderer.addClass(this.iconElement, 'material-icons');
  }

  private createContentElement() {
    this.contentElement = this.renderer.createElement('div');
    this.renderer.addClass(this.contentElement, 'alert-content');
  }

  private createTitleElement() {
    this.titleElement = this.renderer.createElement('h2');
    this.renderer.addClass(this.titleElement, 'alert-title');
    this.titleElement.innerText = this.title;
  }

  private createTextElement() {
    this.textElement = this.renderer.createElement('div');
    this.textElement.innerText = this.text;
  }

  private setupHierarchy() {
    this.renderer.appendChild(this.rootElement, this.iconElement);
    this.renderer.appendChild(this.rootElement, this.contentElement);
    if (this.title) this.renderer.appendChild(this.contentElement, this.titleElement);
    if (this.text) this.renderer.appendChild(this.contentElement, this.textElement);
  }

  private handleType() {
    switch (this.type) {
      case 'base':
        if (this.showIcon) this.iconElement.innerText = 'info_outline';
        break;
      case 'info':
        this.renderer.addClass(this.rootElement, 'alert-info');
        this.iconElement.innerText = 'info';
        break;
      case 'success':
        this.renderer.addClass(this.rootElement, 'alert-success');
        this.iconElement.innerText = 'check_circle';
        break;
      case 'warning':
        this.renderer.addClass(this.rootElement, 'alert-warning');
        this.iconElement.innerText = 'warning';
        break;
      case 'error':
        this.renderer.addClass(this.rootElement, 'alert-error');
        this.iconElement.innerText = 'error';
        break;
      default:
        throw new Error(INVALID_TYPE_ERROR_MESSAGE);
    }
  }

  private handleIcon() {
    if (this.collapseIcon) this.doCollapseIcon();
    if (!this.showIcon) this.doHideIcon();
  }

  private doCollapseIcon() {
    this.renderer.setStyle(this.iconElement, 'display', 'none');
  }

  private doHideIcon() {
    this.renderer.setStyle(this.iconElement, 'visibility', 'hidden');
  }
}
