import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'example-input-with-selector',
  templateUrl: './example-input-with-selector.html',
  styleUrls: ['./example-input-with-selector.scss'],
})
export class ExampleInputWithUnitSelector implements OnInit {
  referenceValue: any;
  exampleUnits: any[] = [
    { value: 100, viewValue: 'cm' },
    { value: 1, viewValue: 'm' },
  ];

  formGroup: FormGroup;

  constructor() {}

  ngOnInit() {
    /*
    set up form for wall height and attach it to floorplan object
    */
    this.setFileForm();
  }

  setFileForm() {
    this.formGroup = new FormGroup({
      inputValue: new FormControl(123),
      selectorValue: new FormControl('defaultValue', Validators.required),
    });
  }

  /*
    Validator to check if input value and mat-select value are valid in their combination
    */
  crossFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputVal = control.get('inputValue').value;
      const selectorVal = control.get('selectorValue').value;

      if (!inputVal) {
        return null;
      }
      let someValidityCondition = inputVal * selectorVal > 5;

      return someValidityCondition ? null : { belowMinHeight: true };
    };
  }

  onChange($event) {}

  onInputChange($event) {}
}
