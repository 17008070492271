import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ExporterPluginService } from './services/exporter-plugin.service';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "../environments/environment";

@Injectable()
export class ExportPluginGuard implements CanActivate {
  constructor(private exporterPluginService: ExporterPluginService, private router: Router, private authService: AuthService) {}

  async canActivate(): Promise<boolean> {
      if (this.exporterPluginService.usesExport() || this.authService.hasUserGroup([environment.adminGroup])) {
          return true;
      } else {
          await this.router.navigate(['/projects'], { queryParams: { returnUrl: window.location.pathname + window.location.search } });
          return false;
      }
  }
}
