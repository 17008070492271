import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, first, tap } from 'rxjs/operators';
import { Level } from '../business-domain/Level';
import { environment } from './../../environments/environment';
import { ErrorDialogService } from '../services/errordialog.service';

interface Response<T> {
  data: T;
}

@Injectable()
export class LevelService {
  constructor(private http: HttpClient, private errorDialogService: ErrorDialogService) {}

  public getLevelsByBuilding(buildingId: string): Observable<Level[]> {
    return this.http
      .get<Response<Level[]>>(environment.apiUrl + `/buildings/${buildingId}/levels`)
      .pipe(map((response) => response.data)) as Observable<Level[]>;
  }

  public addAssetToPreselection(levelId: string, typeId: string, assetId: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/levels/' + levelId + '/types/' + typeId + '/preselections',
      { assetId: assetId }
    );
  }

  public getLevelById(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId);
  }

  public getPreselectionsByLevel(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/preselections');
  }

  public getPreselection(levelId: string, typeId: string, preselectionId: string): Observable<any> {
    return this.http.get(
      environment.apiUrl +
        '/levels/' +
        levelId +
        '/types/' +
        typeId +
        '/preselections/' +
        preselectionId
    );
  }

  public deletePreselection(
    levelId: string,
    typeId: string,
    preselectionId: string
  ): Observable<any> {
    return this.http.delete(
      environment.apiUrl +
        '/levels/' +
        levelId +
        '/types/' +
        typeId +
        '/preselections/' +
        preselectionId
    );
  }

  public deletePreselections(preselectionIds: string[]): Observable<any> {
    console.log('deletePreselections(): ', preselectionIds);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        data: preselectionIds,
      },
    };

    return this.http.delete(environment.apiUrl + '/preselections', options);
  }

  public deletePreselectionsBySchema(levelId, schemaId: string): Observable<any> {
    return this.http.delete(
      environment.apiUrl + '/levels/' + levelId + '/schemas/' + schemaId + '/preselections'
    );
  }

  public getType(levelId: string, typeId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/types/' + typeId);
  }

  public getTypes(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/types');
  }

  public getTypeAndCategoryTree(): Observable<any> {
    return this.http.get(environment.apiUrl + '/types/').pipe(
      map((res: any) => res.data)
      //tap((res) => console.log('getTypeAndCategoryTree tap = ', res))
    );
  }

  public getTypeName(typeId: string, types: any): string {
    for (let index = 0; index < types.length; index++) {
      if (types[index].id == typeId) {
        return types[index].displayName;
      }
    }
  }

  public getCategoriesForLevel(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/categories');
  }

  public getCategoriesForType(levelId: string, typeId: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/levels/' + levelId + '/types/' + typeId + '/categories'
    );
  }

  public getCategoryName(typeId: string, categories: any) {
    return categories.filter((item) => item.typeId === typeId).map((item) => item.displayName);
  }

  public getObjectMaterials(objectId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/objects/' + objectId + '/materials');
  }

  public getColorsById(colorId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/colors/' + colorId);
  }

  public getActorsByLevelId(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/objects');
  }

  public getActorSlotsByLevelId(levelId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/levels/' + levelId + '/actorSlots');
  }
}
