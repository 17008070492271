import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Floorplan } from '../business-domain/Floorplan';
import { environment } from '../../environments/environment';

@Injectable()
export class FloorplanService {
  private uploadSubs: any;

  constructor(private http: HttpClient) {
    this.uploadSubs = {};
  }

  private unsub(filename) {
    const sub = this.uploadSubs[filename];
    if (!sub) return;

    sub.unsubscribe();
    delete this.uploadSubs[filename];
  }

  cancelUploads() {
    for (const filename in this.uploadSubs) {
      if (true) this.unsub(filename);
    }
  }

  deleteFloorplan(floorplanId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/floorplans/' + floorplanId);
  }

  uploadFloorplans(unitId: string, files: File[]): Observable<number> {
    return Observable.create((observer) => {
      let totalProgress = 0;
      let numComplete = 0;

      for (const file of files) {
        let currentProgress = 0;
        const floorplan = { unitId, filename: file.name };
        this.uploadFloorplan(floorplan, file).subscribe(
          (progress) => {
            const progressDiff = progress - currentProgress;
            totalProgress += Math.round(progressDiff / files.length);
            currentProgress = progress;
            observer.next(totalProgress);
          },
          null,
          () => {
            numComplete++;
            if (numComplete === files.length) observer.complete();
          }
        );
      }
    });
  }

  uploadFloorplan(floorplan: { unitId: string; filename: string }, file: File): Observable<number> {
    if (!floorplan.filename) floorplan.filename = file.name;

    return Observable.create((observer) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const fullBase64String = reader.result as string; // contains encoding
        const base64String = fullBase64String.split(',')[1]; // use only data-part

        const request = new HttpRequest(
          'POST',
          environment.apiUrl + '/floorplans',
          { ...floorplan, file: base64String },
          { reportProgress: true }
        );

        const uploadSub = this.http.request(request).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                const percentDone = Math.round((100 * event.loaded) / event.total);
                observer.next(percentDone);
                break;
              case HttpEventType.ResponseHeader:
                if (!event.ok) observer.error(event);
                break;
              default:
                break;
            }
          },
          (error) => {
            console.error(error);
            this.unsub(file.name);
            observer.error(error);
          },
          () => {
            this.unsub(file.name);
            observer.complete();
          }
        );
        this.uploadSubs[file.name] = uploadSub;
      });
      reader.readAsDataURL(file);
    });
  }

  getFloorplan(floorplanId: string, thumbnail = false): Observable<string> {
    return this.http
      .get(environment.apiUrl + '/floorplans/' + floorplanId, {
        params: { thumbnail: String(thumbnail) },
      })
      .pipe(map((floorplan: { data: string }) => floorplan.data));
  }

  getFloorplansByUnit(unitId: string): Observable<Floorplan[]> {
    return this.http.get<Floorplan[]>(environment.apiUrl + `/units/${unitId}/floorplans`);
  }

  startConversion(floorplanId: string): Observable<any> {
    return this.http.post(environment.apiUrl + `/floorplans/${floorplanId}/convert`, {});
  }
}
