<div class="modal-s real-modal">
  <div class="modal-header w-100 row">
    <!-- <h2 class="modal-title">
      Das ist eine überlange Überschrift für den Beispiel-Dialog
    </h2> -->
    <div class="modal-title">
      <!-- <mat-icon color="accent">error_outline</mat-icon> -->T ÜÄÖ Titel mittellange Überschrift
      ÜÄÖ gypq lorem ipsum dolor si amet
    </div>
    <div class="close-button" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="modal-main">
    <p class="modal-subtitle">TÜÄÖ Titel Das ist ein Untertitel</p>
    <p class="modal-text">
      TÜÄÖ Titelgypq Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
      eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
      nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
      Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
      arcu.ÜÄÖ gypq TÜÄÖ Titelgypq Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
      commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
      montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
      quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
      vulputate eget, arcu.ÜÄÖ gypq TÜÄÖ Titelgypq Lorem ipsum dolor sit amet, consectetuer
      adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
      et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
      pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo,
      fringilla vel, aliquet nec, vulputate eget, arcu.ÜÄÖ gypq TÜÄÖ Titelgypq Lorem ipsum dolor sit
      amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
      natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
      ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede
      justo, fringilla vel, aliquet nec, vulputate eget, arcu.ÜÄÖ gypq TÜÄÖ Titelgypq Lorem ipsum
      dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
      Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
      quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
      enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.ÜÄÖ gypq TÜÄÖ
      Titelgypq Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
      dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
      ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla
      consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget,
      arcu.ÜÄÖ gypq TÜÄÖ Titelgypq Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
      commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
      montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
      quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
      vulputate eget, arcu.ÜÄÖ gypq
    </p>
  </div>
  <div class="dialog-actions row">
    <button mat-button color="primary" mat-dialog-close>Abbrechen</button>
    <button mat-flat-button color="accent" mat-dialog-close>Akzeptieren</button>
  </div>
</div>
