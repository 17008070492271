import { Component, OnInit, Renderer2 } from '@angular/core';
import { Alert } from '../../directives/alert.directive';

const PREVIEW_ALERT: Alert = {
  type: 'info',
  text:
    'Hier können zukünftig Nutzer der Hersteller Lizenz die Anbindung Ihrer Produkte für die ' +
    'virtuelle Bemusterung, Statistiken zu Ihren Produkten, Auswertungen zu bemusterten Produkten ' +
    "und die wichtigsten KPI's (Key Performance Indicators) finden.",
};

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss'],
})
export class ManufacturerComponent implements OnInit {
  constructor(private renderer: Renderer2,) {}

  isShowing: boolean;

  ngOnInit(): void {
    this.isShowing = true;
    this.renderer.listen(window, 'resize', () => this.checkForMobile());
  }

  checkForMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 880;
  }

  toggleSidenav() {
    this.isShowing = !this.isShowing;
  }

  getAlert(): Alert {
    return PREVIEW_ALERT;
  }
}
