<div class="component-wrapper w-100 flex-dir-col">
  <header>
    <h1>Visual Units</h1>
    <h2>Übersicht visueller Standard-Komponenten aus HTML und CSS</h2>
  </header>
  <div class="grid">
    <div class="grid-tile">
      <h3>Dropdown</h3>
      <p>
        This is our custom dropdown component which wraps a mat-select component as its core part.
      </p>
      <p>For usage details check out the DropdownConfiguration interface in the component.</p>
      <p>
        Note that while the mat-select needs to be overwritten by global styles, we keep them in the
        dropdown.component.scss. This is acchieved by importing the component styles in the global
        stylessheet and applying them to the host element in the @Component annotation in
        dropdown.component.ts. This is done because wrapping the dropdown styles inside the :host
        selector does not result in overwriting global Material styles
      </p>
    </div>
    <div class="grid-tile flex-dir-col">
      <div>
        <div>
          <p>Standard</p>
          <dropdown [configuration]="dropdownConfiguration_1"></dropdown>
        </div>
        <br />
        <div>
          <p>Scrollbar and overflowing Select-Option</p>
          <dropdown [configuration]="dropdownConfiguration_2"></dropdown>
        </div>
        <br />
        <div>
          <p>Disabled</p>
          <dropdown [configuration]="dropdownConfiguration_3"></dropdown>
        </div>
      </div>
    </div>
    <div class="grid-tile">
      <h3 id="standard-modal">Standard Modal/Dialog</h3>
      <p>
        Öffne den Dialog per Klick auf den Button, um einen Standard-Dialog auf Basis unserer
        modal-Klassen zu sehen.
      </p>
      <p>
        <strong>
          Unbedingt beachten: Angular Material ermöglicht es dem dialog ein config-Objekt zu
          übergeben.
        </strong>
      </p>
      <p>Liste der möglichen Properties:</p>
      <p>
        <a href="https://material.angular.io/components/dialog/api#MatDialogConfig" target="_blank"
          >material.angular.io/components/dialog/api#MatDialogConfig</a
        >
      </p>
      <p>
        Diese Möglichkeit sollte genutzt werden, um z.B. unerwünschten Auto-focus zu vermeinden, wie
        im folgenden Snippet. Angaben, wie width, die auch im CSS angegeben werden können bitte nur
        in Ausnahmefällen verwenden. Der Ursprung des Werts ist sonst schwer zu finden und in
        Dev-Tools gar nicht.
      </p>
      <pre>
    <code>{{this.openDialog.toString()}}</code></pre>
    </div>
    <div class="grid-tile">
      <button mat-flat-button color="accent" (click)="openDialog()">Standard Dialog</button>
    </div>
    <div class="grid-tile">
      <h3 id="infobox-dialog">Infobox-Dialog</h3>
      <p>
        Die Inhalte des Infobox-Dialog sind flexibel und müssen per infobox-dialog.service übergeben
        werden.
      </p>
      <p>Folgend die Properties des Beispiels.</p>
      <pre>
    <code>{{this.openInfoboxDialog.toString()}}</code></pre>
    </div>
    <div class="grid-tile">
      <button mat-flat-button color="accent" (click)="openInfoboxDialog()">Infobox Dialog</button>
    </div>
    <div class="grid-tile">
      <h3 id="error-dialog">Error-Dialog</h3>
      <p>
        Die Inhalte des Error-Dialog sind flexibel, je nach Error-Code aus dem Backend werden die
        entsprechenden Inhalte übergeben.
      </p>
      <p>
        Die aktuellsten <b>errorCode</b> sind im node-server in der Datei src/errorcodes.js zu
        finden. Eine Anleitung zum Error Handling und dem Aufbau der Error-Codes ist
        <a href="https://teamxcyde.atlassian.net/l/c/fUQVcrCA" target="_blank"
          >hier in Confluence</a
        >
        zu finden.
      </p>
      <p>Folgend die Properties des Beispiels.</p>
      <pre>
    <code>{{this.openErrorDialog.toString()}}</code></pre>
      <p>
        In der <b>location</b> sollte die Klasse/Funktion des ErrorDialog Aufrufs notiert werden.
      </p>
      <p>
        Standardmäßig wird als <b>devInfo</b> "error.message" - falls verfügbar - übergeben.
        Ansonsten sollte hier ein aktueller Zustand, wie z.B. 'res.status === FAILED' übergeben
        werden.
      </p>

      <p>
        In der <b>puplicInfo</b> sollte mindestens einer der beiden folgenden Textblöcken verwendet
        werden:
      </p>
      <ul>
        <li>
          Bitte wenden Sie sich an unseren <a href="mailto:support@porter.de">Support</a>. Vielen
          Dank.
        </li>
        <li>
          Mögliche Lösungen finden Sie in unserem
          <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen
          dann noch Fragen, wenden Sie sich bitte direkt an unseren
          <a href="mailto:support@porter.de">Support</a>.
        </li>
      </ul>
      <p>
        <b>debug</b> sollte nur für die Fehlersuche auf true gestellt werden, für einen Merge immer
        auf false.
      </p>
      <p>
        <b>autoFocus</b> muss immer false sein, da sonst das erste tabbable Element auf Fokus
        gesetzt & gehighlighted wird.
      </p>
    </div>
    <div class="grid-tile">
      <button mat-flat-button color="accent" (click)="openErrorDialog()">Error Dialog</button>
    </div>
    <div class="grid-tile">
      <h3 id="buttons">Angular Material Buttons</h3>
      <p>
        Das Prinzip der Angular material Buttons wurde auf die Bedürfnisse unserer Anwendung
        übertragen:
        <a href="https://material.angular.io/components/button" target="_blank"
          >https://material.angular.io/components/button</a
        >
      </p>
      <p>
        Ein Button wir standardmäßig und ganz normal per HTML mit den unterschiedlichen Attribute
        Definitions und Klassenangaben integriert. Dabei müssen keine Veränderungen am CSS
        vorgenommen werden, um Farbe oder Größe individuell anzupassen..<br /><br />Beispiele (ohne
        die Einfärbung durch ein Theme):
      </p>

      <button mat-button>Basic mat-button</button>

      <code>
        <pre>{{ code_matbutton }}</pre>
      </code>

      <button mat-flat-button>Basic mat-flat-button</button>

      <code>
        <pre>{{ code_matflatbutton }}</pre>
      </code>

      <button mat-stroked-button>Basic mat-stroked-button</button>
      <code>
        <pre>{{ code_matstrokedbutton }}</pre>
      </code>

      <p>&nbsp;</p>
      <p>
        <strong>Farben:</strong>
      </p>

      <p>
        Jeder Button kann dann per Attribute Definitions eingefärbt werden, in den
        <strong>4 verfügbaren Farben (accent, primary, warn, disabled)</strong>. Bei der disabled
        Variante sind zudem alle Stati/Funktionalitäten (Hover, Active, Klick-Ripple-Effekt)
        deaktiviert.<br /><br />Beispiel <strong>color="accent"</strong>:
      </p>
      <button mat-flat-button color="accent">Accent</button>

      <code>
        <pre>{{ code_matflatbutton_attributecolors_accent }}</pre>
      </code>
      <p><br /><br />Beispiel <strong>color="primary"</strong>:</p>
      <button mat-flat-button color="primary">Primary</button>

      <code>
        <pre>{{ code_matflatbutton_attributecolors_primary }}</pre>
      </code>

      <p>&nbsp;</p>
      <p>
        <strong>Varianten:</strong>
      </p>

      <p>
        Insgesamt stehen <strong>4 verschiedene Varianten</strong> an Buttons zur Verfügung (jeweils
        mit/ohne Icon):
      </p>

      <ul>
        <li>
          <strong><i>mat-flat-button</i></strong> -> Flacher Button mit flächigem farbigen
          Hintergrund
        </li>
        <li>
          <strong><i>mat-stroked-button </i></strong>-> Outline Button mit farbiger border
        </li>
        <li>
          <strong><i>mat-button </i></strong>-> Button-Link mit farbigem Text und Ripple-Effekt bei
          Klick
        </li>
        <li>
          <strong><i>mat-icon-button</i></strong> -> Button ohne Texte, entweder mit oder ohne
          Hintergrund (siehe "Only Icons")
        </li>
      </ul>

      <p>&nbsp;</p>
      <p>
        <strong>Icons:</strong>
      </p>

      <p>
        Zudem kann jeder Button (außer der mat-icon-button der immer eines dabei hat) mit einem
        <strong>Icon bzw. Material Icon</strong> (<a
          href="https://material.angular.io/components/icon/overview"
          target="_blank"
          >https://material.angular.io/components/icon/overview</a
        >
        und
        <a href="https://fonts.google.com/icons" target="_blank">https://fonts.google.com/icons</a
        >)versehen werden.<br /><br />Beispiel:
      </p>
      <button mat-flat-button color="accent">
        <mat-icon class="material-icons">add</mat-icon>Accent
      </button>

      <code>
        <pre>{{ code_matflatbutton_icon }}</pre>
      </code>

      <p>&nbsp;</p>
      <p>
        <strong>Only Icons :</strong>
      </p>

      <p>mat-icon-button <strong>ohne Hintergrund</strong>.<br /><br />Beispiel:</p>

      <button mat-icon-button color="accent">
        <mat-icon class="material-icons">add</mat-icon>
      </button>

      <code>
        <pre>{{ code_maticonbutton }}</pre>
      </code>
      <p>&nbsp;</p>

      <p>
        mat-icon button <strong>mit Hintergrund</strong> (hier muss class="icon-button-bg"
        hinzugefügt werden).<br /><br />Beispiel:
      </p>

      <button mat-icon-button class="icon-button-bg" color="accent">
        <mat-icon class="material-icons">add</mat-icon>
      </button>

      <code>
        <pre>{{ code_maticonbutton_bg }}</pre>
      </code>

      <p>&nbsp;</p>
      <p>
        <strong>Button Sizes:</strong>
      </p>
      <p>
        Zudem gibt es 4 verschiedene Buttongrößen (hier muss class="button-size-xl" hinzugefügt
        werden).<br /><br />Beispiel:
      </p>

      <button mat-flat-button class="button-size-xl" color="accent">XL Button</button>
      <code>
        <pre>{{ code_matbutton_size }}</pre>
      </code>

      <p></p>
    </div>
    <div class="grid-tile pl-0 pr-0">
      <div>
        <div>
          <h3>
            Buttontyp: &nbsp;&nbsp; accent &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; primary
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; warn
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; disabled
          </h3>
        </div>

        <p>
          <span class="beschriftung">mat-flat-button</span>
          <button mat-flat-button color="accent">
            <!-- Consider handling icon-btn-inner-wrapper via a directive -->
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-flat-button color="primary">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-flat-button color="warn">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-flat-button color="disabled">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>
        <p>
          <span class="beschriftung">mat-flat-button</span>
          <button mat-flat-button color="accent">Button</button>
          <button mat-flat-button color="primary">Button</button>
          <button mat-flat-button color="warn">Button</button>
          <button mat-flat-button color="disabled">Button</button>
        </p>
        <p>
          <span class="beschriftung">mat-stroked-button</span>
          <button mat-stroked-button color="accent">
            <!-- Consider handling icon-btn-inner-wrapper via a directive -->
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-stroked-button color="primary">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-stroked-button color="warn">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-stroked-button color="disabled">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>
        <p>
          <span class="beschriftung">mat-stroked-button</span>
          <button mat-stroked-button color="accent">Button</button>
          <button mat-stroked-button color="primary">Button</button>
          <button mat-stroked-button color="warn">Button</button>
          <button mat-stroked-button color="disabled">Button</button>
        </p>
        <p>
          <span class="beschriftung">mat-button</span>
          <button mat-button color="accent">
            <!-- Consider handling icon-btn-inner-wrapper via a directive -->
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-button color="primary">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-button color="warn">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
          <button mat-button color="disabled">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>

        <p>
          <span class="beschriftung">mat-button</span>
          <button mat-button color="accent">Button</button>
          <button mat-button color="primary">Button</button>
          <button mat-button color="warn">Button</button>
          <button mat-button color="disabled">Button</button>
        </p>
        <p>
          <span class="beschriftung">mat-icon-button</span>
          <button mat-icon-button class="icon-button-bg" color="accent">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button class="icon-button-bg" color="primary">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button class="icon-button-bg" color="warn">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button class="icon-button-bg" color="disabled">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
        </p>
        <p>
          <span class="beschriftung">mat-icon-button</span>
          <button mat-icon-button color="accent">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button color="primary">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button color="warn">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
          <button mat-icon-button color="disabled">
            <mat-icon class="material-icons">add</mat-icon>
          </button>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Button Sizes:</strong>
        </p>
        <p>
          <span class="beschriftung">.button-size-xl</span>
          <button mat-flat-button class="button-size-xl" color="accent">XL mat-flat-button</button>
          <button mat-stroked-button class="button-size-xl" color="accent">
            XL mat-stroked-button
          </button>
          <button mat-stroked-button class="button-size-xl" color="accent">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>
        <p>
          <span class="beschriftung">.button-size-l</span>
          <button mat-flat-button class="button-size-l" color="accent">L mat-flat-button</button>
          <button mat-stroked-button class="button-size-l" color="accent">
            L mat-stroked-button
          </button>
          <button mat-stroked-button class="button-size-l" color="accent">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>
        <p>
          <span class="beschriftung">Default</span>
          <button mat-flat-button color="accent">Default mat-flat-button</button>
          <button mat-stroked-button color="accent">Default mat-stroked-button</button>
        </p>
        <button mat-stroked-button color="accent">
          <div class="icon-btn-inner-wrapper">
            <mat-icon class="material-icons">add</mat-icon>Button
          </div>
        </button>
        <p>
          <span class="beschriftung">.button-size-s</span>
          <button mat-flat-button class="button-size-s" color="accent">S mat-flat-button</button>
          <button mat-stroked-button class="button-size-s" color="accent">
            S mat-stroked-button
          </button>
          <button mat-stroked-button class="button-size-s" color="accent">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons">add</mat-icon>Button
            </div>
          </button>
        </p>
      </div>
    </div>

    <div class="grid-tile">
      <h3 id="shadows">CSS Shadows</h3>
      <p>Schattendefinitionen über Mixins.</p>

      <pre>
        @include shadow-2;
      </pre>
    </div>
    <div class="grid-tile">
      <div>
        <p>
          <mat-card class="mb-5 p-3 shadow-none">@include shadow-0</mat-card>
          <mat-card class="mb-8 p-3 shadow-2">@include shadow-2</mat-card>
          <mat-card class="mb-8 p-3 shadow-2-inset">@include shadow-2-inset</mat-card>
          <mat-card class="mb-8 p-3 shadow-4">@include shadow-4</mat-card>
          <mat-card class="mb-8 p-3 shadow-8">@include shadow-8</mat-card>
          <mat-card class="mb-8 p-3 shadow-16">@include shadow-16</mat-card>
          <mat-card class="mb-8 p-3 shadow-24">@include shadow-24</mat-card>
        </p>
      </div>
    </div>
    <div class="grid-tile">
      <h3 id="tooltipps">Basic Tooltips</h3>
      <p>
        Die Tooltips aus Angular Material (https://material.angular.io/components/tooltip/overview)
        werden über folgende drei Optionen gesteuert:
      </p>
      <code>
        matTooltip="Tooltip Erklärungstext"<br />
        matTooltipClass="tooltip-icon | tooltip-definition | tooltip-interactive"<br />
        matTooltipPosition="above | below | left | right | before | after"<br />
      </code>
      <p></p>
      <p></p>
      <h4>Icon Tooltip</h4>
      <p>
        Ein Icon-Tooltip wird verwendet, um die Aktion oder den Namen einer interaktiven
        Icon-Schaltfläche zu verdeutlichen. Dieser besteht nur aus wenigen Worten, bestenfalls nur
        einem (maximal 2-3).
      </p>
      <p></p>
      <h4>Definition Tooltips</h4>
      <p>
        Der Definitionstooltip bietet zusätzliche Hilfe oder definiert ein Element oder einen
        Begriff.
      </p>
    </div>
    <div class="grid-tile">
      <mat-list role="list">
        <mat-list-item>
          Icon & above
          <mat-icon
            class="info-icon"
            matTooltip="Icon Tooltip"
            matTooltipClass="tooltip-icon"
            matTooltipPosition="above"
          >
            info
          </mat-icon>
        </mat-list-item>
        <mat-list-item>
          Icon & below
          <mat-icon
            class="info-icon"
            matTooltip="Icon Tooltip"
            matTooltipClass="tooltip-icon"
            matTooltipPosition="below"
          >
            info
          </mat-icon>
        </mat-list-item>
        <mat-list-item>
          Definition & left
          <mat-icon
            class="info-icon"
            matTooltip="Definition Tooltip Erklärungstext mit etwas mehr Textinhalt innerhalb des Tooltips"
            matTooltipClass="tooltip-definition"
            matTooltipPosition="left"
          >
            info
          </mat-icon>
        </mat-list-item>
        <mat-list-item>
          Definition & right
          <mat-icon
            class="info-icon"
            matTooltip="Definition Tooltip Erklärungstext mit etwas mehr Textinhalt innerhalb des Tooltips"
            matTooltipClass="tooltip-definition"
            matTooltipPosition="right"
          >
            info
          </mat-icon>
        </mat-list-item>
      </mat-list>
    </div>

    <div class="grid-tile">
      <h3>HTML/Interactive Tooltips</h3>
      <p>
        ! Bisherige Lösung mittels ng-tippy ist veraltet. Der Code dafür ist noch im auskommentiert
        hier im Templat enthalten. Bei Bedarf könnte er vielleicht wiederverwendet werden, falls
        künftig tippy.js direkt verwendet wrden soll.
      </p>
      <!-- <p>Für HTML Tooltips wird tippy.js (https://atomiks.github.io/tippyjs/) verwendet.</p>

      <pre>
        <code [innerHTML]>
          appTippy
          [tippyOptions]=
            arrow: false,
            allowHTML: true,
            interactive: true,
            placement: 'top',
            html: '#interactiveTooltipOne',
            animation: 'scale'
        </code>
      </pre>
      <p></p>
      placement-Optionen: 'top', 'top-start', 'top-end', 'right', 'right-start', 'right-end',
      'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto',
      'auto-start', 'auto-end'
      <p></p>
      <h4>Interactive Tooltip</h4>
      <p>
        Interaktive QuickInfos können Rich Text und andere interaktive Elemente wie Schaltflächen
        oder Links enthalten.
      </p> -->
    </div>
    <div class="grid-tile">
      <!-- <mat-list role="list">
        <mat-list-item>
          <div id="interactiveTooltipOne" style="display: none">
            <strong>Interactive Tooltip One</strong><br />Tooltip Erklärungstext mit etwas mehr
            Textinhalt innerhalb des Tooltips.<br /><a href="#">Verlinkung</a>
          </div>
          Interactive Tooltip Top
          <mat-icon
            class="info-icon"
            appTippy
            [tippyOptions]="{
              arrow: false,
              allowHTML: true,
              interactive: true,
              placement: 'top',
              html: '#interactiveTooltipOne',
              animation: 'scale'
            }"
          >
            info
          </mat-icon>
        </mat-list-item>
        <mat-list-item>
          <div id="interactiveTooltipTwo" style="display: none">
            <strong>Interactive Tooltip Two</strong><br />Tooltip Erklärungstext mit etwas mehr
            Textinhalt innerhalb des Tooltips.<br /><a herf="#">Verlinkung</a>
          </div>
          Interactive Tooltip bottom-end
          <mat-icon
            class="info-icon"
            appTippy
            [tippyOptions]="{
              arrow: false,
              allowHTML: true,
              interactive: true,
              placement: 'bottom-end',
              html: '#interactiveTooltipTwo',
              animation: 'scale'
            }"
          >
            info
          </mat-icon>
        </mat-list-item>
        <mat-list-item>
          <div id="interactiveTooltipThree" style="display: none">
            <strong>Interactive Tooltip Three</strong><br />Tooltip Erklärungstext mit etwas mehr
            Textinhalt innerhalb des Tooltips.<br /><a>Verlinkung</a>
          </div>
          Interactive Tooltip auto-start
          <mat-icon
            class="info-icon"
            appTippy
            [tippyOptions]="{
              arrow: false,
              allowHTML: true,
              interactive: true,
              placement: 'bottom-end',
              html: '#interactiveTooltipThree',
              animation: 'scale'
            }"
          >
            info
          </mat-icon>
        </mat-list-item>
      </mat-list> -->
    </div>
    <div class="grid-tile">
      <h3 id="input-dropdown">Input mit Dropdown für Einheit (IN ARBEIT)</h3>
      <p>Eingebunden über Komponente example-input-with-selector in helpers.</p>
    </div>
    <div class="grid-tile">
      <example-input-with-selector></example-input-with-selector>
    </div>
    <div class="grid-tile">
      <h3 id="toggle-button">Toggle Button</h3>
      <p>[Beschreibung]</p>
    </div>
    <div class="grid-tile">
      <div
        class="toggle-button"
        [ngClass]="{ active: showToggle }"
        (click)="toggleState()"
        matTooltip="Dies ist die Erklärung des Toogle Button XYZ"
        matTooltipClass="tooltip-definition"
        matTooltipPosition="below"
      >
        <div class="toggle-slider"></div>
        <p>XYZ anzeigen</p>
      </div>
    </div>
    <div class="grid-tile">
      <h3 id="typography-levels">Typography-Levels</h3>
      <p>
        Unsere Standardgrößen für Fonts sind im Frontend über Typography-Levels umgesetzt, wie sie
        Angular-Material verwendet.
      </p>
      <p>
        Sie sind unterteilt in Heading- und Body-Levels. Heading- und Body-Levels unterscheiden sich
        grundsätzlich in line-heights und font-weights.
      </p>
      <p>
        Der Einsatz der Levels kann einerseits über die zuweisung zu Angular Material
        Standard-Levels erfolgen, sodass Material unsere levels in seine Standard-Klassen
        miteinbeiht. Andererseits können wir sie per Mixins einsetzen: @mixin
        get-level-styles($level-name, $important: '').
      </p>
    </div>
    <div class="grid-tile flex-dir-col">
      <div class="typo-levels m-5">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>
    </div>
    <div class="grid-tile">
      <h3>Alerts</h3>
    </div>
    <div class="start grid-tile flex-dir-col">
      <h4>Base Alerts</h4>
      <alert
        type="base"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="info"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="success"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="warning"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="error"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <h4>Hidden Icons</h4>
      <alert
        [showIcon]="false"
        type="base"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [showIcon]="false"
        type="info"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [showIcon]="false"
        type="success"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [showIcon]="false"
        type="warning"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [showIcon]="false"
        type="error"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <h4>Collapsed Icons</h4>
      <alert
        [collapseIcon]="true"
        type="base"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [collapseIcon]="true"
        type="info"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [collapseIcon]="true"
        type="success"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [collapseIcon]="true"
        type="warning"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        [collapseIcon]="true"
        type="error"
        title="Hinweis zur Speicherung Ihrer Merkliste:"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <h4>Only Text</h4>
      <alert
        type="base"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="info"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="success"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="warning"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <alert
        type="error"
        text="Ihre Merkliste wird automatisch mit jeder Änderung gespeichert. Änderungen sind bis 6 Stunden vor Ihrem Beratungstermin möglich."
      ></alert>

      <h4>Only Title</h4>
      <alert type="base" title="Hinweis zur Speicherung Ihrer Merkliste"></alert>

      <alert type="info" title="Hinweis zur Speicherung Ihrer Merkliste"></alert>

      <alert type="success" title="Hinweis zur Speicherung Ihrer Merkliste"></alert>

      <alert type="warning" title="Hinweis zur Speicherung Ihrer Merkliste"></alert>

      <alert type="error" title="Hinweis zur Speicherung Ihrer Merkliste"></alert>
    </div>

    <div class="grid-tile">
      <h3>Wohnstil Label</h3>
      <pre>&lt;span class="badge-small"&gt;Natürlich&lt;/span&gt;</pre>
      <pre>&lt;span class="badge-medium"&gt;Natürlich&lt;/span&gt;</pre>
    </div>
    <div class="grid-tile">
      <div class="test">
        <span class="badge-small">Natürlich</span>
        <span class="badge-medium">Natürlich</span>
      </div>
    </div>

    <div class="grid-tile">
      <h3>Information Label</h3>
      <pre>&lt;span class="info-label-transparent"&gt;INFO&lt;/span&gt;</pre>
      <pre>&lt;span class="info-label-black"&gt;INFO&lt;/span&gt;</pre>
      <pre>&lt;span class="info-label-black"&gt;INFO&lt;/span&gt;</pre>
    </div>
    <div class="grid-tile">
      <div>
        <span class="info-label-transparent">INFO</span>
        <span class="info-label-black">INFO</span>
        <span class="info-label-black">INFO</span>
      </div>
    </div>

    <div class="grid-tile">
      <h3>[Bezeichnung]</h3>
      <p>[Beschreibung]</p>
    </div>
    <div class="grid-tile">[Code der visuellen Komponente]</div>
  </div>
</div>
