import { Product } from './product.interface';

export const DEFAULT_PRODUCT: Product = {
  additionalCostsHint: false,
  hexcode: '',
  firstLevelCategoryName: '',
  secondLevelCategoryName: '',
  articleGroup: null,
  articleId: null,
  assetId: 'd2f8b08b-4fa2-408b-b9d6-4f23cd4f29b8',
  assetSourceId: 'fingerhaus',
  assetType: 'OBJECT',
  baseType: '',
  categoryId: '903f3913-8879-4125-afe9-34e7f2c69f93',
  collection: '',
  color: null,
  colorArea: '',
  colorId: null,
  colorName: '',
  coloration: '',
  commodityGroup: null,
  dek_type: '',
  deletionDate: null,
  desc1: null,
  desc2: null,
  desc3: null,
  description: null,
  diafArticleId: null,
  diafQuantityUnit: null,
  dimensions: '2110x860x140mm',
  displayName:
    'Zimmertür Element LA 3, satinato Glas, Fachwerk Eiche Honig quer CPL, SmartKante, 60mm Bekleidung',
  extendsAssetId: null,
  fallbackSyncId: null,
  fiberType: undefined,
  floorType: '',
  flooringType: '',
  hashValueMD5: null,
  id: 'd2f8b08b-4fa2-408b-b9d6-4f23cd4f29b8',
  imageKeyARM: '',
  imageKeyBC: '',
  imageKeyN: '',
  installation: undefined,
  installationSystem: '',
  isDefault: true,
  isDiafLocked: null,
  isManufacturerAsset: 0,
  isPublished: 'PUBLISHED',
  isRotatable: '',
  layerType: '',
  layingSystem: '',
  length: '',
  livingStyle: null,
  manufacturer: 'FingerHaus GmbH',
  manufacturerUuid: '4ec1364c-6a60-4238-a416-9d968047db59',
  manufacturersId: '02af9d3e-4ff3-4418-a87e-ed0ee07daeed',
  markedForDeletion: 0,
  metallic: '',
  metadata: null,
  name: 'Zimmertür Element LA 3, satinato Glas',
  normal: '',
  path: '/PorterAssetsDoors/Assets/Grauthoff/GrauthoffShowcaseDoors/BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer.BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer',
  pe_bestand: null,
  plankFormat: '',
  price: '99.99',
  priceGroup: {
    displayName: { de: 'Inklusivleistung' },
    index: '0',
  },
  priceUnit: {
    index: '1',
    displayName: {
      de: 'Stück',
    },
  },
  priority: null,
  productGroup: null,
  productImages: {
    images: [
      {
        imageUrl:
          'https://s3.eu-central-1.amazonaws.com/xcyde-porter-images/assets/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer.png',
        originalImageUrl:
          'https://s3.eu-central-1.amazonaws.com/xcyde-porter-images/assets/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer_original.png',
        imageKey:
          'assets/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer.png',
      },
    ],
  },
  productSchemaId: 'fh-fce92-021a-44a5-b9ed-3a25ba4654d1',
  productType: 'Innentüren',
  randomSeed: '',
  readyNumber: 'PRN-1054',
  roughness: '',
  sorting: '',
  status: 'COMPLETED',
  structure: '',
  supplierId: null,
  surfaceTexture: '',
  tags: [],
  textureARM: '',
  textureBC: '',
  textureN: '',
  texturePhysicalLength: '',
  texturePhysicalWidth: '',
  thickness: '',
  thumbnailURL:
    'https://s3.eu-central-1.amazonaws.com/xcyde-porter-images/assets/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer/thumb-T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer.png',
  thumbnailURL256:
    'https://s3.eu-central-1.amazonaws.com/xcyde-porter-images/assets/T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer/thumb-T_BP_GTI_GT_ZT_LA3_Satinato_GF_SK_CPL_FachwerkEicheHonigQuer.png',
  typeId: '3a4c3d89-9555-4b11-913b-900398586d84',
  updatedAt: '2023-10-16T15:02:22.000Z',
  upperLayer: '',
  width: '',
};
