<footer class="signup-footer pmat-typography">
  <ul class="footer-menu">
    <li><a href="https://support.porter.de/" target="_blank">Help- & Supportcenter</a></li>
    <li><a href="https://www.porter.de/kontakt/" target="_blank">Kontakt</a></li>
    <li><a href="https://www.porter.de/impressum/" target="_blank">Impressum</a></li>
    <li>
      <a href="https://www.porter.de/porter-datenschutzbestimmungen/" target="_blank"
        >Datenschutz</a
      >
    </li>
    <li>
      <a href="https://www.porter.de/allgemeine-geschaeftsbedingungen/" target="_blank">AGB</a>
    </li>
  </ul>
</footer>
