<app-signup-header></app-signup-header>

<div
  *ngIf="!termsOfService"
  class="porter-container register-container"
  [ngClass]="{ 'confirmation-view': sent }"
>
  <div class="logo-container">
    <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
  </div>

  <div
    *ngIf="!sent"
    class="modal content-container color-bg-white p-6"
    [ngClass]="{ 'confirmation-view': sent }"
  >
    <form (ngSubmit)="register()" [formGroup]="form">
      <div class="modal-header w-100 row">
        <h2 class="modal-title">Registrierung</h2>
      </div>
      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>Unternehmen</mat-label>
        <input
          matInput
          placeholder="Unternehmen"
          name="company"
          formControlName="company"
          required
        />
      </mat-form-field>

      <!--
        <mat-form-field floatLabel="never" class="required" appearance="outline">
          <input matInput placeholder="Name/Vorname" name="username" formControlName="username" required (input)="editRegUser()" />

          <mat-error *ngIf="duplicateUser" class="form-field-error">Benutzername oder E-Mail-Adresse sind schon vergeben</mat-error>
        </mat-form-field>
      -->

      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>Vorname</mat-label>
        <input
          matInput
          placeholder="Vorname"
          name="firstName"
          formControlName="firstName"
          required
        />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>Nachname</mat-label>
        <input
          matInput
          placeholder="Nachname"
          name="lastName"
          formControlName="lastName"
          required
        />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>E-Mail-Adresse</mat-label>
        <input
          matInput
          placeholder="E-Mail-Adresse"
          name="email"
          formControlName="email"
          required
          (input)="editRegUser()"
        />

        <mat-error
          *ngIf="!!form.controls.email.value && form.controls.email.errors?.email"
          class="form-field-error"
        >
          Ungültige E-Mail-Adresse
        </mat-error>
        <mat-error *ngIf="duplicateUser" class="form-field-error">
          E-Mail-Adresse wird bereits verwendet
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>Passwort</mat-label>
        <input
          matInput
          placeholder="Passwort"
          [type]="hidePw ? 'password' : 'text'"
          minlength="8"
          name="password"
          formControlName="password"
          required
        />
        <mat-icon matSuffix class="hide-password material-icons" (click)="hidePw = !hidePw">{{
          hidePw ? 'visibility' : 'visibility_off'
        }}</mat-icon>

        <mat-error *ngIf="form.controls.password.errors" class="form-field-error"
          >mindestens 8 Zeichen und je 1 Kleinbuchstabe, Großbuchstabe, Zahl und
          Sonderzeichen</mat-error
        >
      </mat-form-field>

      <mat-form-field floatLabel="never" class="required" appearance="outline">
        <mat-label>Passwort wiederholen</mat-label>
        <input
          matInput
          placeholder="Passwort wiederholen"
          [type]="hidePw ? 'password' : 'text'"
          type="password"
          minlength="8"
          name="passwordCheck"
          formControlName="passwordCheck"
          required
        />

        <mat-error
          *ngIf="!!form.controls.passwordCheck.value && form.errors?.passwordsNotMatching"
          class="form-field-error"
        >
          Passwörter stimmen nicht überein
        </mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="never" appearance="outline">
        <mat-label>Branche</mat-label>
        <input matInput placeholder="Branche" name="industry" formControlName="industry" />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="half-width" appearance="outline">
        <mat-label>Ort</mat-label>
        <input matInput placeholder="Ort" name="city" formControlName="city" />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="half-width" appearance="outline">
        <mat-label>PLZ</mat-label>
        <input matInput placeholder="PLZ" type="number" name="zipcode" formControlName="zipcode" />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="half-width" appearance="outline">
        <mat-label>Straße</mat-label>
        <input matInput placeholder="Straße" name="street" formControlName="street" />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="half-width" appearance="outline">
        <mat-label>Hausnummer</mat-label>
        <input matInput placeholder="Hausnummer" name="houseNumber" formControlName="houseNumber" />
      </mat-form-field>

      <mat-checkbox
        #checkBox
        color="accent"
        class="accept-terms-of-service"
        formControlName="termsOfService"
        required
      >
        Ich habe die
        <a class="terms-of-service" (click)="termsOfService = true">Nutzungsbedingungen</a> gelesen
        und stimme ihnen zu.
      </mat-checkbox>

      <div class="other-links">
        <a routerLink="/login">Bereits registriert? Jetzt einloggen!</a>
      </div>

      <p class="required-fields" [ngClass]="{ 'has-error': form.errors?.required }">
        *Pflichtfelder
      </p>
      <div class="btn-container">
        <button
          mat-flat-button
          color="accent"
          class="button-size-xl"
          [disabled]="form.invalid || waitingForResponse"
        >
          Weiter
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="sent" class="modal-m content-container registration-confirmation color-bg-white">
    <div class="modal-header w-100 row">
      <h2 class="modal-title">Vielen Dank für Ihre Registrierung</h2>
    </div>
    <div class="modal-text">
      <p>
        Ihr Account wird nun vorbereitet. Sie erhalten in Kürze (1-2 Werktage) weitere Informationen
        per E-Mail. <br />
      </p>
    </div>
    <div class="btn-container btn-container-w100">
      <button mat-flat-button color="accent" class="button-size-xl" routerLink="/login">
        Zurück zum Login
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="termsOfService">
  <app-terms-of-service
    [backButton]="true"
    (backButtonClick)="termsOfService = false"
  ></app-terms-of-service>
</ng-container>

<app-signup-footer></app-signup-footer>
