import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Data,
} from '@angular/router';
import { AuthService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';

@Injectable()
export class UserGroupGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly navigationService: NavigationService) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const data = next.data;

        if ((this.doesRouteDefineARequiredUserGroup(data) && this.authService.hasUserGroup(data.userGroup)) || this.authService.getCurrentUser().admin) {
            return true;
        } else {
            await this.navigationService.guardNavigation(next);
            return false;
        }
    }
    
    private doesRouteDefineARequiredUserGroup(data: Data): boolean {
        return !!data?.userGroup;
    }
}
