import { PriceElementValidation } from "./elements/price-badge.element";
import { MockFactory } from "./factory/mock.factory";

export const PATH_SEPARATOR = '/';

export const FH_BASE_URL = 'http://fingerhaus.localhost:4200';
export const PORTER_CATALOG_URL = 'http://localhost:4200/produkt-katalog-only';

export const CONFIGURATION_PARAMETER = 'configurationId';
export const CONFIGURATION_ID = '9e18d764-44ab-4159-b8cc-f61b060697e3';

export const PRICE_UNIT = {
    METER: 'm²',
    PIECE: 'Stück',
    LFM: 'Lfm.',
};

export const PRICE_GROUP = {
    NORMAL: 'Inklusivleistung',
    EXTRA: 'Mehrpreisleistung',
};

// NORMAL-PRODUCT
const NORMAL_PRODUCT_PRICE_UNIT = MockFactory.createProduct({price: '99.99', priceGroup: {index: '0', displayName: {de: 'Inklusivleistung'}}, priceUnit: {index: '1', displayName: {de: 'm²'}}});
const NORMAL_PRODUCT_PRICE_NO_UNIT = MockFactory.createProduct({price: '99.99', priceGroup: {index: '0', displayName: {de: 'Inklusivleistung'}}, priceUnit: null});
const NORMAL_PRODUCT_NO_PRICE_UNIT = MockFactory.createProduct({price: null, priceGroup: {index: '0', displayName: {de: 'Inklusivleistung'}}, priceUnit: {index: '1', displayName: {de: 'm²'}}});
const NORMAL_PRODUCT_NO_PRICE_NO_UNIT = MockFactory.createProduct({price: null, priceGroup: {index: '0', displayName: {de: 'Inklusivleistung'}}, priceUnit: null});

// EXTRA-PRODUCT
const EXTRA_PRODUCT_PRICE_UNIT = MockFactory.createProduct({price: '99.99', priceGroup: {index: '1', displayName: {de: 'Mehrpreisleistung'}}, priceUnit: {index: '1', displayName: {de: 'm²'}}});
const EXTRA_PRODUCT_PRICE_NO_UNIT = MockFactory.createProduct({price: '99.99', priceGroup: {index: '1', displayName: {de: 'Mehrpreisleistung'}}, priceUnit: null});
const EXTRA_PRODUCT_NO_PRICE_UNIT = MockFactory.createProduct({price: null, priceGroup: {index: '1', displayName: {de: 'Mehrpreisleistung'}}, priceUnit: {index: '1', displayName: {de: 'm²'}}});
const EXTRA_PRODUCT_NO_PRICE_NO_UNIT = MockFactory.createProduct({price: null, priceGroup: {index: '1', displayName: {de: 'Mehrpreisleistung'}}, priceUnit: null});

// DATA TO MOCK REQUEST
export const DATA = {
    data: [
        NORMAL_PRODUCT_PRICE_UNIT, NORMAL_PRODUCT_PRICE_NO_UNIT, NORMAL_PRODUCT_NO_PRICE_UNIT, NORMAL_PRODUCT_NO_PRICE_NO_UNIT,
        EXTRA_PRODUCT_PRICE_UNIT, EXTRA_PRODUCT_PRICE_NO_UNIT, EXTRA_PRODUCT_NO_PRICE_UNIT, EXTRA_PRODUCT_NO_PRICE_NO_UNIT,
    ],
};

export const REQUEST_URL = '**/assets/search?page=0&limit=36&configurationId=faa3d701-e69a-49b4-87a9-2409bf90e123';

// VALIDATIONS
export const NORMAL_PRODUCT_VALIDATION: PriceElementValidation = {
    icon: 'inclusive_product_check_circle',
    text: ' Inklusivleistung ',
    price: null,
};

export const EXTRA_PRODUCT_VALIDATION : PriceElementValidation = {
    icon: null,
    text: ' Mehrpreisleistung ',
    price: ' 99,99 €  / m² ',
};

export const EXTRA_PRODUCT_VALIDATION_NO_UNIT : PriceElementValidation = {
    icon: null,
    text: ' Mehrpreisleistung ',
    price: ' 99,99 € ',
};

export const EXTRA_PRODUCT_VALIDATION_NO_PRICE_NO_UNIT : PriceElementValidation = {
    icon: null,
    text: ' Mehrpreisleistung ',
    price: null,
};

export const MANUFACTURER = {
    all: 'Alle Hersteller',
    luebke: 'Lübke baumetal GmbH',
    fingerhaus: 'FingerHaus GmbH',
};

export const TEST_PRODUCT_SEARCH = '_E2E';

export const TEST_PRODUCT_NAME = {
    temporary: 'Temporary Product',
    zero: '_E2E-Testprodukt-0000-Kein Wohnstil-Visualisierung erfassen',
    one: '_E2E-Testprodukt-0001-Natürlich-Visualisierung erfassen',
    sixteen: '_E2E-Testprodukt-0016-Kein Wohnstil-Produkt veröffentlicht',
    seventeen: '_E2E-Testprodukt-0017-Natürlich-Produkt veröffentlicht',
    twenty:'_E2E-Testprodukt-0020-Alle Wohnstile-Visualisierung erfassen',
};

export const TEST_PAGINATION_PRODUCT_NAME = {
    nineteen: '_E2E-Testprodukt-Pagination-19',
    thirtySeven: '_E2E-Testprodukt-Pagination-37'
}

export const PRODUCT_NAME = {
    twg: 'TWG Glastürbeschlag Formspiele, Black satin, Blind',
    test: 'Test DG - PORTER GmbH',
    greyWhite: 'Grauweiß',
    brilliantBlue: 'Brillantblau',
    signalViolet: 'Signalviolett'
}

export const PAGE = {
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    six: '6',
}

export const PRODUCT_STATUS = {
    none: 'Kein Status',
    captureVisualization: 'Visualisierung erfassen',
    productNeeded: 'Zusätzliches Produkt nötig',
    withoutVisualization: 'Für Katalog ohne Visualisierung',
    visualizationFinished: 'Visualisierung abgeschlossen',
    productPublished: 'Produkt veröffentlicht',
}

export const STORAGE_PATH = {
    fingerhaus_builder: '.auth/fh-builder-storage.json',
    fingerhaus_consultant: '.auth/fh-consultant-storage.json',
    fingerhaus_productManager: '.auth/fh-product-manager-storage.json',
    porter_consultant: '.auth/porter-consultant-storage.json',
};

const NG_ATTRIBUTE = {
    disabled: 'ng-reflect-disabled',
    isDisabled:  'ng-reflect-is-disabled',
    model: 'ng-reflect-model',
};

const ARIA_ATTRIBUTE = {
    invalid: 'aria-invalid',
};

const MAT_ATTRIBUTE = {
    iconName: 'data-mat-icon-name',
};

export const ATTRIBUTE = {
    NG: NG_ATTRIBUTE,
    ARIA: ARIA_ATTRIBUTE,
    MAT: MAT_ATTRIBUTE,
    placeholder: 'placeholder',
    dataPlaceholder: 'data-placeholder',
    required: 'required',
    disabled: 'disabled',
    src: 'src',
};

const NG_CLASS = {
    pristine: 'ng-pristine',
};

const MAT_CLASS = {
    selectDisabled: 'mat-select-disabled',
}

export const CLASS = {
    NG: NG_CLASS,
    MAT: MAT_CLASS,
    accent: 'accent',
    current: 'current',
};

export const STRING = {
    empty: '',
    true: 'true',
    false: 'false',
};

export const KEYBOARD_KEYS = {
    enter: 'Enter',
}

export const MAT_ICONS = {
    arrowLeft: 'keyboard_arrow_left',
    arrowRight: 'keyboard_arrow_right',
}