import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import * as QRCode from 'qrcode';
import { Unit } from '../business-domain/Unit';
import { environment } from './../../environments/environment';

interface Response<T> {
  data: T;
}

@Injectable()
export class UnitService {
  constructor(private http: HttpClient) {}

  public getUnit(unitId: string): Observable<Unit> {
    return this.http.get<Unit>(environment.apiUrl + '/units/' + unitId);
  }

  public getUnitsByProject(projectId: string): Observable<Unit[]> {
    return this.http
      .get<Response<Unit[]>>(environment.apiUrl + `/projects/${projectId}/units`)
      .pipe(map((response) => response.data)) as Observable<Unit[]>;
  }

  public insertUnit(unit: Unit): Observable<any> {
    return this.http.post(environment.apiUrl + '/units', { ...unit });
  }

  public updateUnit(unitId: string, unit): Observable<any> {
    return this.http.put(environment.apiUrl + '/units/' + unitId, { ...unit });
  }

  public deleteUnit(unitId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/units/' + unitId);
  }

  public generateQRCode(unitId: string): Observable<string> {
    const arUrl = `${/^https?:\/\//i.test(environment.apiUrl) ? '' : window.location.origin}${environment.apiUrl}/units/${unitId}/ar/`;

    return from(<string>QRCode.toDataURL(arUrl, { type: 'image/png' }));
  }

  public getActorSlotsOfUnit(unitId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/units/' + unitId + '/actorSlots');
  }

  public addMaterialPreselectionToActorSlots(actorSlotIds: Array<string>, materialId: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/actorSlots/' + actorSlotIds[0] + '/preSelections/globalAdd', {
      ids: actorSlotIds,
      data: [{ materialId: materialId, favorite: false }],
    });
  }
}
