import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';

@Injectable()
export class FeatureFlagGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (this.authService.hasFeatureFlag(route.data.featureFlag)) {
      return true;
    } else {
      await this.navigationService.guardNavigation(route);
      return false;
    }
  }
}
