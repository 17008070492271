import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { InfoboxDialogComponent } from '../components/dialogs/infobox-dialog/infobox-dialog.component';

@Injectable()
export class InfoboxDialogService {
  constructor(private dialog: MatDialog) {}
  dialogRef: MatDialogRef<InfoboxDialogComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(InfoboxDialogComponent, {
      autoFocus: false,
      backdropClass: 'backdropBackground',
      data: options,
    });
  }
  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
}
