<router-outlet></router-outlet>

<!-- Remove when there is a mobile version -->
<div *ngIf="isMobile && !isServiceRoute" class="only-desktop">
  <div class="porter-container">
    <div class="logo-container">
      <ng-container *ngIf="this.customerSystem == 'porter'">
        <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
      </ng-container>
      <ng-container *ngIf="this.customerSystem == 'fingerhaus'" class="px-6">
        <mat-icon class="fingerhaus-logo" svgIcon="fingerhaus-logo"></mat-icon>
      </ng-container>
    </div>
    <div class="modal content-container p-5">
      <div class="modal-header w-100 row">
        <h2 class="modal-title">Hinweis</h2>
      </div>
      <div class="modal-main">
        <div class="modal-text">
          <p>Die Anwendung ist nicht für das Smartphone geeignet.</p>
          <p>Bitte verwenden Sie daher Ihren Desktop-PC, Laptop oder Tablet.</p>
        </div>
      </div>
    </div>
  </div>
</div>
