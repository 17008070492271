import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { ReplaySubject, Observable, forkJoin } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { ErrorDialogService } from '../services/errordialog.service';

declare var initFPEditor: any;

declare global {
  interface Window {
    api?: any;
  }
}

@Injectable()
export class FloorplannerService {
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  FPprojects: any;
  downloadJsonHref: any;
  FMLDownload: any;

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private errorDialogService: ErrorDialogService
  ) {}

  lazyLoadFPScripts(): Observable<any> {
    return forkJoin([this.loadScript('../../assets/js/floorplanner_embed.js')]);
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }
    this._loadedLibraries[url] = new ReplaySubject();
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };
    this.document.body.appendChild(script);
    return this._loadedLibraries[url].asObservable();
  }

  initializeFloorplanner(floorplannerProjectId: number) {
    this.getUserToken().subscribe(
      (data) => {
        console.log('initFloorplanner Success: ', data.token, data.userId);
        console.log('initFloorplanner data: ', data);
        this.initFloorplannerEditor(floorplannerProjectId, data.token, data.userId);
      },
      (error) => {
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'floorplaner.service - getUserToken',
          devinfo: error.message,
          publicinfo:
            'Fehler beim Abruf des Benutzer-Token. <br>Bitte wenden Sie sich an unseren <a href="mailto:support@porter.de">Support</a>. Vielen Dank.',
          debug: false,
          autoFocus: false,
        });
      }
    );
  }

  listProjects(): void {
    console.log('listProjects()');

    this.http.get(environment.apiUrl + '/floorplanner/listProjects').subscribe(
      (data) => {
        this.FPprojects = data;
        console.log('listProjects Success: ', data);
      },
      (error) => {
        console.log('listProjects Error: ', error);
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'floorplaner.service - listProjects',
          devinfo: error.message,
          publicinfo:
            'Fehler beim Abruf der Umwandlungen. Bitte laden Sie die Seite neu. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
          debug: false,
          autoFocus: false,
        });
      }
    );
  }

  getUserToken(): any {
    console.log('getUserToken()');
    return this.http.get(environment.apiUrl + '/floorplanner/usertoken');
  }

  initFloorplannerEditor(projectID: number, authToken: string, floorplannerUserId: string): void {
    initFPEditor({
      projectId: projectID,
      mountSelector: '#fp-editor-container',
      user: {
        id: floorplannerUserId,
        auth_token: authToken,
        // An array of permissions:
        //    'save' shows the save button in the top bar
        //    'no-export' hides the 2D & 3D export buttons in the top bar
        //    'hide-email' hides the email field at the bottom of the export popup
        //    'no-back-to-dashboard' hides the Back to dashboard button in the sidebar
        //    'ntl' turns off the cooldown period for renders
        permissions: ['save', 'no-back-to-dashboard', 'ntl', 'hide-email'],
      },
      language: 'de-DE',
      apiDomain: 'floorplanner.com',
      embedPrefix: 'https://fp-editor-cdn.floorplanner.com',
      kind: 'editor', // 'spaceplanner', 'editor', 'viewer' and 'roomplanner'
      templateId: 30558, // Load template for consistent branding
      brandingId: 333789,
      // "customStyle": {
      //   "light": "rgb(0,99,184)",
      //   "dark": "rgb(0,82,153)", // Color-Styling of loading icon/logo
      //   // "logoImage": "/logo-schwarz.png"
      // }
    }).then((api) => {
      window.api = api;
      /* additional setup using editor API object */
      // api.onUpdated = (data) => console.log('floorplan updated', data);
      // api.onSave = (data) => console.log('floorplan saved', data);
      // api.onExport = (data) => console.log('floorplan exported', data);
      // api.state	= (data) => console.log('floorplan state', data);
    });
  }

  // currently unused, becaus of floorplanner unmount error
  unmountFloorplannerEditor(): void {
    console.log('unount()', window.api);
    window.api.unmount();
  }

  // currently unused, becaus of floorplanner unmount error
  reloadFloorplannerEditor(): void {
    console.log('reload()', window.api);
    window.api.reload();
  }

  showProject(projectId: number): void {
    console.log('showProject()');
    console.log('projectId: ', projectId);

    this.http.get(environment.apiUrl + `/floorplanner/showProject/${projectId}`).subscribe(
      (data) => {
        console.log('showProject Success: ', data);
        // this.generateDownloadJsonUri(projectId);
      },
      (error) => {
        console.log('showProject Error: ', error);
        (error) => {
          console.log('listProjects Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'floorplaner.service - showProject',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Abruf der Umwandlung. Bitte laden Sie die Seite neu. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        };
      }
    );
  }

  createProject(projectName: string): void {
    console.log('createProject(): ' + projectName);

    this.http.post(environment.apiUrl + `/floorplanner/createProject/${projectName}`, {}).subscribe(
      (data) => {
        console.log('createProject Success: ', data);
      },
      (error) => {
        console.log('createProject Error: ', error);
        (error) => {
          console.log('listProjects Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'floorplaner.service - createProject',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Erstellen der Umwandlung. Bitte laden Sie die Seite neu. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        };
      }
    );
  }

  deleteProject(projectId: number): void {
    console.log('deleteProject(): ' + projectId);

    this.http.delete(environment.apiUrl + `/floorplanner/deleteProject/${projectId}`).subscribe(
      (data) => {
        console.log('deleteProject Success: ', data);
      },
      (error) => {
        console.log('deleteProject Error: ', error);
        (error) => {
          console.log('listProjects Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'floorplaner.service - deleteProject',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Löschen der Umwandlung. Bitte laden Sie die Seite neu. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        };
      }
    );
  }

  downloadProjectFML(projectId: number): any {
    console.log('downloadProjectJSON(): ' + projectId);

    this.http.get(environment.apiUrl + `/floorplanner/downloadProjectFML/${projectId}`).subscribe(
      (data) => {
        console.log('downloadProjectJSON Success: ', data);
        let theJSON = JSON.stringify(data);
        let blob = new Blob([theJSON], { type: 'text/json' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'export-' + projectId + '.json';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (error) => {
        console.log('downloadProjectJSON Error: ', error);
        (error) => {
          console.log('listProjects Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'floorplaner.service - downloadProjectJSON',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Herunterladen der ProjectFML. Bitte laden Sie die Seite neu. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        };
      }
    );
  }

  generateDownloadJsonUri(projectId) {
    console.log('generateDownloadJsonUri()');

    const resJsonResponse = this.downloadProjectFML(projectId);
    console.log('resJsonResponse: ', resJsonResponse);

    var theJSON = JSON.stringify(resJsonResponse);
    console.log('theJSON: ', theJSON);

    var uri = this.sanitizer.bypassSecurityTrustUrl(
      'data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON)
    );
    console.log('uri: ', uri);

    this.downloadJsonHref = uri;
  }

  downloadExcel(projectId: number): any {
    console.log('downloadExcel(): ' + projectId);

    this.http
      .post(
        environment.apiUrl + `/floorplans-ai/mass/`,
        { projectId, filename: 'export-' + projectId + '.xlsx' },
        { responseType: 'blob' }
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log('downloadExcel Success: ', data);
          let url = window.URL.createObjectURL(data);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'export-' + projectId + '.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          console.log('Error: ', error);
          (error) => {
            console.log('downloadExcel Error: ', error);
            this.errorDialogService.openDialog({
              errorCode: '0xFFFFFF',
              location: 'floorplaner.service - downloadExcel',
              devinfo: error.message,
              publicinfo:
                'Fehler beim Herunterladen der Excel Datei. Bitte laden Sie die Seite neu und versuchen es erneut. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
              debug: false,
              autoFocus: false,
            });
          };
        }
      );
  }
}
