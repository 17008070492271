import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, LoginData } from '../../../services/auth.service';
import { ConversionData, FloorplanAIService } from '../../../services/floorplan-ai.service';

import { environment } from '../../../../environments/environment';
import { Alert } from '../../../directives/alert.directive';
import { ExporterPluginService } from '../../../services/exporter-plugin.service';
import { IconRegistryService } from '../../../services/icon-registry.service';

const SUCCESSFUL_LOGOUT_ALERT: Alert = {
  type: 'success',
  collapseIcon: true,
  text: 'Sie haben sich erfolgreich abgemeldet',
};

const SESSION_TERMINATED_ALERT: Alert = {
  type: 'warning',
  collapseIcon: true,
  text: 'Ihre Session wurde beendet',
};

const CONNECTION_FAILED_MESSAGE = 'Verbindung zum Server fehlgeschlagen';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  customerSystem: string;

  hidePw: boolean;
  waitingForResponse: boolean;
  logout: boolean;
  sessionLost: boolean;

  hasInvalidCredentials: boolean;
  isUnconfirmedUser: boolean;
  isUsingExport: boolean;

  form: FormGroup;

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly floorplanAiService: FloorplanAIService,
    private readonly exporterPluginService: ExporterPluginService,
    private readonly iconRegistry: IconRegistryService
  ) {
    this.hidePw = true;
    this.hasInvalidCredentials = false;
    this.waitingForResponse = false;
    this.logout = false;
    this.sessionLost = false;
    this.returnUrl = this.createReturnUrl();
    this.isUsingExport = this.exporterPluginService.usesExport();

    this.registerIcons();
  }

  ngOnInit(): void {
    this.customerSystem = environment.customerSystem;
    this.logout = this.activatedRoute.snapshot.queryParams.logout;
    this.sessionLost = this.activatedRoute.snapshot.queryParams.sessionLost;
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(),
    });
  }

  get authenticated(): boolean {
    return this.auth.isAuthenticated();
  }

  async login() {
    const data: LoginData = this.form.value;

    // TODO DAI: If we move the error handling into the InterceptorService, we can remove the try-catch block
    try {
      this.waitingForResponse = true;
      await this.auth.login(data);

      if (this.isUsingExportPlugin()) {
        await this.redirectToProjects();
      } else if (this.shouldRedirectToLatestUpload()) {
        this.redirectToLatestUpload();
      } else if (this.isValidReturnUrl()) {
        await this.redirectToReturnUrl();
      } else {
        await this.redirectToBaseUrl();
      }
    } catch (error) {
      // TODO DAI: Move to InterceptorService
      this.handleLoginError(error);
      this.waitingForResponse = false;
    }
  }

  clearErrors() {
    this.hasInvalidCredentials = false;
    this.isUnconfirmedUser = false;

    this.form.controls.email.setErrors(null);
    this.form.controls.email.updateValueAndValidity();
    this.form.controls.password.setErrors(null);
    this.form.controls.password.updateValueAndValidity();
  }

  private isUsingExportPlugin() {
    return this.exporterPluginService.usesExport();
  }

  private async redirectToProjects() {
    await this.router.navigate(['/projects']);
  }

  // TODO DAI: Move to InterceptorService
  private handleLoginError(error: any) {
    const hasNoConnection = error.error.message === 'NO_CONNECTION';

    if (hasNoConnection) {
      alert(CONNECTION_FAILED_MESSAGE);
      return;
    }

    this.hasInvalidCredentials = error.status === 401 || error.status === 500;
    this.isUnconfirmedUser = error.status === 403;

    this.form.controls.email.setErrors({ incorrect: true });
    this.form.controls.password.setErrors({ wrong: true });
  }

  private async redirectToReturnUrl() {
    await this.router.navigateByUrl(this.returnUrl);
  }

  private isValidReturnUrl(): boolean {
    return (
      this.returnUrl != '/' &&
      !this.returnUrl.startsWith('/register') &&
      !this.returnUrl.startsWith('/confirmation')
    );
  }

  private shouldRedirectToLatestUpload(): boolean {
    // on Grundrisse route, go to last conversion if no conversionId is included in path already
    return this.returnUrl.startsWith('/grundrisse') && !this.returnUrl.includes('conversionId');
  }

  async redirectToBaseUrl() {
    if (this.auth.hasUserGroup(environment.AI_LICENCE_GROUPS) || this.auth.getCurrentUser().admin) {
      await this.router.navigateByUrl('/grundrisse');
    } else if (this.auth.hasUserGroup(environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP)) {
      await this.router.navigateByUrl('/produkt-management');
    } else if (this.auth.hasUserGroup(environment.FINGERHAUS_ADMIN_GROUP)) {
      await this.router.navigateByUrl('/benutzer-verwaltung');
    } else if (this.auth.hasUserGroup(environment.FINGERHAUS_CONSULTANT_GROUP)) {
      await this.router.navigateByUrl('/produkt-katalog');
    } else {
      await this.router.navigateByUrl('/app-link');
    }
  }

  private createReturnUrl(): string {
    return this.activatedRoute.snapshot.queryParams.returnUrl &&
      !/^\/login/i.test(this.activatedRoute.snapshot.queryParams.returnUrl)
      ? this.activatedRoute.snapshot.queryParams.returnUrl
      : '/';
  }

  redirectToLatestUpload() {
    this.floorplanAiService.getAllUserConversionDataWithoutThumbnail().subscribe(
      (conversionData: ConversionData) => {
        const conversionList = conversionData.list;
        if (conversionList.length === 0) {
          window.location.replace(
            window.location.origin +
              '/grundrisse/grundriss-editor?conversionId=&environment=' +
              environment.apiUrl
          );
          return;
        }

        conversionList.forEach((conv) => {
          if (conv.status == 'COMPLETED' || conv.status === 'IN_PROGRESS') {
            window.location.replace(
              window.location.origin +
                '/grundrisse/grundriss-editor?conversionId=' +
                conv.conversionId +
                '&environment=' +
                environment.apiUrl
            );
          } else if (conv == conversionList[conversionList.length - 1]) {
            window.location.replace(
              window.location.origin +
                '/grundrisse/grundriss-editor?conversionId=&environment=' +
                environment.apiUrl
            );
            return;
          }
        });
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  getSuccessfulLogoutAlert(): Alert {
    return SUCCESSFUL_LOGOUT_ALERT;
  }

  getSessionTerminatedAlert(): Alert {
    return SESSION_TERMINATED_ALERT;
  }

  private registerIcons() {
    this.iconRegistry.register('porter-logo', 'assets/icons/porter-logo.svg');
    this.iconRegistry.register('fingerhaus-logo', 'assets/icons/fingerhaus-logo.svg');
  }
}
